import InputGroup from 'components/Input';
import React from 'react';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import './_Styles.scss';

const MIN_PADDING = 0;
const MAX_PADDING = 1000;

const PaddingItem = ({value, onChange}) => {
  const {paddingTop, paddingBottom, paddingLeft, paddingRight} = value;

  return (
    <SectionItem title="Padding" sectionItemClassName="padding-item">
      <InputGroup
        small
        value={paddingLeft}
        type="number"
        labelTextLeft={
          <div className="icon-wrapper">
            <i className="isax isax-slider-horizontal" />
          </div>
        }
        min={MIN_PADDING}
        max={MAX_PADDING}
        onChange={(e) =>
          onChange({
            paddingTop,
            paddingBottom,
            paddingLeft: parseInt(e.target.value, 10),
            paddingRight: parseInt(e.target.value, 10),
          })
        }
      />
      <InputGroup
        small
        value={paddingTop}
        type="number"
        labelTextLeft={
          <div className="icon-wrapper">
            <i className="isax isax-slider-vertical" />
          </div>
        }
        min={MIN_PADDING}
        max={MAX_PADDING}
        onChange={(e) =>
          onChange({
            paddingTop: parseInt(e.target.value, 10),
            paddingBottom: parseInt(e.target.value, 10),
            paddingLeft,
            paddingRight,
          })
        }
      />
    </SectionItem>
  );
};

export default PaddingItem;
