import React, {useContext} from 'react';
import {
  BLOCK_BODY,
  BLOCK_DISMISS_CROSS,
  BLOCK_MEDIA,
  BLOCK_USER,
} from '../../constants/blocks';
import {PokeStateContext} from '../../context';
import {BlockBody} from '../BlockBody';
import {BlockDismissCross} from '../BlockDismissCross';
import {BlockMedia} from '../BlockMedia';
import {BlockUserCursorImage, BlockUserCursorText} from '../BlockUser';
import './_styles.scss';

export const LayoutCursor = () => {
  const {currentStep} = useContext(PokeStateContext);

  const hasProfile =
    currentStep.blocks.find((b) => b.type === BLOCK_USER) != null;
  const hasBody = currentStep.blocks.find((b) => b.type === BLOCK_BODY) != null;
  const hasDismissCross = currentStep.blocks?.some(
    (b) => b.type === BLOCK_DISMISS_CROSS
  );
  const hasMedia = currentStep.blocks?.some((b) => b.type === BLOCK_MEDIA);

  return (
    <div
      className="poke-layout-cursor"
      style={{
        paddingTop: `${currentStep?.style?.paddingTop ?? 24}px`,
        paddingBottom: `${currentStep?.style?.paddingBottom ?? 24}px`,
        paddingLeft: `${currentStep?.style?.paddingLeft ?? 24}px`,
        paddingRight: `${currentStep?.style?.paddingRight ?? 24}px`,
        gap: `${currentStep?.style?.gap ?? 16}px`,
      }}>
      <div className="layout-cursor-content">
        {hasProfile && (
          <div className="layout-cursor-content-left">
            <BlockUserCursorImage />
          </div>
        )}
        <div
          className="layout-cursor-content-right"
          style={{
            gap: `${currentStep?.style?.gap ?? 16}px`,
          }}>
          {hasProfile && <BlockUserCursorText />}
          {hasBody && (
            <div className="layout-banner-center-col-content-bottom">
              <BlockBody />
            </div>
          )}
        </div>
        {hasDismissCross === true && (
          <div className="layout-cursor-dismiss-cross">
            <div className="dismiss-cross-divider" />
            <BlockDismissCross />
          </div>
        )}
      </div>
      {hasMedia && (
        <div className="layout-cursor-media">
          <BlockMedia isCursor />
        </div>
      )}
    </div>
  );
};
