import classNames from 'classnames';
import Button from 'components/Button';
import {BuilderContext} from 'contextes/builder';
import {errorHelpers} from 'helpers';
import {object} from 'prop-types';
import {useContext, useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {evolutionService} from 'services';
import {
  EVENT_CONDITION_TYPE_DELAY,
  EVENT_CONDITION_TYPE_ELEMENT,
  EVENT_CONDITION_TYPE_INPUT,
  EVENT_CONDITION_TYPE_SURVEY_RESPONSE,
  EVENT_CONDITION_TYPE_TOUR_SEEN,
  EVENT_CONDITION_TYPE_URL,
  EventConditionOperand,
} from 'services/event';
import {EVOLUTION_STATE_LIVE} from 'services/evolution';
import {Swaler} from 'swaler';
import {EventConditionsEditorContext} from '../..';
import {isEventConditionValid} from '../../utils';
import EditDropdown from '../EditDropdown';
import {parseResponseValue} from '../EditDropdown/components/SettingsSurveyResponse';
import './_Styles.scss';

const propTypes = {
  condition: object.isRequired,
};

const logger = new Swaler('SettingsTourSeen');

const Condition = ({condition, disabled, isNotDeletable}) => {
  const {
    handleDeleteConditionFromLogic,
    conditions,
    setConditions,
    newlyAddedCondition,
    setNewlyAddedCondition,
    compact,
    settingsPositioning,
  } = useContext(EventConditionsEditorContext);
  const {selectedStep} = useContext(BuilderContext) || {};

  const {value, operand, querySelector, querySelectorManual, file} = condition;

  const [showEditDropdown, setShowEditDropdown] = useState(false);

  useEffect(() => {
    if (showEditDropdown && querySelector) {
      // Close and reopen the popup to force reposition
      setShowEditDropdown(false);
      setTimeout(() => setShowEditDropdown(true), 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySelector]); // Dependency on querySelector

  useEffect(() => {
    if (newlyAddedCondition?.uid === condition.uid) {
      setShowEditDropdown(true);
      setNewlyAddedCondition(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newlyAddedCondition]);

  const {data: experiences = []} = useQuery({
    queryKey: 'experiences',
    queryFn: async () => {
      try {
        const experiences = await evolutionService.getEvolutions({
          relations: [],
          onlyPokes: true,
          states: [EVOLUTION_STATE_LIVE],
          removeDrafts: true,
        });

        return experiences;
      } catch (err) {
        const {code} = errorHelpers.parseError(err);

        logger.error('Fetch experiences failed with error ', code);
      }
    },
  });

  const isElement = condition.type === EVENT_CONDITION_TYPE_ELEMENT;
  const isInput = condition.type === EVENT_CONDITION_TYPE_INPUT;
  const isUrl = condition.type === EVENT_CONDITION_TYPE_URL;
  const isDelay = condition.type === EVENT_CONDITION_TYPE_DELAY;
  const isSurveyResponse =
    condition.type === EVENT_CONDITION_TYPE_SURVEY_RESPONSE;
  const isTourSeen = condition.type === EVENT_CONDITION_TYPE_TOUR_SEEN;

  const isValid = isEventConditionValid(condition);

  let content = '';

  if (isElement) {
    content = (
      <>
        <div className={classNames('icon-wrapper', 'element')}>
          <i className="isax isax-mouse-circle" />
        </div>
        <div className="event-content">
          <div
            className={classNames(
              'condition-type n-500',
              compact ? 'body-4' : 'body-3'
            )}>
            Element
          </div>
          {querySelector ||
          querySelectorManual?.elementText ||
          querySelectorManual?.cssSelector ? (
            <div
              className={classNames(
                'condition-details n-700',
                compact ? 'body-3' : 'body-2'
              )}>
              Is{' '}
              {operand === EventConditionOperand.CLICKED
                ? 'clicked'
                : operand === EventConditionOperand.MOUSE_DOWN
                ? 'mouse down'
                : operand === EventConditionOperand.HOVERED
                ? 'hovered'
                : operand === EventConditionOperand.PRESENT
                ? 'present'
                : operand === EventConditionOperand.NOT_PRESENT
                ? 'not present'
                : operand === EventConditionOperand.DISABLED
                ? 'disabled'
                : operand === EventConditionOperand.NOT_DISABLED
                ? 'not disabled'
                : ''}
              :{' '}
              {querySelectorManual?.elementText != null ||
              querySelectorManual?.cssSelector != null
                ? 'Manual selector'
                : !file?.publicUrl
                ? querySelector
                : ''}
            </div>
          ) : (
            <div
              className={classNames(
                'condition-details r-500',
                compact ? 'body-3' : 'body-2'
              )}>
              No element selected yet
            </div>
          )}
        </div>
        {querySelectorManual == null && file?.publicUrl && (
          <div className="element-image-wrapper">
            <img src={file.publicUrl} alt="element" />
          </div>
        )}
      </>
    );
  } else if (isUrl) {
    content = (
      <>
        <div className={classNames('icon-wrapper', 'url')}>
          <i className="isax isax-receipt-search" />
        </div>
        <div className="event-content">
          <div
            className={classNames(
              'condition-type n-500',
              compact ? 'body-4' : 'body-3'
            )}>
            Current page
          </div>
          {!operand || !value ? (
            <div
              className={classNames(
                'condition-details r-500',
                compact ? 'body-3' : 'body-2'
              )}>
              No rule set yet
            </div>
          ) : (
            <div
              className={classNames(
                'condition-details n-800',
                compact ? 'body-3' : 'body-2'
              )}>
              <span className="n-700">
                {operand === EventConditionOperand.IS
                  ? 'Is'
                  : operand === EventConditionOperand.NIS
                  ? 'Is not'
                  : operand === EventConditionOperand.CONTAINS
                  ? 'Contains'
                  : operand === EventConditionOperand.NCONTAINS
                  ? 'Does not contain'
                  : operand === EventConditionOperand.STARTS_WITH
                  ? 'Starts with'
                  : operand === EventConditionOperand.ENDS_WITH
                  ? 'Ends with'
                  : operand === EventConditionOperand.MATCHES_REGEX
                  ? 'Matches regular expression'
                  : operand === EventConditionOperand.NMATCHES_REGEX
                  ? 'Does not match regular expression'
                  : ''}
                :
              </span>{' '}
              {value}
            </div>
          )}
        </div>
      </>
    );
  } else if (isInput) {
    content = (
      <>
        <div className={classNames('icon-wrapper', 'input')}>
          <i className="isax isax-keyboard" />
        </div>
        <div className="event-content">
          <div
            className={classNames(
              'condition-type n-500',
              compact ? 'body-4' : 'body-3'
            )}>
            Fills in input
          </div>
          {querySelector ||
          querySelectorManual?.elementText ||
          querySelectorManual?.cssSelector ? (
            <div
              className={classNames(
                'condition-details n-800',
                compact ? 'body-3' : 'body-2'
              )}>
              <span className="n-700">
                {operand === EventConditionOperand.IS
                  ? 'Is'
                  : operand === EventConditionOperand.NIS
                  ? 'Is not'
                  : operand === EventConditionOperand.CONTAINS
                  ? 'Contains'
                  : operand === EventConditionOperand.NCONTAINS
                  ? 'Does not contain'
                  : operand === EventConditionOperand.STARTS_WITH
                  ? 'Starts with'
                  : operand === EventConditionOperand.ENDS_WITH
                  ? 'Ends with'
                  : operand === EventConditionOperand.MATCHES_REGEX
                  ? 'Matches regular expression'
                  : operand === EventConditionOperand.NMATCHES_REGEX
                  ? 'Does not match regular expression'
                  : operand === EventConditionOperand.IS_EMPTY
                  ? 'Is empty'
                  : operand === EventConditionOperand.NIS_EMPTY
                  ? 'Is not empty'
                  : ''}
              </span>
              {[
                EventConditionOperand.IS_EMPTY,
                EventConditionOperand.NIS_EMPTY,
              ].includes(operand) !== true && `: “${value}“`}
            </div>
          ) : (
            <div
              className={classNames(
                'condition-details r-500',
                compact ? 'body-3' : 'body-2'
              )}>
              No element selected yet
            </div>
          )}
        </div>
        {querySelectorManual == null && file?.publicUrl && (
          <div className="element-image-wrapper">
            <img src={file.publicUrl} alt="element" />
          </div>
        )}
      </>
    );
  } else if (isDelay) {
    content = (
      <>
        <div className={classNames('icon-wrapper', 'delay')}>
          <i className="isax isax-clock" />
        </div>
        <div className="event-content">
          <div
            className={classNames(
              'condition-type n-500',
              compact ? 'body-4' : 'body-3'
            )}>
            Delay
          </div>
          <div
            className={classNames(
              'condition-details n-800',
              compact ? 'body-3' : 'body-2'
            )}>
            {value} seconds
          </div>
        </div>
      </>
    );
  } else if (isTourSeen) {
    content = (
      <>
        <div className={classNames('icon-wrapper', 'tour-seen')}>
          <i className="isax isax-routing-2" />
        </div>
        <div className="event-content">
          <div
            className={classNames(
              'condition-type n-500',
              compact ? 'body-4' : 'body-3'
            )}>
            Experience
          </div>
          <div
            className={classNames(
              'condition-details n-800',
              compact ? 'body-3' : 'body-2'
            )}>
            {experiences?.find((e) => e.uid === value)?.title ||
              'No experience selected'}
          </div>
        </div>
      </>
    );
  } else if (isSurveyResponse) {
    const isFilled = condition.operand != null && condition.value;

    content = (
      <>
        <div
          className={classNames('icon-wrapper', 'survey-response', {
            filled: isFilled,
          })}>
          <i className="isax isax-message-text" />
        </div>
        <div className="event-content">
          <div
            className={classNames(
              'condition-type n-500',
              compact ? 'body-4' : 'body-3'
            )}>
            {isFilled ? 'Response' : 'Add response condition...'}
          </div>
          <div
            className={classNames(
              'condition-details n-800',
              compact ? 'body-3' : 'body-2'
            )}>
            {parseResponseValue(operand, value, selectedStep)}
          </div>
        </div>
      </>
    );
  }

  return (
    <EditDropdown
      condition={condition}
      setCondition={(condition) => {
        setConditions(
          conditions.map((c) => {
            if (c.uid === condition.uid) {
              return condition;
            }

            return c;
          })
        );
      }}
      isOpen={showEditDropdown}
      onClose={() => setShowEditDropdown(false)}
      trigger={
        <div
          className={classNames('event-condition', {
            'is-invalid': isValid !== true,
            disabled: disabled,
            selected: showEditDropdown,
            compact: compact,
          })}
          onClick={() => {
            if (disabled) {
              return;
            }

            setShowEditDropdown(true);
          }}>
          {content}
          {isNotDeletable !== true && (
            <div className="actions-wrapper">
              <Button
                iconOnly
                iconLeft="isax isax-trash"
                danger
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  handleDeleteConditionFromLogic(condition.uid);
                }}
              />
            </div>
          )}
        </div>
      }
      position={
        settingsPositioning != null
          ? settingsPositioning
          : compact
          ? 'left top'
          : 'center right'
      }
      offsetX={compact ? 8 : 0}
      offsetY={compact ? -4 : 0}
    />
  );
};

Condition.propTypes = propTypes;

export default Condition;
