import classNames from 'classnames';
import Dropdown from 'components/Dropdown';
import React, {useState} from 'react';
import {F_BOOST_SLOT_CURSOR} from 'services/evolution';
import {
  F_SLOT_DOT,
  F_SLOT_POP_IN,
  F_SLOT_SNIPPET,
  F_SLOT_TOOLTIP,
} from 'shared/front/components/Poke/constants/poke';
import './_Styles.scss';

const types = [
  {
    name: 'Modal',
    description: 'Show content in a modal',
    value: F_SLOT_POP_IN,
    icon: 'isax isax-size',
  },
  {
    name: 'Snippet',
    description: 'Show content on page corners',
    value: F_SLOT_SNIPPET,
    icon: 'isax isax-screenmirroring',
  },
  {
    name: 'Hotspot',
    description: 'Grab user attention onto an element',
    value: F_SLOT_DOT,
    icon: 'isax isax-notification-1',
  },
  {
    name: 'Tooltip',
    description: 'Explain a specific element',
    value: F_SLOT_TOOLTIP,
    icon: 'isax isax-message',
  },
  {
    name: 'Cursor',
    description: 'Point to a specific element',
    value: F_BOOST_SLOT_CURSOR,
    icon: 'isax isax-mouse-1',
  },
];

const DropdownTypePicker = ({value, onChange}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const selectedType = types.find((type) => type.value === value);

  return (
    <Dropdown
      open={showDropdown}
      className={classNames('dropdown-type-picker')}
      position="bottom left"
      repositionOnResize={true}
      trigger={
        <div
          className="type-picker-trigger"
          onClick={() => setShowDropdown(true)}>
          <div className="icon-wrapper">
            <i className={selectedType?.icon} />
          </div>
          <div className="title subtitle-4 n-800">{selectedType?.name}</div>
          <i className="icon-chevron-bottom" />
        </div>
      }>
      <div className="dropdown-menu">
        {types.map((type) => {
          const {name, description, value, icon} = type;
          return (
            <div
              className="dropdown-menu-item"
              onClick={() => {
                onChange(value);
                setShowDropdown(false);
              }}>
              <div className={classNames('icon-wrapper')}>
                <i className={icon} />
              </div>
              <div className="content">
                <div className="title subtitle-4 n-800">{name}</div>
                <div className="subtitle body-4 n-700">{description}</div>
              </div>
            </div>
          );
        })}
      </div>
    </Dropdown>
  );
};

export default DropdownTypePicker;
