import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import {func, string} from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {ElementSelector} from 'scenes/SuccessTracker/components/Event/components/EventConditions/components/EditDropdown/components/ElementSelector';
import ModalSelectElement from 'scenes/SuccessTracker/components/ModalSelectElement';
import {BLOCK_TYPE_HINT} from 'services/steps';
import {HINT_TYPE_HIDDEN} from '../../../blocks/Hint';
import PopupSetting from '../../PopupSetting';
import ClickableInput from '../../items/ClickableInput';
import PixelPicker from '../../items/PixelPicker';
import ZIndex from '../ZIndex';
import './_Styles.scss';
import HintAlignment from './components/HintAlignment';

const propTypes = {
  value: string,
  onChange: func,
  setCloseOnDocumentClick: func,
};

const defaultProps = {
  value: {},
  onChange: () => {},
  setCloseOnDocumentClick: () => {},
};

export const positionsArr = [
  {position: 'top-left', value: 1},
  {position: 'top', value: 2},
  {position: 'top-right', value: 4},
  {position: 'right', value: 8},
  {position: 'bottom-right', value: 16},
  {position: 'bottom', value: 32},
  {position: 'bottom-left', value: 64},
  {position: 'left', value: 128},
  {position: 'right-top', value: 512},
  {position: 'right-bottom', value: 1024},
  {position: 'left-bottom', value: 2048},
  {position: 'left-top', value: 4096},
];

const HintPosition = ({evolution, setEvolution, setCloseOnDocumentClick}) => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    isInApp,
    messenger,
  } = useContext(BuilderContext);

  const [showSelectElementModal, setShowSelectElementModal] = useState(false);

  useEffect(() => {
    setCloseOnDocumentClick(!showSelectElementModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSelectElementModal]);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_HINT, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_HINT);

  const {style = {}} = block || {};
  const {offsetX, offsetY, type} = style;

  return (
    <>
      <div className="section-item target-picker">
        <ElementSelector
          data={{
            querySelector: evolution?.boostedQueryselector,
            file: evolution?.file,
            querySelectorManual: evolution?.querySelectorManual,
          }}
          onChange={(data) => {
            setEvolution({
              ...evolution,
              boostedQueryselector: data.querySelector,
              file: data.file,
              querySelectorManual: data.querySelectorManual,
            });
          }}
          onModalOpen={(isOpen) => setShowSelectElementModal(isOpen)}
          withElementsCount={isInApp}
          onSelectElement={
            isInApp
              ? messenger.sendSelectTargetElement
              : () => setShowSelectElementModal(true)
          }
          isEditingStep
        />
      </div>
      <Divider />
      {type !== HINT_TYPE_HIDDEN && (
        <>
          <HintAlignment
            style={style}
            onChange={(style) =>
              updateBlock({
                style: {
                  ...style,
                },
              })
            }
          />
          <SectionItem title="Offset X">
            <PixelPicker
              value={offsetX}
              min={-400}
              max={400}
              onChange={(value) =>
                updateBlock({
                  style: {
                    ...style,
                    offsetX: value,
                  },
                })
              }
            />
          </SectionItem>
          <SectionItem title="Offset Y">
            <PixelPicker
              value={offsetY}
              min={-400}
              max={400}
              onChange={(value) =>
                updateBlock({
                  style: {
                    ...style,
                    offsetY: value,
                  },
                })
              }
            />
          </SectionItem>
        </>
      )}
      <div className="section-item z-index">
        <div className="section-item-title">Z-index</div>
        <div className="section-item-content">
          <PopupSetting
            trigger={
              <ClickableInput
                className="position-z-index"
                value={
                  evolution.boostedZIndex != null
                    ? `${evolution.boostedZIndex}`
                    : 'Auto'
                }
                leftLabel={
                  <div className="icon-wrapper">
                    <i className="icon-duplicate" />
                  </div>
                }
              />
            }
            title="Z-index"
            content={
              <ZIndex
                value={evolution.boostedZIndex}
                onChange={(value) =>
                  setEvolution({
                    ...evolution,
                    boostedZIndex: value,
                  })
                }
                setIsOpen={(v) => setCloseOnDocumentClick(!v)}
              />
            }
          />
        </div>
      </div>

      {/* {showSelectElementModal && (
        // <ElementPickerModal
        //   evolution={parentEvolution}
        //   stepId={selectedStepId}
        //   isOpen={showSelectElementModal}
        //   onRequestClose={() => setShowSelectElementModal(false)}
        //   onQuerySelectorChange={(querySelector) => {
        //     setEvolution({...evolution, boostedQueryselector: querySelector});
        //   }}
        //   setEvolution={(evolution) => {
        //     if (evolution.uid === parentEvolution.uid) {
        //       setParentEvolution(evolution);
        //     } else {
        //       setParentEvolution({
        //         ...parentEvolution,
        //         tourSteps: parentEvolution.tourSteps.map((tourStep) => {
        //           if (tourStep.uid === evolution.uid) {
        //             return evolution;
        //           }
        //           return tourStep;
        //         }),
        //       });
        //     }
        //   }}
        // /> */}
      <ModalSelectElement
        isOpen={showSelectElementModal}
        onRequestClose={() => setShowSelectElementModal(false)}
        // onElementSelected={handleElementSelected}
        onElementSelected={(element) => {
          setEvolution({
            ...evolution,
            boostedQueryselector: element.querySelector,
            file: element.file,
          });
          setShowSelectElementModal(false);
        }}
        // onlyInput={isInput}
      />
      {/* )} */}
    </>
  );
};

HintPosition.propTypes = propTypes;
HintPosition.defaultProps = defaultProps;

export default HintPosition;
