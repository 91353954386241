import InputGroup from 'components/Input';
import {BuilderContext} from 'contextes/builder';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import React, {useContext} from 'react';
import {NPS_INTERACTIVE_BACKGROUND} from 'scenes/PokeBuilder/components/BlockManager/utils';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {BLOCK_TYPE_NPS} from 'services/steps';
import RadioGroup from '../../../../../../components/RadioGroup';
import ColorItem from '../../components/sectionItems/ColorItem';
import FontFamilyItem from '../../components/sectionItems/FontFamilyItem';
import FontSizeItem from '../../components/sectionItems/FontSizeItem';
import PaddingItem from '../../components/sectionItems/PaddingItem';
import './_Styles.scss';

const animatedOptions = [
  {label: 'Yes', value: 'yes'},
  {label: 'No', value: 'no'},
];

const Nps = () => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    evolution,
  } = useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_NPS, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_NPS);

  if (block == null) {
    return <></>;
  }

  const {value, flags, style = {}} = block;
  const [labelLeft, labelRight] = value.split(';');

  return (
    <div className="block-settings nps">
      <Section title="Style">
        {evolution.style?.background?.type === 'gradient' && (
          <SectionItem title="Animation">
            <RadioGroup
              value={hasFlag(NPS_INTERACTIVE_BACKGROUND, flags) ? 'yes' : 'no'}
              options={animatedOptions}
              onSelect={(value) =>
                updateBlock({
                  flags:
                    value === 'yes'
                      ? addFlag(NPS_INTERACTIVE_BACKGROUND, flags)
                      : removeFlag(NPS_INTERACTIVE_BACKGROUND, flags),
                })
              }
            />
          </SectionItem>
        )}
        <SectionItem title="Left label">
          <InputGroup
            className="nps-left-label-input"
            value={labelLeft}
            onChange={({target}) =>
              updateBlock({
                value: `${target.value};${labelRight}`,
              })
            }
          />
        </SectionItem>
        <SectionItem title="Right label">
          <InputGroup
            className="nps-right-label-input"
            value={labelRight}
            onChange={({target}) =>
              updateBlock({
                value: `${labelLeft};${target.value}`,
              })
            }
          />
        </SectionItem>
        <NpsStyle style={style} updateStyle={updateBlock} />
      </Section>
    </div>
  );
};

export const NpsStyle = ({style, updateStyle}) => {
  return (
    <>
      <FontSizeItem
        value={style.fontSize}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontSize: value},
          })
        }
      />
      <PaddingItem
        value={{
          paddingTop: style.paddingTop ?? style.padding,
          paddingBottom: style.paddingBottom ?? style.padding,
          paddingLeft: style.paddingLeft ?? style.padding,
          paddingRight: style.paddingRight ?? style.padding,
        }}
        onChange={(value) =>
          updateStyle({
            style: {
              ...style,
              ...value,
            },
          })
        }
      />
      <FontFamilyItem
        value={style.fontFamily}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontFamily: value},
          })
        }
      />
      <ColorItem
        title="Labels color"
        value={style.labelsColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, labelsColor: value},
          })
        }
        colorPickerProps={{erasable: true}}
      />
    </>
  );
};

export default Nps;
