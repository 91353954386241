import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import React, {useContext, useState} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {DropdownAddAction} from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/TriggerActions';
import {
  BLOCK_TYPE_CHECKLIST_TASK_ITEM,
  STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
  STEP_CONDITION_ACTION_TYPE_DISMISS,
  STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
  STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
  STEP_CONDITION_ACTION_TYPE_OPEN_POST,
  STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
} from 'services/steps';
import {v4 as uuidv4} from 'uuid';
import ClickableInput from '../../components/items/ClickableInput';
import ColorItem from '../../components/sectionItems/ColorItem';
import ButtonAction from '../Button/components/ButtonAction';
import './_Styles.scss';
import ChecklistConditionsWrapper from './ChecklistConditionsWrapper';

const ChecklistListItem = () => {
  const {
    selectedStep: step,
    updateStep,
    updateBlock: uptBlock,
    selectedChecklistItemId,
  } = useContext(BuilderContext);

  const [newlyAddedAction, setNewlyAddedAction] = useState(null);

  const updateBlock = (updateObj) => {
    updateStep(step.uid, {
      blocks: step.blocks.map((block) =>
        block.uid === selectedChecklistItemId ? {...block, ...updateObj} : block
      ),
    });
  };

  const handleAddAction = (type) => {
    const newAction = {
      uid: uuidv4(),
      type,
    };

    updateBlock({
      actions: [...actions, newAction],
    });
    setNewlyAddedAction(newAction);
  };

  const block = step?.blocks.find(
    (b) =>
      b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM &&
      b.uid === selectedChecklistItemId
  );

  if (block == null) {
    return <></>;
  }

  const {style, actions = [], triggers = []} = block;

  let authorizedActions = [
    STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
    STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
    STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
  ].filter((a) => actions.map((action) => action.type).includes(a) !== true);

  if (
    actions
      .map((action) => action.type)
      .some((t) =>
        [
          STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
          STEP_CONDITION_ACTION_TYPE_OPEN_POST,
          STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
          STEP_CONDITION_ACTION_TYPE_DISMISS,
        ].includes(t)
      ) === true
  ) {
    authorizedActions = authorizedActions.filter(
      (a) =>
        [
          STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
          STEP_CONDITION_ACTION_TYPE_OPEN_POST,
          STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
          STEP_CONDITION_ACTION_TYPE_DISMISS,
        ].includes(a) !== true
    );
  }
  if (
    actions
      .map((action) => action.type)
      .some((t) => [STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW].includes(t)) ===
    true
  ) {
    authorizedActions = authorizedActions.filter(
      (a) => a !== STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO
    );
  }

  const markAsCompletedTrigger = triggers?.[0];

  return (
    <div className="block-settings checklist-list-item" key={block.uid}>
      <Section title="Behavior">
        <SectionItem title="Task actions" contentClassName="action-picker">
          <div className="actions-wrapper">
            {actions.map((action) => {
              return (
                <ButtonAction
                  key={action.uid}
                  action={action}
                  setAction={(updatedAction) => {
                    updateBlock({
                      actions: actions.map((_action) =>
                        _action.uid === action.uid ? updatedAction : _action
                      ),
                    });
                  }}
                  onDelete={() => {
                    updateBlock({
                      actions: actions.filter(
                        (_action) => _action.uid !== action.uid
                      ),
                    });
                  }}
                  defaultOpen={newlyAddedAction?.uid === action.uid}
                />
              );
            })}
            <DropdownAddAction
              className="add-action-element-button"
              authorizedActions={authorizedActions}
              usedActionTypes={actions.map((action) => action.type)}
              onAddAction={handleAddAction}
              position="left top"
              offsetY={-4}
              offsetX={8}
              trigger={
                <ClickableInput
                  className="add-action-element-placeholder-btn"
                  inputProps={{placeholder: 'Add action...'}}
                  leftLabel={
                    <div className="action-icon-wrapper">
                      <i className="isax isax-flash-15" />
                    </div>
                  }
                />
              }
            />
          </div>
        </SectionItem>
        <SectionItem title="Mark completed" sectionItemClassName="triggers">
          <ChecklistConditionsWrapper
            trigger={markAsCompletedTrigger}
            setTrigger={(trigger) => updateBlock({triggers: [trigger]})}
            title="Mark task as completed"
          />
        </SectionItem>
      </Section>
      <Divider />
      <Section
        title={
          <>
            <i className="isax isax-main-component5 p-500" />
            Style
          </>
        }
        titleExtra={
          <>
            <div className="body-4 n-700">For every</div>
            <div className="block-group subtitle-4 n-800">
              <div className="icon-wrapper">
                <i className="isax isax-tick-square" />
              </div>
              Task Item
            </div>
          </>
        }>
        <ChecklistTaskItemStyle
          style={style}
          updateStyle={(style) =>
            uptBlock(BLOCK_TYPE_CHECKLIST_TASK_ITEM, style)
          }
          hideStepActive={style.type === 'text'}
        />
      </Section>
    </div>
  );
};

export const ChecklistTaskItemStyle = ({style, updateStyle}) => {
  return (
    <ColorItem
      title="Border"
      value={style.borderColor || null}
      onChange={(value) =>
        updateStyle({
          style: {
            ...style,
            borderColor: value,
          },
        })
      }
      colorPickerProps={{erasable: true}}
    />
  );
};

export default ChecklistListItem;
