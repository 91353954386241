import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import {BuilderContext} from 'contextes/builder';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import React, {useContext} from 'react';
import {CHOICE_CAN_MULTIPLE} from 'scenes/PokeBuilder/components/BlockManager/utils';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {BLOCK_TYPE_CHOICE} from 'services/steps';
import {
  CHOICE_DISPOSITION_BLOCK,
  CHOICE_DISPOSITION_INLINE,
} from 'shared/front/components/Poke/components/BlockChoice';
import {v4 as uuidv4} from 'uuid';
import RadioGroup from '../../../../../../components/RadioGroup';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import Shadow from '../../components/modals/Shadow';
import ColorItem from '../../components/sectionItems/ColorItem';
import FontFamilyItem from '../../components/sectionItems/FontFamilyItem';
import FontSizeItem from '../../components/sectionItems/FontSizeItem';
import FontWeightItem from '../../components/sectionItems/FontWeightItem';
import GapItem from '../../components/sectionItems/GapItem';
import PaddingItem from '../../components/sectionItems/PaddingItem';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import './_Styles.scss';

const dispositionOptions = [
  {label: 'Inline', value: CHOICE_DISPOSITION_INLINE},
  {label: 'Block', value: CHOICE_DISPOSITION_BLOCK},
];

const typeOptions = [
  {label: 'Single', value: false},
  {label: 'Multiple', value: true},
];

const checkboxOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

export const getDefaultChoiceOption = () => {
  return {
    uid: uuidv4(),
    content: '',
    labels: [],
    indexOrder: 0,
  };
};

const MultipleChoice = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_CHOICE, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_CHOICE);

  if (step == null || block == null) {
    return <></>;
  }

  const {flags, options, style} = block;

  const handleAddOption = (e) => {
    e.preventDefault();
    updateBlock({
      options: [
        ...options,
        {
          ...getDefaultChoiceOption(),
          indexOrder: options.length,
        },
      ],
    });
  };

  const handleDeleteOption = (index) => {
    updateBlock({
      options: options.filter((e, i) => i !== index),
    });
  };

  const handleOptionContentChange = (optionId, newContent) => {
    updateBlock({
      options: options.map((o) =>
        o.uid === optionId ? {...o, content: newContent} : o
      ),
    });
  };

  return (
    <div className="block-settings multiple-choice">
      <Section title="Options">
        <div className="options">
          {options
            .sort((a, b) => a.indexOrder - b.indexOrder)
            .map((option, index) => {
              const content = option.content;
              return (
                <div className="choice-wrapper" key={option.uid}>
                  <InputGroup
                    required
                    className={classNames('choice-label', {
                      invalid: !content,
                    })}
                    placeholder={`Choice ${index + 1}`}
                    name="name"
                    value={content || ''}
                    onChange={(e) =>
                      handleOptionContentChange(option.uid, e.target.value)
                    }
                    labelTextRight={
                      index > 1 ? (
                        <i
                          className="icon-trash"
                          onClick={() => handleDeleteOption(index)}></i>
                      ) : null
                    }
                  />
                  {index + 1 === options.length ? (
                    <Button
                      className={classNames('add-option-btn')}
                      iconLeft="icon-plus-rounded"
                      iconOnly
                      onClick={handleAddOption}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
        </div>
      </Section>
      <Divider />
      <Section title="Layout">
        <SectionItem title="Choice type">
          <RadioGroup
            value={hasFlag(CHOICE_CAN_MULTIPLE, flags)}
            options={typeOptions}
            onSelect={(value) => {
              if (value) {
                updateBlock({flags: addFlag(CHOICE_CAN_MULTIPLE, flags)});
              } else {
                updateBlock({
                  flags: removeFlag(CHOICE_CAN_MULTIPLE, flags),
                });
              }
            }}
          />
        </SectionItem>
        <MultipleChoiceStyle style={style} updateStyle={updateBlock} />
      </Section>
    </div>
  );
};

export const MultipleChoiceStyle = ({style, updateStyle}) => {
  return (
    <>
      <SectionItem title="Checkbox">
        <RadioGroup
          value={style.withCheckbox || false}
          options={checkboxOptions}
          onSelect={(value) =>
            updateStyle({style: {...style, withCheckbox: value}})
          }
        />
      </SectionItem>
      <SectionItem title="Disposition">
        <RadioGroup
          value={style.disposition}
          options={dispositionOptions}
          onSelect={(value) =>
            updateStyle({style: {...style, disposition: value}})
          }
        />
      </SectionItem>
      <FontFamilyItem
        value={style.fontFamily}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontFamily: value},
          })
        }
      />
      <FontSizeItem
        value={style.fontSize}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontSize: value},
          })
        }
      />
      <FontWeightItem
        value={style.fontWeight}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontWeight: value},
          })
        }
      />
      <PaddingItem
        value={{
          paddingTop: style.paddingTop ?? style.padding,
          paddingBottom: style.paddingBottom ?? style.padding,
          paddingLeft: style.paddingLeft ?? style.padding,
          paddingRight: style.paddingRight ?? style.padding,
        }}
        onChange={(value) =>
          updateStyle({
            style: {
              ...style,
              ...value,
            },
          })
        }
      />
      <GapItem
        value={style.gap ?? 8}
        onChange={(value) =>
          updateStyle({
            style: {...style, gap: value},
          })
        }
      />
      <RadiusItem
        value={style.borderRadius}
        onChange={(value) =>
          updateStyle({
            style: {
              ...style,
              borderRadius: value,
            },
          })
        }
      />
      <ColorItem
        title="Color"
        value={style.primaryColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, primaryColor: value},
          })
        }
      />
      <SectionItem title="Shadow" contentClassName="shadow-picker">
        <PopupSetting
          trigger={
            <ClickableInput
              inputProps={{small: true}}
              value={style.shadow?.color}
              leftLabel={
                <span
                  className="preview-shadow-color"
                  style={{background: style.shadow?.color || '#FFFFFF'}}
                />
              }
            />
          }
          title="Shadow"
          content={
            <Shadow
              value={style.shadow}
              onChange={(value) => {
                updateStyle({style: {...style, shadow: value}});
              }}
            />
          }
        />
      </SectionItem>
    </>
  );
};

export default MultipleChoice;
