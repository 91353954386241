import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Dropdown from 'components/Dropdown';
import InputGroup from 'components/Input';
import {BuilderContext} from 'contextes/builder';
import {getEmojiDataFromNative} from 'emoji-mart';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import React, {useContext, useEffect, useState} from 'react';
import {SLIDER_ABOVE_EMOJI_ANIMATION} from 'scenes/PokeBuilder/components/BlockManager/utils';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {BLOCK_TYPE_SLIDER} from 'services/steps';
import RadioGroup from '../../../../../../components/RadioGroup';
import ClickableInput from '../../components/items/ClickableInput';
import PixelPicker from '../../components/items/PixelPicker';
import ColorItem from '../../components/sectionItems/ColorItem';
import FontFamilyItem from '../../components/sectionItems/FontFamilyItem';
import './_Styles.scss';

const animatedOptions = [
  {label: 'Yes', value: 'yes'},
  {label: 'No', value: 'no'},
];

const Slider = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_SLIDER, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_SLIDER);

  if (block == null) {
    return <></>;
  }

  const {value, flags, style} = block;
  const [type, customEmoji, labelLeft, labelRight] = value.split(';');

  return (
    <div className="block-settings slider">
      <Section title="Style">
        <SliderStyle style={style} updateStyle={updateBlock} />
        <SectionItem title="Emoji">
          <EmojiPicker
            value={customEmoji}
            onSelect={(value) =>
              updateBlock({
                value: `${type};${value};${labelLeft};${labelRight}`,
              })
            }
          />
        </SectionItem>
        <SectionItem title="Animation">
          <RadioGroup
            value={hasFlag(SLIDER_ABOVE_EMOJI_ANIMATION, flags) ? 'yes' : 'no'}
            options={animatedOptions}
            onSelect={(value) =>
              updateBlock({
                flags:
                  value === 'yes'
                    ? addFlag(SLIDER_ABOVE_EMOJI_ANIMATION, flags)
                    : removeFlag(SLIDER_ABOVE_EMOJI_ANIMATION, flags),
              })
            }
          />
        </SectionItem>
        <SectionItem title="Left label">
          <InputGroup
            className="slider-left-label-input"
            value={labelLeft}
            onChange={({target}) =>
              updateBlock({
                value: `${type};${customEmoji};${target.value};${labelRight}`,
              })
            }
          />
        </SectionItem>
        <SectionItem title="Right label">
          <InputGroup
            className="slider-right-label-input"
            value={labelRight}
            onChange={({target}) =>
              updateBlock({
                value: `${type};${customEmoji};${labelLeft};${target.value}`,
              })
            }
          />
        </SectionItem>
      </Section>
    </div>
  );
};

export const EmojiPicker = ({value, onSelect}) => {
  const [emojiName, setEmojiName] = useState(null);

  useEffect(() => {
    const getEmojiData = async () => {
      const emojiData = await getEmojiDataFromNative(value);
      setEmojiName(emojiData?.name);
    };

    getEmojiData();
  }, [value]);

  return (
    <Dropdown
      className="emoji-picker-dropdown"
      position="left top"
      offsetX={120}
      trigger={
        <ClickableInput
          value={emojiName || 'Select emoji'}
          leftLabel={
            <div className="emoji-icon-wrapper">
              <span className="emoji-icon" style={{background: '#FFFFFF'}}>
                {value}
              </span>
            </div>
          }
        />
      }>
      <Picker data={data} onEmojiSelect={(emoji) => onSelect(emoji.native)} />
    </Dropdown>
  );
};

export const SliderStyle = ({style, updateStyle}) => {
  return (
    <>
      <SectionItem title="Size">
        <PixelPicker
          small
          value={style.height}
          min={2}
          max={12}
          onChange={(value) =>
            updateStyle({
              style: {...style, height: value},
            })
          }
        />
      </SectionItem>
      <ColorItem
        title="Color"
        value={style.primaryColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, primaryColor: value},
          })
        }
      />
      <ColorItem
        title="Background"
        value={style.secondaryColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, secondaryColor: value},
          })
        }
      />
      <FontFamilyItem
        value={style.fontFamily}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontFamily: value},
          })
        }
      />
      <ColorItem
        title="Labels color"
        value={style.labelsColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, labelsColor: value},
          })
        }
        colorPickerProps={{erasable: true}}
      />
    </>
  );
};

export default Slider;
