import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {BLOCK_TYPE_CHECKLIST_DISMISS} from 'services/steps';
import ColorItem from '../../components/sectionItems/ColorItem';
import FontFamilyItem from '../../components/sectionItems/FontFamilyItem';
import FontSizeItem from '../../components/sectionItems/FontSizeItem';
import FontWeightItem from '../../components/sectionItems/FontWeightItem';
import './_Styles.scss';

const ChecklistDismiss = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_CHECKLIST_DISMISS, updateObj);
  };

  const block = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_DISMISS
  );

  if (block == null) {
    return <></>;
  }

  const {value, style} = block;

  return (
    <div className="block-settings checklist-dismiss">
      <Section title="Content">
        <SectionItem title="Text">
          <InputGroup
            className="dismiss-content-input"
            value={value}
            onChange={({target}) =>
              updateBlock({
                value: target.value,
              })
            }
            autoFocus
          />
        </SectionItem>
      </Section>
      <Divider />
      <Section title="Layout">
        <ChecklistDismissStyle style={style} updateStyle={updateBlock} />
      </Section>
    </div>
  );
};

export const ChecklistDismissStyle = ({style, updateStyle}) => {
  return (
    <>
      <FontFamilyItem
        value={style.fontFamily}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontFamily: value},
          })
        }
      />
      <FontSizeItem
        value={style.fontSize}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontSize: value},
          })
        }
      />
      <FontWeightItem
        value={style.fontWeight}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontWeight: value},
          })
        }
      />

      <ColorItem
        title="Text color"
        value={style.fontColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontColor: value},
          })
        }
      />
    </>
  );
};

export default ChecklistDismiss;
