import SelectGroup from 'components/Select';
import React from 'react';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import './_Styles.scss';

export const fontWeightOptions = [
  {label: '300 - Light', value: '300'},
  {label: '400 - Regular', value: '400'},
  {label: '500 - Medium', value: '500'},
  {label: '600 - Semi-bold', value: '600'},
  {label: '700 - Bold', value: '700'},
];

const FontWeightItem = ({value, onChange}) => {
  return (
    <SectionItem title="Font weight">
      <SelectGroup
        isSearchable={false}
        small
        options={fontWeightOptions}
        value={fontWeightOptions.find((o) => o.value === (value || '400'))}
        onChange={(option) => onChange(option.value)}
      />
    </SectionItem>
  );
};

export default FontWeightItem;
