import {hasFlag, hasFlags} from 'helpers/bitwise';
import {getStepIssue} from 'helpers/step';
import {
  ACTIVE_OPERATOR_CUSTOM,
  ACTIVE_OPERATOR_SINGLE_URL,
} from 'scenes/EmbeddedElementSelectorBuilder';
import {
  TYPE_BANNER,
  TYPE_CURSOR,
  TYPE_HINT,
  TYPE_HOTSPOT,
  TYPE_MODAL,
  TYPE_NAVIGATION,
  TYPE_SNIPPET,
  TYPE_TOOLTIP,
  TYPE_TOUR,
} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal/templates';
import {stepsService} from 'services';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_TOUR,
  F_BOOST_SLOT_CURSOR,
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_HINT,
  F_BOOST_SLOT_NAVIGATION,
  F_BOOST_SLOT_POP_IN,
  F_BOOST_SLOT_SNIPPET,
  F_BOOST_SLOT_TOOLTIP,
  F_BOOST_SLOT_TOP_BAR,
  F_BOOST_SLOT_TOUR,
  F_OPTION_PROGRESS_ON_TARGET_CLICK,
} from 'services/evolution';
import {
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  F_STEP_IS_SELECTING_PRESET,
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
} from 'services/steps';
import isURL from 'validator/lib/isURL';

export const CATEGORY_1 = 'Ask';
export const CATEGORY_2 = 'Test';
export const CATEGORY_3 = 'Say';

export const BLOCKS = [
  {
    category: CATEGORY_1,
    title: 'Opinion scale',
    description: 'Measure opinion with rating scale',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-vote-opinion" />,
    step: stepsService.STEP_TYPE_OPINION_SCALE,
    className: 'step-opinion-scale',
  },
  {
    category: CATEGORY_1,
    title: 'Multiple choice',
    description: 'Ask a question with multiple choices',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-vote-multi-choice" />,
    step: stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
    className: 'step-multi-choice',
  },
  {
    category: CATEGORY_1,
    title: 'Open question',
    description: 'Collect text',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-vote-open-question" />,
    step: stepsService.STEP_TYPE_TEXT_LONG,
    className: 'step-text-long',
  },
  {
    category: CATEGORY_1,
    title: 'NPS',
    description: 'Measure with a net promoter score',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-vote-nps" />,
    step: stepsService.STEP_TYPE_NPS,
    className: 'step-nps',
  },
  {
    category: CATEGORY_1,
    title: 'Emoji slider',
    description: 'Measure opinion with sliding scale',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-vote-slider" />,
    step: stepsService.STEP_TYPE_SLIDER,
    className: 'step-slider',
  },
  {
    category: CATEGORY_2,
    title: 'Concept test',
    description: 'Conduct a research on your design',
    iconBackgroundColor: '#E6F5FD',
    icon: <i className="icon-filter-preview" />,
    step: stepsService.STEP_TYPE_CONCEPT_TEST,
    className: 'step-concept-test',
  },
  {
    category: CATEGORY_2,
    title: 'Interview prompt',
    description: 'Invite users to book a slot in your calendar',
    iconBackgroundColor: '#E6F5FD',
    beta: true,
    icon: <i className="icon-user-interview" />,
    step: stepsService.STEP_TYPE_INTERVIEW,
    className: 'step-user-interview',
  },
  {
    category: CATEGORY_3,
    title: 'Text block',
    description: 'Write basic texts',
    iconBackgroundColor: '#E6FDE8',
    icon: <i className="icon-filter-announcement" />,
    step: stepsService.STEP_TYPE_TEXT_BLOCK,
    className: 'step-text-block',
  },
  {
    category: CATEGORY_3,
    title: 'Thank you',
    description: 'End the interaction with joy',
    iconBackgroundColor: '#E6FDE8',
    icon: <i className="icon-heart-o" />,
    step: stepsService.STEP_TYPE_SUCCESS,
    className: 'step-thanks-block',
  },
  // add single choice to prevent unnamed block
  {
    title: 'Multiple choice',
    description: 'Ask a question with multiple choices',
    iconBackgroundColor: '#fdefe6',
    icon: <i className="icon-vote-multi-choice" />,
    step: stepsService.STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    className: 'step-multi-choice',
  },
];

export const evolutionIcons = [
  {
    value: 'DEFAULT',
    icon: (
      <div className="icon-wrapper scratch">
        <i className="icon-radiobox-o" />
      </div>
    ),
  },
  {
    value: 'IDEATION',
    icon: (
      <div className="icon-wrapper discovery">
        <i className="icon-filter-ideation" />
      </div>
    ),
  },
  {
    value: 'INTERVIEW',
    icon: (
      <div className="icon-wrapper discovery">
        <i className="icon-user-interview" />
      </div>
    ),
  },
  {
    value: 'PREVIEW',
    icon: (
      <div className="icon-wrapper discovery">
        <i className="icon-filter-preview" />
      </div>
    ),
  },
  {
    value: 'SURVEY',
    icon: (
      <div className="icon-wrapper discovery">
        <i className="icon-user-satisfaction"></i>
      </div>
    ),
  },
  {
    value: 'ANNOUNCEMENT',
    icon: (
      <div className="icon-wrapper adoption">
        <i className="icon-filter-announcement"></i>
      </div>
    ),
  },
  {
    value: 'ONBOARDING',
    icon: (
      <div className="icon-wrapper adoption">
        <i className="icon-tour"></i>
      </div>
    ),
  },
  {
    value: 'CHANGELOG',
    icon: (
      <div className="icon-wrapper discovery">
        <i className="isax isax-slider-vertical5"></i>
      </div>
    ),
  },
];

export const isPokeValid = (evolution) => {
  let isValid = true;
  const issues = [];

  if (evolution == null) {
    issues.push({issues: {issues: ['Poke is missing']}});
  } else {
    const isTour = evolution.type === EVOLUTION_TYPE_TOUR;
    const isBanner = evolution.type === EVOLUTION_TYPE_BANNER;
    const isHint = evolution.type === EVOLUTION_TYPE_HINT;
    const isChecklist = evolution.type === EVOLUTION_TYPE_CHECKLIST;

    if (isTour || isBanner || isHint) {
      const tourSteps = JSON.parse(
        JSON.stringify(
          evolution.tourSteps?.filter((t) =>
            t.steps.some((s) => s.removed !== true)
          ) || []
        )
      );
      tourSteps.forEach((ts) => {
        ts.steps = ts.steps.filter((s) => s.removed !== true);
      });

      if (tourSteps?.length <= 0) {
        issues.push({issues: [{issues: ['Step is missing']}]});
      } else {
        tourSteps.forEach((s) => {
          const [tourIndexOrder] = (s.tourStepInfo || '0;0;0').split(';');
          s.tourIndexOrder = parseInt(tourIndexOrder, 10);
        });
        tourSteps.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder);
        const lastTourStep = tourSteps[tourSteps.length - 1];

        tourSteps?.forEach((ts) => {
          const isNavigationStep = hasFlag(
            F_BOOST_SLOT_NAVIGATION,
            ts.boostFlags
          );
          const isTargetNotSelected =
            hasFlags(
              [F_BOOST_SLOT_DOT, F_BOOST_SLOT_TOOLTIP, F_BOOST_SLOT_HINT],
              ts.boostFlags,
              true
            ) &&
            !ts.boostedQueryselector &&
            !ts.querySelectorManual?.elementText &&
            !ts.querySelectorManual?.cssSelector;

          const isTooltip = hasFlag(F_BOOST_SLOT_TOOLTIP, ts.boostFlags);
          const isCursor = hasFlag(F_BOOST_SLOT_CURSOR, ts.boostFlags);
          const isProgressingOnTargetClick =
            (isTooltip || isCursor) &&
            hasFlag(F_OPTION_PROGRESS_ON_TARGET_CLICK, ts.optionsFlags) ===
              true;
          const isLastTourStep = ts.uid === lastTourStep.uid;

          if (isNavigationStep) {
            // here we should check that navigation step is valid
            if (ts.boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL) {
              if (
                isURL(ts.boostedActiveUrl || '', {
                  require_tld: false, // to allow localhost
                }) !== true
              ) {
                isValid = false;
                issues.push({
                  tourStepId: ts.uid,
                  issues: [{issues: ['URL is invalid']}],
                });
              }
            } else if (ts.boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM) {
              if (!ts.boostedPaths?.length > 0) {
                isValid = false;
                issues.push({
                  tourStepId: ts.uid,
                  issues: [{issues: ['Path rules are missing']}],
                });
              }
            }
          } else {
            if (isTargetNotSelected) {
              isValid = false;
              issues.push({
                tourStepId: ts.uid,
                stepId: ts.steps[0]?.uid,
                issues: [{issues: ['Target is missing']}],
              });
            }
            if (ts.steps?.length > 0) {
              ts.steps?.forEach((s, stepIndex) => {
                const tourStepsCopy = JSON.parse(JSON.stringify(tourSteps));
                tourStepsCopy.forEach((ts) => {
                  const [tourIndexOrder] = (ts.tourStepInfo || '0;0;0').split(
                    ';'
                  );
                  ts.tourIndexOrder = parseInt(tourIndexOrder, 10);
                  ts.steps.forEach((s) => {
                    s.boostFlags = ts.boostFlags;
                  });
                });
                tourStepsCopy.sort(
                  (a, b) => a.tourIndexOrder - b.tourIndexOrder
                );
                const actualSteps = tourStepsCopy
                  ?.map((t) =>
                    t.steps?.sort((a, b) => a.indexOrder - b.indexOrder)
                  )
                  .flat();
                const hasNoPrevStep = s.uid === actualSteps[0]?.uid;

                const tourStepIndex = tourSteps.findIndex((ts) =>
                  ts.steps.some((ss) => ss.uid === s.uid)
                );
                const prevStepIsNavigation =
                  tourStepIndex > 0 &&
                  tourSteps[tourStepIndex - 1].boostFlags ===
                    F_BOOST_SLOT_NAVIGATION &&
                  stepIndex === 0;

                const isSelectingPreset = hasFlag(
                  F_STEP_IS_SELECTING_PRESET,
                  s.stepFlags
                );

                if (isSelectingPreset) {
                  isValid = false;
                  issues.push({
                    tourStepId: ts.uid,
                    stepId: s.uid,
                    issues: [
                      {
                        issues: [
                          `Step is selecting preset. Please select a preset or start from scratch.`,
                        ],
                      },
                    ],
                  });
                }

                const stepIssues = getStepIssue(s, {
                  hasNoPrevStep,
                  prevStepIsNavigation,
                });
                if (stepIssues != null) {
                  isValid = false;
                  issues.push({
                    tourStepId: ts.uid,
                    stepId: s.uid,
                    issues: stepIssues,
                  });
                }

                if (isTour) {
                  const isLastStep = stepIndex === ts.steps.length - 1;
                  const hasTriggerGoToStep = s.triggers?.some((t) =>
                    t.actions.some(
                      (a) => a.type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP
                    )
                  );

                  if (
                    s.blocks.some(
                      (b) =>
                        [
                          BLOCK_TYPE_PRIMARY_CTA,
                          BLOCK_TYPE_SECONDARY_CTA,
                        ].includes(b.type) && b.removed !== true
                    ) !== true &&
                    (isLastStep && isProgressingOnTargetClick) !== true &&
                    (isLastStep && isLastTourStep) !== true &&
                    hasTriggerGoToStep !== true
                  ) {
                    isValid = false;
                    issues.push({
                      tourStepId: ts.uid,
                      stepId: s.uid,
                      issues: [
                        {
                          issues: [
                            `A Primary/Secondary CTA block or trigger action is required to progress to a next step.`,
                          ],
                        },
                      ],
                    });
                  }
                }
              });
            }
          }
        });

        const firstStep = tourSteps?.[0];
        const lastStep = tourSteps?.[tourSteps.length - 1];
        if (
          hasFlag(F_BOOST_SLOT_NAVIGATION, firstStep?.boostFlags) ||
          hasFlag(F_BOOST_SLOT_NAVIGATION, lastStep?.boostFlags)
        ) {
          isValid = false;
          issues.push({
            issues: [
              {
                issues: ['First and last steps cannot be navigation steps'],
              },
            ],
          });
        }
      }
    } else {
      if (evolution?.steps?.length <= 0) {
        issues.push({issues: [{issues: ['Step is missing']}]});
      } else {
        evolution?.steps?.forEach((s, index) => {
          const stepIssues = getStepIssue(s, {
            hasNoPrevStep: index === 0,
            isChecklist,
          });
          if (stepIssues != null) {
            isValid = false;
            issues.push({
              stepId: s.uid,
              issues: stepIssues,
            });
          }
        });
      }
    }
  }

  if (isValid) {
    return true;
  } else {
    return issues;
  }
};

export const isAudienceValid = (evolution) => {
  const isPoke = evolution.boostFlags > 0;
  const boostedActiveUrl = evolution.boostedActiveUrl ?? '';

  if (
    isPoke &&
    evolution.boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL &&
    boostedActiveUrl.includes('localhost') === false &&
    isURL(boostedActiveUrl) === false
  ) {
    return false;
  }

  return true;
};

export const getTypeFromBoostFlags = (boostFlags) => {
  if (hasFlag(F_BOOST_SLOT_DOT, boostFlags)) {
    return TYPE_HOTSPOT;
  } else if (hasFlag(F_BOOST_SLOT_SNIPPET, boostFlags)) {
    return TYPE_SNIPPET;
  } else if (hasFlag(F_BOOST_SLOT_POP_IN, boostFlags)) {
    return TYPE_MODAL;
  } else if (hasFlag(F_BOOST_SLOT_TOP_BAR, boostFlags)) {
    return TYPE_BANNER;
  } else if (hasFlag(F_BOOST_SLOT_TOUR, boostFlags)) {
    return TYPE_TOUR;
  } else if (hasFlag(F_BOOST_SLOT_TOOLTIP, boostFlags)) {
    return TYPE_TOOLTIP;
  } else if (hasFlag(F_BOOST_SLOT_NAVIGATION, boostFlags)) {
    return TYPE_NAVIGATION;
  } else if (hasFlag(F_BOOST_SLOT_HINT, boostFlags)) {
    return TYPE_HINT;
  } else if (hasFlag(F_BOOST_SLOT_CURSOR, boostFlags)) {
    return TYPE_CURSOR;
  }
  return null;
};

export const getStepData = (type) => {
  const step = BLOCKS.find((b) => b.step === type) ?? {};

  return step;
};

export const defaultTheme = {
  name: 'Default',
  // banner
  bannerTextsColors: '#071331;#071331',
  bannerPrimaryColor: '#ffffff',
  bannerTitleFontSize: '18',
  bannerCtaBorderRadius: '34',
  bannerCtaBackgroundColor: '#1260eb',
  bannerCtaColor: '#ffffff',
  // hotspot
  hotspotPositionFlags: 64,
  hotspotTextsColors: '#071331;#071331',
  hotspotPrimaryColor: '#ffffff',
  hotspotSecondaryColor: '#1260EB',
  hotspotRoundness: '8',
  hotspotContentFontSize: '14',
  hotspotTitleFontSize: '18',
  hotspotStyle: '#1260EB;22',
  hotspotSize: null,
  hotspotCtaBorderRadius: '34',
  hotspotCtaBackgroundColor: '#1260eb',
  hotspotCtaColor: '#ffffff',
  //snippet
  snippetTextsColors: '#071331;#071331',
  snippetPrimaryColor: '#ffffff',
  snippetSecondaryColor: '#1260EB',
  snippetRoundness: '8',
  snippetContentFontSize: '14',
  snippetTitleFontSize: '18',
  snippetSize: null,
  snippetCtaBorderRadius: '34',
  snippetCtaBackgroundColor: '#1260eb',
  snippetCtaColor: '#ffffff',
  //modal
  modalTextsColors: '#071331;#071331',
  modalPrimaryColor: '#ffffff',
  modalSecondaryColor: '#1260EB',
  modalRoundness: '8',
  modalContentFontSize: '14',
  modalTitleFontSize: '18',
  modalSize: null,
  modalCtaBorderRadius: '34',
  modalCtaBackgroundColor: '#1260eb',
  modalCtaColor: '#ffffff',
  modalLightbox: 'SOFT',

  // tootlip
  tooltipPositionFlags: 64,
  tooltipTextsColors: '#071331;#071331',
  tooltipPrimaryColor: '#ffffff',
  tooltipSecondaryColor: '#1260EB',
  tooltipRoundness: '8',
  tooltipContentFontSize: '14',
  tooltipTitleFontSize: '18',
  tooltipSize: null,
  tooltipCtaBorderRadius: '34',
  tooltipCtaBackgroundColor: '#1260eb',
  tooltipCtaColor: '#ffffff',
  tooltipLightbox: 'SOFT',

  style: {
    stepStyle: {
      margin: 0,
      shadow: {
        x: '0',
        y: '24',
        blur: '24',
        color: '#00000014',
      },
      overlay: null,
      background: {
        type: 'gradient',
        animated: true,
        primaryColor: '#1260ebff',
        secondaryColor: '#ffffffff',
      },
      animationIn: 'fade',
      'animation-in': null,
      animationOut: 'fade',
      borderRadius: 12,
      'animation-out': 'slide-up',
    },
    blocksStyle: {
      NPS: {
        style: {
          padding: 8,
          fontSize: 16,
        },
      },
      BODY: {
        style: {
          align: 'left',
          fontSize: 16,
          fontColor: '#000000',
          fontFamily: 'Inter',
          fontWeight: '300',
        },
      },
      USER: {
        style: {
          borderRadius: 8,
        },
      },
      LABEL: {
        style: {
          align: 'left',
          padding: 8,
          fontSize: 12,
          fontColor: '#1260ebff',
          fontFamily: 'Inter',
          fontWeight: '400',
          borderColor: '#1260ebbf',
          borderRadius: 8,
          primaryColor: '#1260eb14',
        },
      },
      MEDIA: {
        style: {
          padding: 8,
          position: 'top',
          borderRadius: 8,
        },
      },
      TITLE: {
        style: {
          align: 'left',
          fontSize: 24,
          fontColor: '#000000',
          fontFamily: 'Inter',
          fontWeight: '600',
        },
      },
      CHOICE: {
        style: {
          shadow: {
            x: '0',
            y: '8',
            blur: '12',
            color: '#00000014',
          },
          padding: 12,
          fontSize: 16,
          fontFamily: 'Inter',
          disposition: 'inline',
          borderRadius: 12,
          primaryColor: '#1260ebff',
        },
      },
      SLIDER: {
        style: {
          height: 6,
          primaryColor: '#1260ebb3',
          secondaryColor: '#1260eb29',
        },
      },
      OPINION: {
        style: {
          padding: 8,
          fontSize: 20,
        },
      },
      STEPPER: {
        style: {
          type: 'dot-line',
          align: 'left',
          position: 'top',
          primaryColor: '#1260ebff',
          secondaryColor: '#1260eb29',
        },
      },
      INTERVIEW: {
        style: {
          align: 'center',
          fontSize: 16,
          fontFamily: 'Inter',
          borderRadius: 8,
          primaryColor: '#ffffffff',
          secondaryColor: '#ffffff',
          fontPrimaryColor: '#1260ebff',
          borderPrimaryColor: '#1260ebff',
          fontSecondaryColor: '#000000ff',
          borderSecondaryColor: '#00000029',
        },
      },
      PRIMARY_CTA: {
        style: {
          align: 'right',
          padding: 12,
          fontSize: 16,
          fontColor: '#ffffffff',
          fontFamily: 'Inter',
          fontWeight: '500',
          borderRadius: 12,
          primaryColor: '#1260ebff',
        },
      },
      OPEN_QUESTION: {
        style: {
          borderRadius: 6,
          primaryColor: '#f3f3f3ff',
        },
      },
      SECONDARY_CTA: {
        style: {
          align: 'left',
          padding: 12,
          fontSize: 16,
          fontColor: '#000000',
          fontFamily: 'Arial',
          borderColor: '#0000001f',
          borderRadius: 12,
          primaryColor: '#ffffff',
        },
      },
    },
  },
};
