import { useContext, useEffect, useState } from 'react';
import {
  BLOCK_BODY,
  BLOCK_CHOICE,
  BLOCK_LABEL,
  BLOCK_MEDIA,
  BLOCK_NPS,
  BLOCK_OPEN_QUESTION,
  BLOCK_PRIMARY_CTA,
  BLOCK_SECONDARY_CTA,
  BLOCK_STEPPER,
  BLOCK_TITLE,
  BLOCK_USER,
} from '../constants/blocks';
import {
  F_SLOT_CURSOR,
  F_SLOT_DOT,
  F_SLOT_HINT,
  F_SLOT_POP_IN,
  F_SLOT_SNIPPET,
  F_SLOT_TOOLTIP,
  F_SLOT_TOP_BAR,
} from '../constants/poke';
import { PokeContext } from '../context';

// Dimensions for slots [width, height]
const defaultDimensions = {
  [F_SLOT_POP_IN]: [600, 200],
  [F_SLOT_SNIPPET]: [350, 200],
  [F_SLOT_TOOLTIP]: [300, 150],
  [F_SLOT_DOT]: [350, 200],
  [F_SLOT_TOP_BAR]: [-1, 40],
  [F_SLOT_HINT]: [300, 150],
  [F_SLOT_CURSOR]: [300, 150],
};
// Dimensions for blocks [width, height] for each slot
const blockDimensions = {
  [BLOCK_MEDIA]: [0, 250],
  [BLOCK_USER]: [0, 50],
  [BLOCK_STEPPER]: [0, 25],
  [BLOCK_TITLE]: [0, 40],
  [BLOCK_BODY]: [0, 100],
  [BLOCK_CHOICE]: [0, 70],
  [BLOCK_LABEL]: [0, 15],
  [BLOCK_PRIMARY_CTA]: [0, 50],
  [BLOCK_SECONDARY_CTA]: [0, 50],
  [BLOCK_OPEN_QUESTION]: [0, 200],
};
const blockDimensionsPerSlot = {
  [F_SLOT_POP_IN]: {
    [BLOCK_MEDIA]: [0, 200],
  },
  [F_SLOT_SNIPPET]: {
    [BLOCK_MEDIA]: [0, 200],
    [BLOCK_NPS]: [300, 0],
  },
  [F_SLOT_TOOLTIP]: {
    [BLOCK_MEDIA]: [0, 200],
    [BLOCK_NPS]: [300, 0],
  },
  [F_SLOT_DOT]: {
    [BLOCK_MEDIA]: [0, 200],
    [BLOCK_NPS]: [300, 0],
  },
  [F_SLOT_TOP_BAR]: {
    [BLOCK_PRIMARY_CTA]: [0, 0],
    [BLOCK_SECONDARY_CTA]: [0, 0],
  },
  [F_SLOT_HINT]: {
    [BLOCK_MEDIA]: [0, 200],
    [BLOCK_NPS]: [300, 0],
  },
  [F_SLOT_CURSOR]: {
    [BLOCK_MEDIA]: [0, 200],
    [BLOCK_NPS]: [300, 0],
  },
};

export const useDimension = ({step = null}) => {
  const {poke} = useContext(PokeContext);

  const computeDimension = () => {
    if (poke == null || step == null) {
      return [0, 0];
    }

    const totalDimension = [...defaultDimensions[poke.boostFlags]];
    const additionalDimensions = [];
    let hasAddedDimensionsForCTA = false;

    // Let's add additional width and height according to the step's blocks
    // eslint-disable-next-line no-unused-expressions
    step.blocks?.forEach((b) => {
      const dimension =
        blockDimensionsPerSlot[poke.boostFlags][b.type] != null
          ? blockDimensionsPerSlot[poke.boostFlags][b.type]
          : blockDimensions[b.type];

      if (dimension != null) {
        // Prevent from adding twice the CTA if there is a primary and secondary
        if ([BLOCK_PRIMARY_CTA, BLOCK_SECONDARY_CTA].includes(b.type)) {
          if (hasAddedDimensionsForCTA === true) {
            return;
          } else {
            hasAddedDimensionsForCTA = true;
          }
        }
        // Do not add open question dimension if a body is already added
        if (
          b.type === BLOCK_OPEN_QUESTION &&
          step.blocks.findIndex((sb) => sb.type === BLOCK_BODY) >= 0
        ) {
          return;
        }
        additionalDimensions.push(dimension);
      }
    });
    additionalDimensions.forEach((additional) => {
      totalDimension[0] += additional[0];
      totalDimension[1] += additional[1];
    });
    // Let's see if the height has been forced
    if (step?.style?.height != null) {
      totalDimension[1] = step?.style?.height;
    } else {
      totalDimension[1] = null;
    }
    if (step?.style?.width != null) {
      totalDimension[0] = step?.style?.width;
    }
    return totalDimension;
  };

  const [dimension, setDimension] = useState(computeDimension());

  useEffect(() => {
    setDimension(computeDimension());
  }, [poke, step]);

  return dimension;
};

export const useBlockDimension = (blockType) => {
  const {poke} = useContext(PokeContext);

  return blockDimensionsPerSlot[poke.boostFlags][blockType] != null
    ? blockDimensionsPerSlot[poke.boostFlags][blockType]
    : blockDimensions[blockType];
};
