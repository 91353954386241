import {func, number} from 'prop-types';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import RadioGroup from '../../../../../../../components/RadioGroup';
import PixelPicker from '../../items/PixelPicker';
import './_Styles.scss';

const widthOptions = [
  {label: 'Auto', value: 'auto'},
  {label: 'Custom', value: 'custom'},
];

const propTypes = {
  value: number,
  onChange: func,
};

const defaultProps = {
  value: null,
  onChange: () => {},
};

const Width = ({value, onChange}) => {
  const type = value !== null ? 'custom' : 'auto';

  return (
    <>
      <SectionItem>
        <RadioGroup
          value={type}
          options={widthOptions}
          onSelect={(value) => {
            if (value === 'auto') {
              onChange(null);
            } else {
              onChange(400);
            }
          }}
        />
      </SectionItem>
      {type === 'custom' && (
        <div className="section-item width-picker">
          <PixelPicker
            value={value}
            min={100}
            max={900}
            onChange={(value) => onChange(value)}
          />
        </div>
      )}
    </>
  );
};

Width.propTypes = propTypes;
Width.defaultProps = defaultProps;

export default Width;
