import classnames from 'classnames';
import Markdown from 'markdown-to-jsx';
import React, {useContext, useEffect, useState} from 'react';
import sanitizeHtml from 'sanitize-html';
import {hasFlag} from '../../../../helpers/bitwise';
import {BLOCK_BODY} from '../../constants/blocks';
import {F_SLOT_CURSOR, F_SLOT_HINT, F_SLOT_TOP_BAR} from '../../constants/poke';
import {PokeContext, PokeStateContext} from '../../context';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import {VariableToSpan} from '../BlockTitle';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './styles.scss';
import {htmlToText} from 'html-to-text';

export const decodeHtml = (html) => {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

export const BlockBody = () => {
  const {poke, user} = useContext(PokeContext);
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    language,
    onUrlClick,
    addFontFamily,
  } = useContext(PokeStateContext);

  const playAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_BODY,
  });

  const block = currentStep.blocks.find((b) => b.type === 'BODY');
  const {fontColor, fontFamily, align, ...restStyle} = block.style;

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  const translation = block.translations?.find((t) => t.language === language);

  const sanitizedContent = sanitizeHtml(
    translation != null
      ? decodeHtml(translation.value)
      : decodeHtml(block.value),
    {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat([
        'img',
        'iframe',
        'ins',
        'del',
        'variable',
      ]),
      allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        a: ['href', 'target'],
        variable: ['class', 'data-attribute', 'data-fallback', 'data-source'],
      },
    }
  );

  const isInPokeBanner = hasFlag(F_SLOT_TOP_BAR, poke.boostFlags);

  const isHint = hasFlag(F_SLOT_HINT, poke.boostFlags);
  const isCursor = hasFlag(F_SLOT_CURSOR, poke.boostFlags);

  const [visibleWords, setVisibleWords] = useState([]);

  const text = htmlToText(sanitizedContent);

  const maxDelayPerWord = Math.min(100, 1000 / text.split(' ').length);

  useEffect(() => {
    if (isCursor !== true) {
      return;
    }

    if (inBuilder === true) {
      return setVisibleWords(text.split(' '));
    }

    const words = text.split(' ');
    let timeoutId;

    const showWords = () => {
      setVisibleWords([]);
      for (let i = 0; i < words.length; i++) {
        timeoutId = setTimeout(() => {
          setVisibleWords((prev) => [...prev, words[i]]);
        }, i * maxDelayPerWord);
      }
    };

    showWords();

    return () => {
      clearTimeout(timeoutId); // Clean up on unmount
    };
  }, [text, maxDelayPerWord]);

  document.documentElement.style.setProperty(
    '--poke-block-body-font-size',
    `${restStyle.fontSize}px`
  );

  const ATagtoDiv = ({children, ...props}) => {
    const {href} = props;
    return (
      <span className="a-div" onClick={() => onUrlClick(href)}>
        {children}
      </span>
    );
  };

  return (
    <div
      className={classnames('poke-block-body', `align-${align}`, {
        'poke-block-clickable': inBuilder === true,
        'is-animating-out': playAnimationOut === true,
        'is-in-poke-banner': isInPokeBanner === true,
        selected: selectedBlock === BLOCK_BODY,
        'is-cursor': isCursor === true,
        'in-builder': inBuilder === true,
      })}
      style={{
        ...restStyle,
        fontFamily: `${fontFamily}, ui-sans-serif, system-ui, sans-serif`,
        color: fontColor,
      }}
      onClick={(e) => {
        e.stopPropagation();
        onBlockSelected(BLOCK_BODY);
      }}>
      {isCursor === true ? (
        <>
          {visibleWords.map((word, index) => (
            <span
              key={index}
              className={classnames('word', 'bypass-animation-disabled')}>
              {word}{' '}
            </span>
          ))}
        </>
      ) : (
        <Markdown
          className="body-markdown"
          options={{
            overrides: {
              a: {
                component: ATagtoDiv,
              },
              variable: {
                component: (props) => (
                  <VariableToSpan
                    {...props}
                    user={user}
                    inBuilder={inBuilder}
                  />
                ),
              },
            },
          }}>
          {sanitizedContent}
        </Markdown>
      )}
      <ClickableBlockOverlay withoutOverflow={isHint} />
    </div>
  );
};
