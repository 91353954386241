import Divider from 'components/Divider';
import RadioGroup from 'components/RadioGroup';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX} from 'services/steps';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import IconPicker, {
  checklistCheckboxIconsList,
  HINT_ICON_BUILT_IN,
} from '../../components/modals/IconPicker';
import ColorItem from '../../components/sectionItems/ColorItem';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import './_Styles.scss';

const borderStyleOptions = [
  {label: 'Solid', value: 'solid'},
  {label: 'Dashed', value: 'dashed'},
];

const ChecklistItemCheckbox = () => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    selectedChecklistItemId,
  } = useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX, updateObj);
  };

  const block = step?.blocks.find(
    (b) =>
      b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX &&
      b.parentBlockId === selectedChecklistItemId
  );

  if (block == null) {
    return <></>;
  }

  const {style, file} = block;

  return (
    <div className="block-settings checklist-item-checkbox">
      <Section
        title={
          <>
            <i className="isax isax-main-component5 p-500" />
            Style
          </>
        }
        titleExtra={
          <>
            <div className="body-4 n-700">For every</div>
            <div className="block-group subtitle-4 n-800">
              <div className="icon-wrapper">
                <i className="icon-tick" />
              </div>
              Checkmark
            </div>
          </>
        }
      />
      <Divider />
      <ChecklistItemCheckboxStyle
        style={style}
        updateStyle={updateBlock}
        file={file}
      />
    </div>
  );
};

export const ChecklistItemCheckboxStyle = ({style, updateStyle, file}) => {
  return (
    <>
      <Section title="Incomplete">
        <CheckboxIncompleteStyle style={style} updateStyle={updateStyle} />
      </Section>
      <Divider />
      <Section title="Completed">
        <CheckboxCompleteStyle
          style={style}
          updateStyle={updateStyle}
          file={file}
        />
      </Section>
    </>
  );
};

export const CheckboxIncompleteStyle = ({style, updateStyle}) => {
  return (
    <>
      <ColorItem
        title="Background"
        value={style.incompleteBackgroundColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, incompleteBackgroundColor: value},
          })
        }
      />
      <ColorItem
        title="Border"
        value={style.incompleteBorderColor || null}
        onChange={(value) =>
          updateStyle({
            style: {
              ...style,
              incompleteBorderColor: value,
            },
          })
        }
        colorPickerProps={{erasable: true}}
      />

      <SectionItem title="Border style">
        <RadioGroup
          value={style.borderStyle}
          options={borderStyleOptions}
          onSelect={(value) => {
            updateStyle({
              style: {
                ...style,
                borderStyle: value,
              },
            });
          }}
        />
      </SectionItem>
      <RadiusItem
        value={style.borderRadius}
        onChange={(value) =>
          updateStyle({
            style: {
              ...style,
              borderRadius: value,
            },
          })
        }
        max={50}
      />
    </>
  );
};

export const CheckboxCompleteStyle = ({style, updateStyle, file}) => {
  const selectedIcon = checklistCheckboxIconsList.find(
    (i) => i.value === style.iconName
  );

  return (
    <>
      <ColorItem
        title="Background"
        value={style.completedBackgroundColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, completedBackgroundColor: value},
          })
        }
      />

      <ColorItem
        title="Border"
        value={style.completedBorderColor || null}
        onChange={(value) =>
          updateStyle({
            style: {
              ...style,
              completedBorderColor: value,
            },
          })
        }
        colorPickerProps={{erasable: true}}
      />
      <SectionItem title="Icon" sectionItemClassName="item-icon-picker">
        <PopupSetting
          className="picker-icon-popup"
          trigger={
            <ClickableInput
              value={
                style.iconSource === HINT_ICON_BUILT_IN
                  ? selectedIcon?.label
                  : style.iconUrl != null
                  ? 'Custom Icon'
                  : null
              }
              leftLabel={
                <span className="preview-icon" style={{}}>
                  {style.iconSource === HINT_ICON_BUILT_IN ? (
                    selectedIcon?.icon
                  ) : style.iconUrl ? (
                    <img src={style.iconUrl} alt="" />
                  ) : (
                    <></>
                  )}
                </span>
              }
              inputProps={{
                placeholder: 'Select an icon',
              }}
            />
          }
          title="Checkmark icons"
          content={
            <IconPicker
              style={style}
              file={file}
              type="checkbox"
              onChange={({style: updatedStyle, file}) => {
                updateStyle({
                  style: {
                    ...style,
                    ...updatedStyle,
                  },
                  file,
                });
              }}
            />
          }
        />
      </SectionItem>
      {style.iconSource === HINT_ICON_BUILT_IN && (
        <ColorItem
          title="Icon color"
          value={style.iconColor}
          onChange={(value) =>
            updateStyle({
              style: {...style, iconColor: value},
            })
          }
        />
      )}
    </>
  );
};

export default ChecklistItemCheckbox;
