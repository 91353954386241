import classnames from 'classnames';
import React, {useContext} from 'react';
import {Media} from 'shared/front/components/Poke/components/BlockMedia';
import {BLOCK_MEDIA} from 'shared/front/components/Poke/constants/blocks';
import {PostContext} from '../../context';
import './_Styles.scss';

const PostBlockThumbnail = ({isDetail = false, isFeed = false}) => {
  const {post, theme, onImageClick, ThumbnailWrapper} = useContext(PostContext);
  // thumbnail container only needed for post list, for motion animation related

  const step = post.steps[0] || {};
  const block = step?.blocks.find((b) => b.type === BLOCK_MEDIA);

  if (block == null) {
    return null;
  }

  const {cards} = theme ?? {};
  const {borderRadius} = cards ?? {};

  return (
    <div
      className={classnames('post-block-thumbnail', {
        'is-feed': isFeed,
      })}>
      <ThumbnailWrapper>
        <Media
          block={block}
          borderRadius={borderRadius}
          extraStyle={
            isDetail || isFeed
              ? {
                  borderRadius: 0,
                }
              : {
                  borderRadius: `${borderRadius}px ${borderRadius}px 0px 0px`,
                }
          }
          onImageClick={onImageClick}
          isDisableAnimation
        />
      </ThumbnailWrapper>
    </div>
  );
};

export default PostBlockThumbnail;
