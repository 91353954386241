import classNames from 'classnames';
import {addFlag, hasFlag} from 'helpers/bitwise';
import {MarkerType} from 'reactflow';
import {getCustomizationFromTheme} from 'router/Layouts';
import {getBoostFlags} from 'scenes/Pushes/components/ModalCreatePoke';
import {
  replaceUIDsInObject,
  TYPE_CURSOR,
  TYPE_HOTSPOT,
  TYPE_MODAL,
  TYPE_NAVIGATION,
  TYPE_SNIPPET,
  TYPE_TOOLTIP,
} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal/templates';
import {defaultHotspotStyle} from 'scenes/Settings/scenes/Themes/components/ThemeEditor';
import {ACTIVE_OPERATOR_SINGLE_URL} from 'services/event';
import {
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
  F_BOOST_SLOT_TOOLTIP,
  F_OPTION_POKE_CARD_WITH_POINTER,
  F_OPTION_PROGRESS_ON_TARGET_CLICK,
  getDefaultEvolution,
  getDefaultOptionsFlags,
} from 'services/evolution';
import {
  BLOCK_TYPE_ANIMATION,
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_CONCEPT,
  BLOCK_TYPE_CURSOR,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_NPS,
  BLOCK_TYPE_OPEN_QUESTION,
  BLOCK_TYPE_OPINION,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_SLIDER,
  BLOCK_TYPE_STEPPER,
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_USER,
  getDefaultStep,
  getDefaultStepStyle,
  STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
  STEP_TYPE_CONCEPT_TEST,
  STEP_TYPE_INTERVIEW,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_SUCCESS,
  STEP_TYPE_TEXT_BLOCK,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import {v4 as uuidv4} from 'uuid';
import {getDefaultBlockFromType} from '../BlockManager/utils';
import {getNewStepName} from '../DiscoveryStepsManager/utils';

export const generateNodes = (evolution) => {
  const isTour = evolution.type === EVOLUTION_TYPE_TOUR;
  const isSurvey = evolution.type === EVOLUTION_TYPE_SURVEY;

  const steps =
    isTour === true
      ? evolution.tourSteps.reduce((acc, tourStep) => {
          return acc.concat(
            tourStep.steps
              .filter((step) => !step.removed)
              .map((step) => ({
                step,
                evolution: tourStep,
                tourStepIndex: parseInt(
                  tourStep.tourStepInfo.split(';')[0],
                  10
                ),
              }))
          );
        }, [])
      : isSurvey === true
      ? evolution.steps
          .filter((step) => !step.removed)
          .map((step) => ({
            step,
            evolution: evolution,
          }))
      : [];

  steps.sort((a, b) => {
    // first sort on tourStepIndex and then on indexOrder
    const tourStepIndexA = a.tourStepIndex;
    const tourStepIndexB = b.tourStepIndex;
    const indexOrderA = a.indexOrder;
    const indexOrderB = b.indexOrder;

    if (tourStepIndexA < tourStepIndexB) {
      return -1;
    }
    if (tourStepIndexA > tourStepIndexB) {
      return 1;
    }
    if (indexOrderA < indexOrderB) {
      return -1;
    }
    if (indexOrderA > indexOrderB) {
      return 1;
    }
    return 0;
  });

  const result = steps.reduce(
    (acc, step, index) => {
      const {nodes, edges} = acc;
      const node = {
        id: step.step.uid,
        data: {
          label: step.step.name,
          step: step.step,
          evolution: step.evolution,
          index,
        },
        position: {
          x: index * 500,
          y: 200,
        },
        type: 'stepNode',
        dragHandle: '.custom-drag-handle',
      };
      nodes.push(node);

      const canAddConditions = [
        STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
        STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
        STEP_TYPE_NPS,
        STEP_TYPE_SLIDER,
        STEP_TYPE_OPINION_SCALE,
        STEP_TYPE_TEXT_LONG,
      ].includes(step.step.type);

      const isTooltip = step.evolution.boostFlags === F_BOOST_SLOT_TOOLTIP;
      const hasProgressOnTargetClick = hasFlag(
        F_OPTION_PROGRESS_ON_TARGET_CLICK,
        step.evolution.optionsFlags
      );

      const nextStep = steps[index + 1];
      const previousStep = steps[index - 1];
      const primaryCtaBlock = step.step.blocks?.find(
        (block) => block.type === BLOCK_TYPE_PRIMARY_CTA && !block.removed
      );
      const secondaryCtaBlock = step.step.blocks?.find(
        (block) => block.type === BLOCK_TYPE_SECONDARY_CTA && !block.removed
      );

      const primaryCtaGoToStepAction = primaryCtaBlock?.actions?.find(
        (action) => action.type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP
      );
      const secondaryCtaGoToStepAction = secondaryCtaBlock?.actions?.find(
        (action) => action.type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP
      );
      const actionsArr = [
        {action: primaryCtaGoToStepAction, type: BLOCK_TYPE_PRIMARY_CTA},
        {action: secondaryCtaGoToStepAction, type: BLOCK_TYPE_SECONDARY_CTA},
      ];

      if (canAddConditions === true && primaryCtaBlock == null) {
        if (step.step.endSurvey !== true && nextStep != null) {
          const goToStep =
            steps.find(
              (s) => s.step.uid === (step.step.goTo?.uid || step.step.goTo)
            ) || nextStep;

          edges.push({
            id: `${step.step.uid}-${goToStep?.step?.uid}`,
            data: {
              step: step.step,
            },
            source: step.step.uid,
            sourceHandle: `${step.step.uid}-default-handle`,
            target: goToStep?.step?.uid,
            markerEnd: {
              type: MarkerType.Arrow,
              width: 20,
              height: 20,
              color: '#b1b1b1',
            },
            style: {
              strokeWidth: 1,
              stroke: '#b1b1b1',
            },
            className: 'default-edge',
          });
        }
      }

      for (const actionIndex in actionsArr) {
        const action = actionsArr[actionIndex].action;
        const type = actionsArr[actionIndex].type;
        const isPrimaryCta = actionIndex === '0';

        if (action != null) {
          let goToStep = null;

          if (isSurvey && isPrimaryCta) {
            if (step.step.endSurvey !== true) {
              goToStep =
                steps.find(
                  (s) => s.step.uid === (step.step.goTo?.uid || step.step.goTo)
                ) || nextStep;
            }
          } else if (action.value === 'next-step') {
            if (nextStep == null) {
              continue;
            }
            goToStep = nextStep;
          } else if (action.value === 'previous-step') {
            goToStep = previousStep;
          } else {
            goToStep = steps.find((s) => s.step.uid === action.value);
          }

          edges.push({
            id: `${step.step.uid}-${goToStep?.step?.uid}`,
            data: {
              step: step.step,
              blockType: isPrimaryCta
                ? BLOCK_TYPE_PRIMARY_CTA
                : BLOCK_TYPE_SECONDARY_CTA,
            },
            source: step.step.uid,
            sourceHandle: `${step.step.uid}-${type}`,
            target: goToStep?.step?.uid,
            markerEnd: {
              type: MarkerType.Arrow,
              width: 20,
              height: 20,
              color: '#b1b1b1',
            },
            style: {
              strokeWidth: 1,
              stroke: '#b1b1b1',
            },
            className: classNames('default-edge', type.toLowerCase()),
          });
        }
      }

      step.step.triggers?.forEach((trigger) => {
        const goToStepAction = trigger.actions.find(
          (action) => action.type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP
        );

        if (goToStepAction) {
          let goToStep = null;

          if (goToStepAction.value === 'next-step') {
            goToStep = nextStep;
          } else if (goToStepAction.value === 'previous-step') {
            goToStep = previousStep;
          } else {
            goToStep = steps.find((s) => s.step.uid === goToStepAction.value);
          }

          edges.push({
            id: `${step.step.uid}-${trigger.uid}`,
            data: {
              step: step.step,
              trigger,
            },
            source: step.step.uid,
            sourceHandle: trigger.uid,
            target: goToStep?.step?.uid,
            markerEnd: {
              type: MarkerType.Arrow,
              width: 20,
              height: 20,
              color: '#b1b1b1',
            },
            style: {
              strokeWidth: 1,
              stroke: '#b1b1b1',
            },
            className: 'trigger-edge',
          });
        }
      });

      if (
        isTooltip === true &&
        hasProgressOnTargetClick === true &&
        nextStep != null
      ) {
        edges.push({
          id: `${step.step.uid}-${nextStep.step.uid}-target`,
          data: {
            step: step.step,
          },
          source: step.step.uid,
          sourceHandle: `${step.step.uid}-target`,
          target: nextStep.step.uid,
          markerEnd: {
            type: MarkerType.Arrow,
            width: 20,
            height: 20,
            color: '#b1b1b1',
          },
          style: {
            strokeWidth: 1,
            stroke: '#b1b1b1',
          },
          className: 'default-edge',
        });
      }

      return acc;
    },
    {nodes: [], edges: []}
  );

  return result;
};

/**
 * Use this function to automatically layout the nodes
 */
export const autoLayout = (nodes) => {
  let currentX = 0;
  const centerY = 100;

  for (const node of nodes) {
    const yPos = centerY - node.height / 2;
    node.position = {x: currentX, y: yPos};
    currentX += node.width + 200;
  }

  return nodes;
};

/**
 * Use this function to adjust the position of the nodes when dragging a node
 */
export const autoLayoutDragging = (nodes, draggedNode, afterNode) => {
  // Sort nodes based on their index to ensure proper order
  nodes.sort((a, b) => a.data.index - b.data.index);

  const draggedIndex = nodes.findIndex((node) => node.id === draggedNode?.id);
  const afterIndex = nodes.findIndex((node) => node.id === afterNode?.id);

  const placeholderWidth = 200; // Width of the placeholder node
  const draggedNodeWidth = (draggedNode?.width || placeholderWidth) + 200; // 200 is the horizontal spacing between nodes

  // Create a new array to store the adjusted nodes
  const adjustedNodes = nodes.map((node, index) => {
    /**
     * Case 1: Dragged node is not present
     */
    if (draggedNode == null) {
      if (index > afterIndex) {
        // Move the nodes after the 'afterNode' to the right
        return {
          ...node,
          position: {
            ...node.position,
            x: node.position.x + draggedNodeWidth,
          },
        };
      }
    } else {
      /**
       * Case 2: Dragged node is present
       */
      if (index === draggedIndex) {
        return node; // Do not change the position of the dragged node
      }

      if (index > draggedIndex && index > afterIndex) {
        // Move the nodes after the dragged node to the right
        node = {
          ...node,
          position: {
            ...node.position,
            x: node.position.x - draggedNodeWidth,
          },
        };
      }

      if (index > afterIndex) {
        // Move the nodes after the 'afterNode' to the right
        return {
          ...node,
          position: {
            ...node.position,
            x: node.position.x + draggedNodeWidth,
          },
        };
      } else if (index > draggedIndex) {
        // Move the nodes after the original position of the dragged node to the left
        return {
          ...node,
          position: {
            ...node.position,
            x: node.position.x - draggedNodeWidth,
          },
        };
      }
    }
    // Return node without any position change if it is before the 'afterNode' and before the original position of draggedNode
    return node;
  });

  let placeholderNode = {
    id: 'placeholder',
    type: 'stepPlaceholder',
    position: {
      y: -40,
      x:
        afterIndex !== -1
          ? adjustedNodes[afterIndex].position.x +
            adjustedNodes[afterIndex].width +
            200
          : 0,
    },
  };

  if (draggedNode != null) {
    // Create the placeholder node
    placeholderNode = {
      ...draggedNode,
      id: 'placeholder', // Unique ID for the placeholder
      position: {
        ...draggedNode.position,
        x:
          afterIndex !== -1
            ? adjustedNodes[afterIndex].position.x +
              adjustedNodes[afterIndex].width +
              200
            : 0,
      },
      data: {
        ...draggedNode.data,
        isPlaceholder: true, // Indicate that this node is a placeholder
      },
    };
  } else {
    // If there is no dragged node, set the width of the placeholder node
    placeholderNode.width = placeholderWidth;
  }

  adjustedNodes.unshift(placeholderNode);

  const stillNodes = adjustedNodes
    .filter((node) => node.id !== draggedNode?.id)
    .sort((a, b) => a.position.x - b.position.x);

  const edges = [];
  for (const nodeIndex in stillNodes) {
    if (nodeIndex < stillNodes.length - 1) {
      edges.push({
        id: `${stillNodes[nodeIndex].id}-${stillNodes[+nodeIndex + 1].id}`,
        source: stillNodes[nodeIndex].id,
        target: stillNodes[+nodeIndex + 1].id,
        markerEnd: {
          type: MarkerType.Arrow,
          width: 20,
          height: 20,
          color: '#b1b1b1',
        },
        style: {
          strokeWidth: 1,
          stroke: '#b1b1b1',
        },
        className: 'default-edge',
      });
    }
  }

  return {nodes: adjustedNodes, edges};
};

export const addNewStep = ({evolution, type, afterStepId, project, preset}) => {
  const isTour = evolution.type === EVOLUTION_TYPE_TOUR;
  const isSurvey = evolution.type === EVOLUTION_TYPE_SURVEY;

  if (isTour) {
    return addNewStepToTour({
      evolution,
      type,
      afterStepId,
      project,
      preset,
    });
  } else if (isSurvey) {
    return addNewStepToSurvey({
      evolution,
      type,
      afterStepId,
      project,
    });
  }
};

const addNewStepToTour = ({evolution, type, afterStepId, project, preset}) => {
  let tourSteps = evolution.tourSteps;
  let newStep = null;

  const afterStepIndex = evolution.tourSteps
    ?.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder)
    .map((ts) => ts.steps?.[0]?.uid)
    .indexOf(afterStepId);

  if (type != null) {
    const profileBlockInFirstStep =
      evolution.tourSteps?.[0]?.steps?.[0]?.blocks?.find(
        (b) => b.type === BLOCK_TYPE_USER
      );
    const stepperBlockInExistingSteps = evolution.tourSteps
      ?.map((ts) => ts.steps)
      ?.flat()
      ?.map((s) => s.blocks)
      ?.flat()
      ?.find((b) => b.type === BLOCK_TYPE_STEPPER);
    const themeStepStyle =
      evolution.theme?.style?.stepStyle != null
        ? JSON.parse(JSON.stringify(evolution.theme?.style?.stepStyle))
        : null;
    delete themeStepStyle?.width;
    delete themeStepStyle?.height;
    const hotspotStyle = evolution.theme?.style?.blocksStyle?.['HOTSPOT'];
    let optionsFlags = getDefaultOptionsFlags();

    if (type === TYPE_TOOLTIP) {
      optionsFlags = addFlag(optionsFlags, F_OPTION_PROGRESS_ON_TARGET_CLICK);
      optionsFlags = addFlag(optionsFlags, F_OPTION_POKE_CARD_WITH_POINTER);
    }

    if (type === TYPE_CURSOR) {
      optionsFlags = addFlag(optionsFlags, F_OPTION_PROGRESS_ON_TARGET_CLICK);
    }

    newStep = getDefaultStep({
      name: 'New Step',
      blocks: [
        ...(type === TYPE_CURSOR
          ? [getDefaultBlockFromType(BLOCK_TYPE_CURSOR, evolution.theme)]
          : [getDefaultBlockFromType(BLOCK_TYPE_TITLE, evolution.theme)]),
        getDefaultBlockFromType(BLOCK_TYPE_BODY, evolution.theme),
        ...(profileBlockInFirstStep != null
          ? [
              {
                ...profileBlockInFirstStep,
                uid: uuidv4(),
              },
            ]
          : [TYPE_SNIPPET, TYPE_CURSOR].includes(type)
          ? [
              {
                ...getDefaultBlockFromType(BLOCK_TYPE_USER, evolution.theme),
              },
            ]
          : []),
        ...(stepperBlockInExistingSteps != null
          ? [{...stepperBlockInExistingSteps, uid: uuidv4()}]
          : []),
        ...(type === TYPE_MODAL
          ? [
              {
                ...getDefaultBlockFromType(BLOCK_TYPE_MEDIA, evolution.theme),
              },
            ]
          : []),
        ...([TYPE_MODAL, TYPE_SNIPPET, TYPE_HOTSPOT].includes(type)
          ? [
              {
                ...getDefaultBlockFromType(
                  BLOCK_TYPE_PRIMARY_CTA,
                  evolution.theme
                ),
              },
            ]
          : []),
      ],
      ...(type === TYPE_CURSOR
        ? {
            style: {
              ...getDefaultStepStyle(),
              paddingTop: 12,
              paddingBottom: 12,
              paddingLeft: 12,
              paddingRight: 12,
              gap: 8,
            },
          }
        : {}),
    });

    const newTourStep = getDefaultEvolution({
      uid: uuidv4(),
      boostFlags: getBoostFlags(type),
      ...getCustomizationFromTheme(type, project.theme),
      ...(type === TYPE_NAVIGATION
        ? {
            boostedActiveOperator: ACTIVE_OPERATOR_SINGLE_URL,
          }
        : {}),
      ...(type === TYPE_TOOLTIP ? {boostedPositionFlags: 0} : {}),
      style:
        themeStepStyle ||
        getDefaultStepStyle({
          ...([TYPE_MODAL, TYPE_TOOLTIP].includes(type)
            ? {overlay: '#0000001a'} // 10% opacity overlay
            : {}),
        }),
      steps: type !== TYPE_NAVIGATION ? [newStep] : [],
      boostedDotStyle: hotspotStyle?.style || defaultHotspotStyle,
      optionsFlags: optionsFlags,
    });

    tourSteps = [
      ...(evolution.tourSteps?.slice(0, afterStepIndex + 1) || []),
      newTourStep,
      ...(evolution.tourSteps?.slice(afterStepIndex + 1) || []),
    ];

    tourSteps.forEach((ts, index) => {
      ts.tourStepInfo = [index].join(';');
    });
  } else if (preset != null) {
    newStep = JSON.parse(JSON.stringify(preset.steps[0]));
    replaceUIDsInObject(newStep);

    const newTourStep = getDefaultEvolution({
      uid: uuidv4(),
      boostFlags: preset.boostFlags,
      style: preset.style,
      ...getCustomizationFromTheme(type, project.theme),
      optionsFlags: preset.optionsFlags,
      steps: [newStep],
    });

    tourSteps = [
      ...(evolution.tourSteps?.slice(0, afterStepIndex + 1) || []),
      newTourStep,
      ...(evolution.tourSteps?.slice(afterStepIndex + 1) || []),
    ];

    tourSteps.forEach((ts, index) => {
      ts.tourStepInfo = [index].join(';');
    });
  }

  return {tourSteps, newStep};
};

const addNewStepToSurvey = ({evolution, type, afterStepId, project}) => {
  const profileBlockInFirstStep = evolution.steps?.[0]?.blocks?.find(
    (b) => b.type === BLOCK_TYPE_USER
  );
  const stepperBlockInExistingSteps = evolution.steps
    ?.map((s) => s.blocks)
    ?.flat()
    ?.find((b) => b.type === BLOCK_TYPE_STEPPER);

  const newStep = getDefaultStep({
    name: getNewStepName(type, evolution.steps),
    type,
    blocks: [
      {
        ...getDefaultBlockFromType(BLOCK_TYPE_TITLE, evolution.theme),
        ...(type === STEP_TYPE_SUCCESS
          ? {value: 'Thank you for your input!|-|none|-|'}
          : {}),
      },

      ...(type === STEP_TYPE_TEXT_BLOCK
        ? [
            getDefaultBlockFromType(BLOCK_TYPE_BODY, evolution.theme),
            getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA, evolution.theme),
          ]
        : []),
      ...(type === STEP_TYPE_SUCCESS
        ? [
            {
              ...getDefaultBlockFromType(BLOCK_TYPE_BODY, evolution.theme),
              value:
                'You just completed our survey. Thank you a lot for helping us improve our product.',
              rawValue: null,
            },
          ]
        : []),
      ...(type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT
        ? [
            getDefaultBlockFromType(BLOCK_TYPE_CHOICE, evolution.theme),
            getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA, evolution.theme),
          ]
        : type === STEP_TYPE_SLIDER
        ? [
            getDefaultBlockFromType(BLOCK_TYPE_SLIDER, evolution.theme),
            getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA, evolution.theme),
          ]
        : type === STEP_TYPE_NPS
        ? [
            getDefaultBlockFromType(BLOCK_TYPE_NPS, evolution.theme),
            getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA, evolution.theme),
          ]
        : type === STEP_TYPE_OPINION_SCALE
        ? [
            getDefaultBlockFromType(BLOCK_TYPE_OPINION, evolution.theme),
            getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA, evolution.theme),
          ]
        : type === STEP_TYPE_TEXT_LONG
        ? [
            getDefaultBlockFromType(BLOCK_TYPE_OPEN_QUESTION, evolution.theme),
            getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA, evolution.theme),
          ]
        : type === STEP_TYPE_SUCCESS
        ? [
            getDefaultBlockFromType(BLOCK_TYPE_ANIMATION, evolution.theme),
            getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA, evolution.theme),
          ]
        : type === STEP_TYPE_INTERVIEW
        ? [
            {
              ...getDefaultBlockFromType(
                BLOCK_TYPE_PRIMARY_CTA,
                evolution.theme
              ),
              value: 'Book a call;step-next;open-booking-url;',
              actions: [
                {
                  uid: uuidv4(),
                  type: STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
                },
              ],
            },
            {
              ...getDefaultBlockFromType(
                BLOCK_TYPE_SECONDARY_CTA,
                evolution.theme
              ),
              value: 'Not now;step-next;none;',
            },
          ]
        : type === STEP_TYPE_CONCEPT_TEST
        ? [
            getDefaultBlockFromType(BLOCK_TYPE_CONCEPT, evolution.theme),
            getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA, evolution.theme),
          ]
        : []),
      ...(profileBlockInFirstStep != null
        ? [{...profileBlockInFirstStep, uid: uuidv4()}]
        : []),
      ...(stepperBlockInExistingSteps != null
        ? [{...stepperBlockInExistingSteps, uid: uuidv4()}]
        : []),
    ],
  });

  let afterStepIndex = evolution.steps.map((s) => s.uid).indexOf(afterStepId);

  const steps = [
    ...(evolution.steps.slice(0, afterStepIndex + 1) || []),
    newStep,
    ...(evolution.steps.slice(afterStepIndex + 1) || []).map((s) => ({
      ...s,
      indexOrder: s.indexOrder + 1,
    })),
  ];

  steps.forEach((s, index) => {
    s.stepInfo = [index].join(';');
  });

  return {steps, newStep};
};
