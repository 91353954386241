import React from 'react';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import ColorPickerInput from '../../items/ColorPickerInput';
import './_Styles.scss';

const ColorItem = ({title, value, onChange, colorPickerProps}) => {
  return (
    <SectionItem title={title}>
      <ColorPickerInput
        inputProps={{small: true}}
        title={title}
        value={value}
        onChange={onChange}
        {...colorPickerProps}
      />
    </SectionItem>
  );
};

export default ColorItem;
