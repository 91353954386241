import ProgressBar from '@ramonak/react-progress-bar';
import classnames from 'classnames';
import React, {useContext} from 'react';
import {BLOCK_STEPPER} from '../../constants/blocks';
import {PokeContext, PokeStateContext} from '../../context';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';

export const STEPPER_TYPE_TEXT = 'text';
export const STEPPER_TYPE_DOT = 'dot';
export const STEPPER_TYPE_DOT_LINE = 'dot-line';
export const STEPPER_TYPE_LINE = 'line';

export const BlockStepper = () => {
  const {poke, transStepperTextOf = null} = useContext(PokeContext);
  const {currentStep, onBlockSelected, selectedBlock, inBuilder} =
    useContext(PokeStateContext);

  const playAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_STEPPER,
  });

  const block = currentStep.blocks.find((b) => b.type === BLOCK_STEPPER);
  const {
    type = STEPPER_TYPE_DOT_LINE,
    align,
    primaryColor,
    secondaryColor,
  } = block.style;

  const currentPokeSteps = poke.steps.filter((s) => s.removed !== true);

  const totalStepsLength =
    currentPokeSteps.length + (poke.stepsBefore || 0) + (poke.stepsAfter || 0);

  /** Create CSS variables */
  document.documentElement.style.setProperty(
    '--blockStepperPrimaryColor',
    primaryColor
  );
  document.documentElement.style.setProperty(
    '--blockStepperSecondaryColor',
    secondaryColor
  );

  const stepIndex = currentPokeSteps.findIndex(
    (s) => s.uid === currentStep.uid
  );

  return (
    <div
      className={classnames('poke-block-stepper', {
        'poke-block-clickable': inBuilder === true,
        'is-animating-out': playAnimationOut === true,
        'type-text': type === STEPPER_TYPE_TEXT,
        'type-dot': type === STEPPER_TYPE_DOT,
        'type-dot-line': type === STEPPER_TYPE_DOT_LINE,
        'type-line': type === STEPPER_TYPE_LINE,
        'align-left': align === 'left',
        'align-center': align === 'center',
        'align-right': align === 'right',
        selected: selectedBlock === BLOCK_STEPPER,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onBlockSelected(BLOCK_STEPPER);
      }}>
      {type === STEPPER_TYPE_TEXT && (
        <>
          {stepIndex + (poke.stepsBefore || 0) + 1}{' '}
          {transStepperTextOf != null ? transStepperTextOf : 'of'}{' '}
          {totalStepsLength}
        </>
      )}
      {[STEPPER_TYPE_DOT, STEPPER_TYPE_DOT_LINE].includes(type) &&
        // iterate over an array of length totalStepsLength
        [...Array(totalStepsLength)].map((_, index) => {
          return (
            <div
              key={index}
              className={classnames('item-step', {
                active: stepIndex + (poke.stepsBefore || 0) === index,
              })}
            />
          );
        })}
      {type === STEPPER_TYPE_LINE && (
        <ProgressBar
          completed={Math.ceil(
            ((stepIndex + (poke.stepsBefore || 0) + 1) / totalStepsLength) * 100
          )}
          className="stepper-line"
          barContainerClassName="stepper-line-container"
          bgColor={primaryColor}
          baseBgColor={secondaryColor}
          isLabelVisible={false}
        />
      )}
      <ClickableBlockOverlay />
    </div>
  );
};
