import classNames from 'classnames';
import Button from 'components/Button';
import InputGroup from 'components/Input';
import {BuilderContext} from 'contextes/builder';
import {GlobalContext} from 'contextes/Global';
import {hasFlag} from 'helpers/bitwise';
import {validateTrigger} from 'helpers/step';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useSelector} from 'react-redux';
import {Handle, Position} from 'reactflow';
import {Dot} from 'scenes/PokeBuilder/components/PokeRender';
import RemoveStepModal from 'scenes/PokeBuilder/components/RemoveStepModal';
import {ActionIcon} from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/TriggerActions';
import {parseResponseValue} from 'scenes/SuccessTracker/components/Event/components/EventConditions/components/EditDropdown/components/SettingsSurveyResponse';
import {buildLogic} from 'scenes/SuccessTracker/components/Event/components/EventConditions/utils';
import {generalSelector} from 'selectors';
import {EVENT_CONDITION_TYPE_SURVEY_RESPONSE} from 'services/event';
import {
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
  F_BOOST_SLOT_CURSOR,
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_NAVIGATION,
  F_BOOST_SLOT_TOOLTIP,
  F_OPTION_POKE_CARD_WITH_POINTER,
} from 'services/evolution';
import {
  BLOCK_TYPE_CURSOR,
  BLOCK_TYPE_HOTSPOT,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  STEP_CONDITION_ACTION_TYPE_DISMISS,
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
  STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import {Poke} from 'shared/front/components/Poke';
import {BlockArrow} from 'shared/front/components/Poke/components/BlockArrow';
import ClickableBlockOverlay from 'shared/front/components/Poke/components/ClickableBlockOverlay';
import Cursor from 'shared/front/components/Poke/components/Cursor';
import {PokeContext} from 'shared/front/components/Poke/context';
import {v4 as uuidv4} from 'uuid';
import {LogicViewContext} from '../../..';
import {positionsArr} from '../../../../BlockEditor/components/modals/Position';
import {MODE_NAVIGATOR, MODE_TRIGGERS} from '../../../../PokeBuilderSidebar';
import {BlockGradient} from '../../../../PokeRender/components/BlockGradient';
import JimoLabel from '../../../../PokeRender/components/JimoLabel';
import {getPokeWrapperStyle} from '../../../../PokeRender/utils';
import {
  ConditionIcon,
  CustomIndicatorLabel,
} from '../../../../TriggerManager/components/Triggers/components/Trigger';
import '../../_Styles.scss';
import {getPointerStyle} from '../../utils';
import TargetElement from '../TargetElement';
import './_Styles.scss';

const Step = ({data, isSelected}) => {
  const {step: stepProps, evolution: evolutionProps, isPlaceholder} = data;

  const {draggedNode, steps} = useContext(LogicViewContext);
  const {
    evolution: parentEvolution,
    setEvolution: setParentEvolution,
    setSelectedBlockType,
    selectedBlockType,
    selectedTriggerId,
    setSelectedTriggerId,
    mode,
    setMode,
    selectedStepId,
    setSelectedStepId,
    updateStep,
    originalEvolution,
  } = useContext(BuilderContext);
  const {addFontFamily} = useContext(GlobalContext);

  const isTour = parentEvolution?.type === EVOLUTION_TYPE_TOUR;
  const isSurvey = parentEvolution?.type === EVOLUTION_TYPE_SURVEY;
  const optionalDependencies = isSurvey && parentEvolution;

  const evolution = useMemo(
    () =>
      isTour === true
        ? parentEvolution.tourSteps.find((s) => s.uid === evolutionProps.uid)
        : isSurvey === true
        ? parentEvolution
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      parentEvolution.tourSteps,
      evolutionProps.uid,
      isTour,
      isSurvey,
      optionalDependencies,
    ]
  );
  const step = useMemo(
    () => evolution?.steps.find((s) => s.uid === stepProps.uid),
    [evolution?.steps, stepProps.uid]
  );
  const triggers = useMemo(() => step?.triggers ?? [], [step?.triggers]);

  const project = useSelector(generalSelector.getProject);

  const [style, setStyle] = useState({});
  const [dimension, setDimension] = useState({width: 0, height: 0});
  const [isDeleting, setIsDeleting] = useState(false);

  const pokeRef = useRef();

  const isStepLive = useMemo(
    () =>
      parentEvolution.isDraft !== true &&
      originalEvolution.tourSteps?.some((s) =>
        s.steps.some((s) => s.uid === step?.uid)
      ),
    [parentEvolution.isDraft, originalEvolution.tourSteps, step?.uid]
  );

  const handleDuplicateStep = useCallback(() => {
    const blockIdsMapping = {};
    const optionIdsMapping = {};

    const newStep = {
      ...JSON.parse(JSON.stringify(step)),
      uid: uuidv4(),
      name: `${step.name} (Copy)`,
      triggers: [],
    };

    newStep.blocks?.forEach((block) => {
      const oldBlockId = block.uid;
      const newBlockId = uuidv4();
      blockIdsMapping[oldBlockId] = newBlockId;
      block.uid = newBlockId;

      block.options?.forEach((option) => {
        const oldId = option.uid;
        const newId = uuidv4();
        optionIdsMapping[oldId] = newId;
        option.uid = newId;

        option.labels?.forEach((label) => {
          delete label.uid;
        });
      });

      block.actions?.forEach((action) => {
        action.uid = uuidv4();
      });
    });

    newStep.triggers?.forEach((trigger) => {
      const conditionIdsMapping = {};
      trigger.uid = uuidv4();
      trigger.conditions?.forEach((condition) => {
        const oldId = condition.uid;
        const newId = uuidv4();
        conditionIdsMapping[oldId] = newId;
        condition.uid = newId;
      });

      const replaceIdsInLogic = (logic) => {
        if (logic.type === 'condition') {
          logic.attributeId = conditionIdsMapping[logic.attributeId];
        } else if (logic.type === 'logicGroup') {
          logic.children = logic.children.map(replaceIdsInLogic);
        }
        return logic;
      };
      trigger.logic = replaceIdsInLogic(trigger.logic);

      trigger.actions?.forEach((action) => {
        action.uid = uuidv4();
      });
    });

    let tourSteps = parentEvolution.tourSteps;
    let steps = parentEvolution.steps;

    if (isTour === true) {
      const newTourStep = {
        ...evolution,
        steps: [newStep],
        uid: uuidv4(),
        boostedQueryselector: null,
        querySelectorManual: null,
        file: null,
      };

      let afterStepIndex = parentEvolution.tourSteps
        ?.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder)
        .map((s) => s.uid)
        .indexOf(evolution?.uid);
      afterStepIndex =
        afterStepIndex === -1 ? evolution.tourSteps?.length : afterStepIndex;
      tourSteps = [
        ...(parentEvolution.tourSteps?.slice(0, afterStepIndex + 1) || []),
        newTourStep,
        ...(parentEvolution.tourSteps?.slice(afterStepIndex + 1) || []),
      ];

      tourSteps.forEach((ts, index) => {
        ts.tourStepInfo = [index].join(';');
      });
    } else if (isSurvey === true) {
      let afterStepIndex = parentEvolution.steps
        ?.sort((a, b) => a.indexOrder - b.indexOrder)
        .map((s) => s.uid)
        .indexOf(step?.uid);
      afterStepIndex = afterStepIndex === -1 ? steps?.length : afterStepIndex;

      steps = [
        ...(parentEvolution.steps?.slice(0, afterStepIndex + 1) || []),
        newStep,
        ...(parentEvolution.steps?.slice(afterStepIndex + 1) || []).map(
          (s) => ({
            ...s,
            indexOrder: s.indexOrder + 1,
          })
        ),
      ];
    }

    setParentEvolution({
      ...parentEvolution,
      tourSteps,
      steps,
    });
    setSelectedStepId(newStep.uid);
    setSelectedBlockType(null);
    setMode(MODE_NAVIGATOR);
  }, [
    setParentEvolution,
    parentEvolution,
    evolution,
    step,
    isTour,
    isSurvey,
    setSelectedStepId,
    setSelectedBlockType,
    setMode,
  ]);

  const handleDeleteStep = useCallback(() => {
    const stepIds =
      isTour === true
        ? parentEvolution.tourSteps
            .map((t) => t.steps)
            .flat()
            .filter((s) => s)
            .map((s) => s.uid)
        : parentEvolution.steps.map((s) => s.uid);

    if (isStepLive === true) {
      setParentEvolution({
        ...parentEvolution,
        ...(isTour === true
          ? {
              tourSteps: parentEvolution.tourSteps.map((t) => {
                t.steps = t.steps.map((s) => {
                  if (s.uid === step.uid) {
                    return {
                      ...s,
                      removed: true,
                    };
                  }
                  return s;
                });
                return t;
              }),
            }
          : {}),
        ...(isSurvey === true
          ? {
              steps: parentEvolution.steps.map((s) => {
                if (s.uid === step.uid) {
                  return {
                    ...s,
                    removed: true,
                  };
                }
                return s;
              }),
            }
          : {}),
      });
    } else {
      setParentEvolution({
        ...parentEvolution,
        ...(isTour === true
          ? {
              tourSteps: parentEvolution.tourSteps
                .map((t) => {
                  t.steps = t.steps.filter((s) => s.uid !== step.uid);
                  return t;
                })
                .filter(
                  (t) =>
                    t.steps.length > 0 ||
                    (hasFlag(F_BOOST_SLOT_NAVIGATION, t.boostFlags) &&
                      t.uid !== step.uid)
                ),
            }
          : {}),
        ...(isSurvey === true
          ? {
              steps: parentEvolution.steps.filter((s) => s.uid !== step.uid),
            }
          : {}),
      });
    }

    if (step.uid === selectedStepId) {
      const index = stepIds.indexOf(step.uid);
      setSelectedStepId(stepIds[index > 0 ? index - 1 : index + 1]);
      setSelectedBlockType(null);
      setMode(null);
    }

    setIsDeleting(false);
  }, [
    isTour,
    isSurvey,
    isStepLive,
    parentEvolution,
    step,
    selectedStepId,
    setSelectedStepId,
    setSelectedBlockType,
    setParentEvolution,
    setMode,
  ]);

  useEffect(() => {
    if (pokeRef.current) {
      const {width, height} = pokeRef.current.getBoundingClientRect();
      setDimension({width, height});
    }
  }, [pokeRef]);

  const currentStepIndex = useMemo(
    () => evolution?.steps.findIndex((s) => s.uid === step?.uid),
    [evolution?.steps, step?.uid]
  );

  const isHeightAuto = useMemo(
    () => evolution?.style?.height == null,
    [evolution?.style?.height]
  );

  const pokeWrapperStyle = useMemo(
    () => getPokeWrapperStyle(evolution?.style),
    [evolution?.style]
  );

  const [indexTourStep] = useMemo(
    () => evolution?.tourStepInfo?.split(';') ?? [0],
    [evolution?.tourStepInfo]
  );

  const {stepsBefore, stepsAfter} = useMemo(
    () =>
      parentEvolution?.tourSteps?.reduce(
        (acc, cur) => {
          if (cur.uid === evolution?.uid) {
            return acc;
          }

          const [index] = cur.tourStepInfo?.split(';');

          if (index < indexTourStep) {
            acc.stepsBefore += cur.steps.filter(
              (s) => s.removed !== true
            ).length;
          } else if (index > indexTourStep) {
            acc.stepsAfter += cur.steps.filter(
              (s) => s.removed !== true
            ).length;
          }
          return acc;
        },
        {stepsBefore: 0, stepsAfter: 0}
      ),
    [parentEvolution, evolution?.uid, indexTourStep]
  );

  const handleSelectedBlockType = useCallback(
    (type) => {
      setMode(MODE_NAVIGATOR);
      setSelectedBlockType(type);
      setSelectedTriggerId(null);
      setSelectedStepId(step.uid);
    },
    [
      setMode,
      setSelectedBlockType,
      setSelectedTriggerId,
      setSelectedStepId,
      step?.uid,
    ]
  );

  const handleAddTrigger = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      const newTrigger = {
        uid: uuidv4(),
        conditions: [
          ...(isSurvey === true
            ? [
                {
                  uid: uuidv4(),
                  type: EVENT_CONDITION_TYPE_SURVEY_RESPONSE,
                  value: null,
                },
              ]
            : []),
        ],
        logic: buildLogic(),
        actions: [
          ...(isTour === true && triggers.length === 0
            ? [
                {
                  uid: uuidv4(),
                  type: STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
                  value: 'next-step',
                },
              ]
            : []),
        ],
      };

      /**
       * Use setParentEvolution instead of updateStep
       * as it wasn't adding the trigger to the step
       * when the step wasn't already selected
       *
       * updateStep(step.uid, {
       *  triggers: [...triggers, newTrigger],
       * });
       */

      setParentEvolution({
        ...parentEvolution,
        tourSteps: parentEvolution.tourSteps.map((t) => {
          if (t.uid === evolution.uid) {
            return {
              ...t,
              steps: t.steps.map((s) => {
                if (s.uid === step.uid) {
                  return {
                    ...s,
                    triggers: [...triggers, newTrigger],
                  };
                }
                return s;
              }),
            };
          }
          return t;
        }),
        steps: parentEvolution.steps.map((s) => {
          if (s.uid === step.uid) {
            return {
              ...s,
              triggers: [...triggers, newTrigger],
            };
          }
          return s;
        }),
      });

      setSelectedStepId(step.uid);
      setSelectedTriggerId(newTrigger.uid);
      setMode(MODE_TRIGGERS);
      setSelectedBlockType(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isSurvey,
      triggers,
      updateStep,
      step?.uid,
      setSelectedStepId,
      setSelectedTriggerId,
      setMode,
      setSelectedBlockType,
    ]
  );

  const handleClick = useCallback(() => {
    setSelectedStepId(data.step.uid);
    setSelectedTriggerId(null);
    setMode(MODE_NAVIGATOR);
  }, [data.step.uid, setSelectedStepId, setSelectedTriggerId, setMode]);

  const hasPointer = useMemo(
    () =>
      hasFlag(F_BOOST_SLOT_TOOLTIP, evolution?.boostFlags) &&
      hasFlag(F_OPTION_POKE_CARD_WITH_POINTER, evolution?.optionsFlags),
    [evolution?.optionsFlags, evolution?.boostFlags]
  );

  const positionItem = useMemo(
    () => positionsArr.find((p) => p.value === evolution?.boostedPositionFlags),
    [evolution?.boostedPositionFlags]
  );
  const position = positionItem?.position;

  const {pointerPosition, forcedOffsetX, forcedOffsetY} = useMemo(
    () =>
      getPointerStyle({
        position,
        dimension,
        hintDimension: {width: 0, height: 0},
        borderRadius: evolution?.style?.borderRadius,
      }),
    [position, dimension, evolution?.style?.borderRadius]
  );

  const {style: pokeStyle} = evolution ?? {};
  const {background} = pokeStyle ?? {};

  const wrapperClassNames = useMemo(
    () =>
      classNames('step-node-content-wrapper', 'custom-drag-handle', {
        'is-editing-content': mode === MODE_NAVIGATOR,
        'is-editing-triggers': mode === MODE_TRIGGERS,
        'is-selected': isSelected,
        'is-placeholder': isPlaceholder,
        'is-dragging': isPlaceholder !== true && draggedNode?.id === step?.uid,
      }),
    [mode, isSelected, isPlaceholder, draggedNode, step?.uid]
  );

  const triggerList = useMemo(
    () =>
      triggers.map((trigger) => {
        const {conditions = [], actions = []} = trigger;

        const goToStepAction = actions.find(
          (action) => action.type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP
        );
        const hasDismissAction = actions.some(
          (action) => action.type === STEP_CONDITION_ACTION_TYPE_DISMISS
        );
        const hasLaunchExperienceAction = actions.some(
          (action) =>
            action.type === STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE
        );

        const issues = validateTrigger(trigger);

        return (
          <div
            className={classNames('trigger-wrapper', {
              'is-selected': isSelected && selectedTriggerId === trigger.uid,
            })}
            key={trigger.uid}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              setSelectedTriggerId(trigger.uid);
              setSelectedStepId(step.uid);
              setMode(MODE_TRIGGERS);
              setSelectedBlockType(null);
            }}
            onMouseDownCapture={(e) => {
              e.stopPropagation();
            }}>
            <div
              className={classNames('trigger-icons-wrapper', {
                'is-selected': isSelected && selectedTriggerId === trigger.uid,
                'is-invalid': issues.length > 0,
              })}
              key={trigger.uid}>
              <CustomIndicatorLabel text="If" className="if" />
              <div
                className={classNames('trigger-icons', {
                  'is-selected':
                    isSelected && selectedTriggerId === trigger.uid,
                })}>
                <div className="condition-icons-wrapper">
                  {conditions.map((condition, index) => {
                    if (isSurvey === true) {
                      const {value, operand} = condition;
                      const responseValue = parseResponseValue(
                        operand,
                        value,
                        step
                      );

                      return (
                        <div className="survey-condition">{responseValue}</div>
                      );
                    } else {
                      return (
                        <ConditionIcon key={index} type={condition.type} />
                      );
                    }
                  })}
                </div>
                <div className="action-icons-wrapper">
                  {actions
                    .filter(
                      (action) =>
                        [
                          STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
                          STEP_CONDITION_ACTION_TYPE_DISMISS,
                          STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
                        ].includes(action.type) !== true
                    )
                    .map((action, index) => (
                      <ActionIcon key={index} type={action.type} />
                    ))}
                </div>

                {!hasDismissAction && !hasLaunchExperienceAction && (
                  <div className="go-to-icon-wrapper"></div>
                )}
              </div>
            </div>
            {hasDismissAction && (
              <div className="dismiss-icon-wrapper">
                <i className="isax isax-close-circle r-500" />
              </div>
            )}
            {hasLaunchExperienceAction && (
              <div className="launch-experience-icon-wrapper">
                <i className="isax isax-routing-2 b-400" />
              </div>
            )}
            {goToStepAction && (
              <Handle
                type="source"
                position={Position.Right}
                id={trigger.uid}
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
                isConnectable={false}
              />
            )}
          </div>
        );
      }),
    [
      triggers,
      setSelectedTriggerId,
      setSelectedStepId,
      isSelected,
      selectedTriggerId,
      setMode,
      setSelectedBlockType,
      isSurvey,
      step,
    ]
  );

  if (!evolution || !step) {
    return null;
  }

  step.blocks = step.blocks?.map((block) => {
    if (
      [BLOCK_TYPE_PRIMARY_CTA, BLOCK_TYPE_SECONDARY_CTA].includes(
        block.type
      ) === true
    ) {
      const {actions = []} = block;

      const goToStepAction = actions.find(
        (action) => action.type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP
      );
      if (goToStepAction != null) {
        const {value} = goToStepAction;
        const targetStep = steps.find((s) => s.uid === value);
        const targetStepIndex = steps.indexOf(targetStep);
        const currentStepIndex = steps.indexOf(step);
        const isLastStep = currentStepIndex === steps.length - 1;

        const isGoingRight =
          value === 'next-step' ||
          (value !== 'previous-step' && targetStepIndex > currentStepIndex);

        if (isLastStep !== true) {
          return {
            ...block,
            handle: () => {
              return (
                <Handle
                  type="source"
                  position={
                    isGoingRight === true ? Position.Right : Position.Left
                  }
                  id={`${step.uid}-${block.type}`}
                  isConnectable={false}
                  isConnectableStart={false}
                  className={classNames('block-handle', {
                    'is-secondary-cta': block.type === BLOCK_TYPE_SECONDARY_CTA,
                  })}
                />
              );
            },
          };
        }
      }
    }
    return block;
  });

  const isTooltip = hasFlag(F_BOOST_SLOT_TOOLTIP, evolution?.boostFlags);
  const isHotspot = hasFlag(F_BOOST_SLOT_DOT, evolution?.boostFlags);
  const isCursor = hasFlag(F_BOOST_SLOT_CURSOR, evolution?.boostFlags);
  const hasTargetElement = isTooltip || isHotspot || isCursor;

  const cursorBlock = step.blocks.find((b) => b.type === BLOCK_TYPE_CURSOR);

  const canAddConditions =
    isTour ||
    (isSurvey &&
      [
        STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
        STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
        STEP_TYPE_NPS,
        STEP_TYPE_SLIDER,
        STEP_TYPE_OPINION_SCALE,
        STEP_TYPE_TEXT_LONG,
      ].includes(step.type));

  return (
    <PokeContext.Provider
      value={{
        poke: {
          ...evolution,
          steps: evolution?.steps?.sort((a, b) => a.indexOrder - b.indexOrder),
          stepsBefore,
          stepsAfter,
        },
        currentStepIndex,
      }}>
      <div className={wrapperClassNames} onClick={handleClick}>
        <div
          className="step-node-info"
          onMouseDownCapture={(e) => {
            // if element is not div with class drag-handle or a nested children of the element with class drag-handle, stop propagation
            if (
              e.target.className !== 'drag-handle' &&
              !e.target.closest('.drag-handle')
            ) {
              e.stopPropagation();
            }
          }}>
          <div
            className="content"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}>
            <div className="title subtitle-4 n-800">
              <InputGroup
                className="step-name-input"
                placeholder="Enter step name..."
                value={step.name || ''}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  setSelectedStepId(step.uid);
                }}
                onChange={(e) => {
                  updateStep(step.uid, {
                    name: e.target.value,
                  });
                }}
                autoFocus
              />
            </div>
            <div className="actions">
              <div
                className="action drag-handle"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}>
                <i className="isax isax-arrow-2" />
              </div>
              <div
                className="action"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  handleDuplicateStep();
                }}>
                <i className="isax isax-copy" />
              </div>
              <div
                className="action"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  setIsDeleting(true);
                }}>
                <i className="isax isax-trash" />
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames('step-node-content', {
            'is-selected': isSelected,
          })}>
          {hasTargetElement && (
            <TargetElement
              evolution={evolution}
              step={step}
              steps={steps}
              isSelected={isSelected}
            />
          )}
          <div className="step-node-content-inner">
            {isHotspot === true && (
              <div
                className={classNames('step-node-dot-wrapper', {
                  selected:
                    isSelected && selectedBlockType === BLOCK_TYPE_HOTSPOT,
                })}
                onMouseDownCapture={(e) => {
                  e.stopPropagation();
                }}
                onClick={(e) => {
                  e.stopPropagation();

                  setMode(MODE_NAVIGATOR);
                  setSelectedStepId(step.uid);
                  setSelectedBlockType(BLOCK_TYPE_HOTSPOT);
                }}>
                <Dot
                  poke={evolution}
                  dimension={dimension}
                  style={{
                    position: 'initial',
                    transform: 'none',
                  }}
                />
                <ClickableBlockOverlay isNotCardElem />
              </div>
            )}

            <div className="step-node-poke-group">
              {isCursor === true && (
                <div
                  className={classNames('cursor-wrapper', {
                    selected:
                      isSelected && selectedBlockType === BLOCK_TYPE_CURSOR,
                  })}
                  onMouseDownCapture={(e) => {
                    e.stopPropagation();
                  }}
                  onClick={(e) => {
                    e.stopPropagation();

                    setMode(MODE_NAVIGATOR);
                    setSelectedStepId(step.uid);
                    setSelectedBlockType(BLOCK_TYPE_CURSOR);
                  }}>
                  <Cursor style={cursorBlock?.style} />
                  <ClickableBlockOverlay isNotCardElem />
                </div>
              )}
              <div
                className={classNames('step-node-poke-wrapper', {
                  selected: isSelected && selectedBlockType == null,
                  'is-cursor': isCursor === true,
                })}
                onMouseDownCapture={(e) => {
                  e.stopPropagation();
                }}>
                <div
                  className={classNames('step-node-poke-render', {
                    'auto-height': isHeightAuto,
                  })}
                  style={{
                    ...style,
                    boxShadow: '0px 9px 16px rgb(12 23 37 / 16%)',
                    width: 'auto',
                    ...(isHeightAuto !== true ? {height: 'auto'} : {}),
                    ...pokeWrapperStyle,
                  }}>
                  <Poke
                    forwardRef={pokeRef}
                    inBuilder
                    onDimensionChange={() => {}}
                    onContainerStyleChange={(style) => {
                      setStyle(style);
                    }}
                    onBlockSelected={handleSelectedBlockType}
                    selectedBlock={isSelected && selectedBlockType}
                    disableAnimations
                    pointerDirection="top"
                    JimoLabel={
                      project.whiteLabeling === false
                        ? () => <JimoLabel background={background} />
                        : undefined
                    }
                    addFontFamily={addFontFamily}
                  />
                  <BlockGradient poke={evolution} />
                </div>
                {hasPointer && (
                  <BlockArrow
                    poke={evolution}
                    direction={pointerPosition}
                    pokeSize={{
                      height: dimension.height,
                      width: dimension.width,
                    }}
                    borderRadius={evolution.style?.borderRadius}
                    forcedOffsetX={forcedOffsetX}
                    forcedOffsetY={forcedOffsetY}
                  />
                )}
                <ClickableBlockOverlay isNotCardElem />
              </div>
            </div>
          </div>
          {canAddConditions && (
            <div className="step-node-triggers-wrapper">
              {triggerList}

              {triggers.length === 0 ? (
                <div className="add-trigger-empty">
                  <div
                    className={classNames('trigger-icons-wrapper')}
                    onClick={handleAddTrigger}
                    onMouseDownCapture={(e) => {
                      e.stopPropagation();
                    }}>
                    <CustomIndicatorLabel
                      text={<i className="isax isax-hierarchy-3" />}
                      className="if"
                    />
                    <div className="content subtitle-4 n-800">
                      <i className="icon-plus" />
                      {isTour === true
                        ? 'Add progress trigger'
                        : 'Add condition'}
                    </div>
                  </div>
                </div>
              ) : (
                <Button
                  iconOnly
                  iconLeft="icon-plus"
                  className="add-trigger-btn"
                  onClick={handleAddTrigger}
                  onMouseDownCapture={(e) => {
                    e.stopPropagation();
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>

      {isDeleting === true && (
        <RemoveStepModal
          isOpen={isDeleting}
          onRequestClose={() => setIsDeleting(false)}
          onConfirm={handleDeleteStep}
          isLiveStep={isStepLive}
        />
      )}
    </PokeContext.Provider>
  );
};

export default memo(Step);
