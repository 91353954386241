import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {useContext} from 'react';
import {F_BLOCK_ZOOM_ON_MEDIA_CLICK} from 'scenes/PokeBuilder/components/BlockManager/utils';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {F_BOOST_SLOT_CURSOR} from 'services/evolution';
import {BLOCK_TYPE_CHECKLIST_HEADER, STEP_TYPE_CHECKLIST} from 'services/steps';
import RadioGroup from '../../../../../../components/RadioGroup';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import Height, {
  HEIGHT_TYPE_AUTO,
  HEIGHT_TYPE_CUSTOM,
} from '../../components/modals/Height';
import MediaLink from '../../components/modals/MediaLink';
import Width from '../../components/modals/Width';
import PaddingItem from '../../components/sectionItems/PaddingItem';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import './_Styles.scss';

const mediaLayoutTypeOptions = [
  {label: <i className="isax isax-row-vertical" />, value: 'horizontal'},
  {label: <i className="isax isax-row-horizontal" />, value: 'vertical'},
];

const mediaPositionOptions = [
  {label: <i className="icon-align-t" />, value: 'top'},
  {label: <i className="icon-align-b" />, value: 'bottom'},
  {label: 'Left', value: 'left'},
  {label: 'Right', value: 'right'},
];

const zoomOnMediaClickOptions = [
  {label: 'Disable', value: false},
  {label: 'Enable', value: true},
];

const Media = () => {
  const {
    controlledEvolution,
    selectedStep: step,
    updateBlock: uptBlock,
    selectedChecklistItemId,
    selectedBlockType,
  } = useContext(BuilderContext);

  const isCursor = hasFlag(controlledEvolution.boostFlags, F_BOOST_SLOT_CURSOR);
  const isChecklistStep = step?.type === STEP_TYPE_CHECKLIST;

  const checklistHeaderBlock = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_HEADER
  );

  const block = step?.blocks.find(
    (b) =>
      b.type === selectedBlockType &&
      ((isChecklistStep !== true && b.parentBlockId == null) ||
        (selectedChecklistItemId == null &&
          [null, checklistHeaderBlock?.uid].includes(b.parentBlockId)) ||
        selectedChecklistItemId === b.parentBlockId)
  );

  const updateBlock = (updateObj, all = false) => {
    if (all !== true && block?.parentBlockId != null) {
      uptBlock(selectedBlockType, updateObj, {
        parentBlockId: block.parentBlockId,
      });
    } else {
      uptBlock(selectedBlockType, updateObj);
    }
  };

  if (block == null) {
    return <></>;
  }

  const {value, file, style, flags} = block;
  const [type, fileUrl, altText, sizing] = value.split(';');

  const hasZoomOnMediaClickFlag = hasFlag(F_BLOCK_ZOOM_ON_MEDIA_CLICK, flags);

  return (
    <div className="block-settings media">
      <Section title="Content">
        <SectionItem title="Media">
          <PopupSetting
            trigger={
              <ClickableInput
                key={fileUrl}
                value={
                  type === 'image' && file != null
                    ? file?.originalName
                    : fileUrl
                }
                erasable={
                  !!(type === 'video' && fileUrl) ||
                  !!(type === 'image' && fileUrl)
                }
                onErase={() => {
                  updateBlock({
                    value: `${type};;${altText};${sizing}`,
                    file: null,
                    fileId: null,
                  });
                }}
              />
            }
            title="Media"
            content={
              <MediaLink
                type={type}
                fileUrl={fileUrl}
                altText={altText}
                sizing={sizing}
                file={file}
                onChange={({value, file}) => {
                  updateBlock({value, file, fileId: file?.uid});
                }}
              />
            }
          />
        </SectionItem>

        {type === 'image' && (
          <SectionItem title="Zoom on click">
            <RadioGroup
              value={hasZoomOnMediaClickFlag}
              options={zoomOnMediaClickOptions}
              onSelect={(value) =>
                updateBlock({
                  flags:
                    value === true
                      ? addFlag(F_BLOCK_ZOOM_ON_MEDIA_CLICK, flags)
                      : removeFlag(F_BLOCK_ZOOM_ON_MEDIA_CLICK, flags),
                })
              }
            />
          </SectionItem>
        )}
      </Section>
      <Divider />
      <Section
        title={
          isChecklistStep ? (
            <>
              <i className="isax isax-main-component5 p-500" />
              Style
            </>
          ) : (
            'Style'
          )
        }
        titleExtra={
          isChecklistStep ? (
            <>
              <div className="body-4 n-700">For every</div>
              <div className="block-group subtitle-4 n-800">
                <div className="icon-wrapper">
                  <i className="icon-image-o" />
                </div>
                Media
              </div>
            </>
          ) : null
        }>
        <MediaStyle
          style={style}
          updateStyle={(value) =>
            updateBlock(
              value,
              isChecklistStep === true && selectedChecklistItemId != null
            )
          }
          hidePosition={isChecklistStep || isCursor}
          hidePadding={isChecklistStep}
        />
      </Section>
    </div>
  );
};

export const MediaStyle = ({style, updateStyle, hidePosition, hidePadding}) => {
  return (
    <>
      {hidePosition !== true && (
        <>
          <SectionItem title="Type">
            <RadioGroup
              value={style.layoutType || 'horizontal'}
              options={mediaLayoutTypeOptions}
              onSelect={(value) =>
                updateStyle({
                  style: {
                    ...style,
                    layoutType: value,
                    ...(value === 'horizontal' &&
                    ['top', 'bottom'].includes(style.position) !== true
                      ? {
                          position: 'top',
                        }
                      : value === 'vertical' &&
                        ['left', 'right'].includes(style.position) !== true
                      ? {
                          position: 'left',
                        }
                      : {}),
                  },
                })
              }
            />
          </SectionItem>
          <SectionItem title="Position">
            <RadioGroup
              value={style.position}
              options={mediaPositionOptions.filter((o) => {
                if (style.layoutType === 'horizontal') {
                  return ['top', 'bottom'].includes(o.value);
                }
                return ['left', 'right'].includes(o.value);
              })}
              onSelect={(value) =>
                updateStyle({style: {...style, position: value}})
              }
            />
          </SectionItem>
        </>
      )}
      {style.layoutType === 'vertical' ? (
        <SectionItem title="Width">
          <PopupSetting
            trigger={
              <ClickableInput
                inputProps={{small: true}}
                value={style.width != null ? `${style.width}px` : 'Auto'}
              />
            }
            title="Width"
            content={
              <Width
                value={style.width}
                onChange={(value) =>
                  updateStyle({
                    style: {...style, width: value},
                  })
                }
              />
            }
          />
        </SectionItem>
      ) : (
        <SectionItem title="Height">
          <PopupSetting
            trigger={
              <ClickableInput
                inputProps={{small: true}}
                value={style.height != null ? `${style.height}px` : 'Auto'}
              />
            }
            title="Height"
            content={
              <Height
                height={style.height}
                type={
                  style.height != null ? HEIGHT_TYPE_CUSTOM : HEIGHT_TYPE_AUTO
                }
                max={500}
                onChangeType={(type) => {
                  if (type === HEIGHT_TYPE_AUTO) {
                    updateStyle({
                      style: {...style, height: undefined},
                    });
                  } else {
                    updateStyle({
                      style: {...style, height: 200},
                    });
                  }
                }}
                onChange={({height}) =>
                  updateStyle({
                    style: {...style, height},
                  })
                }
              />
            }
          />
        </SectionItem>
      )}
      {hidePadding !== true && (
        <PaddingItem
          value={{
            paddingTop: style.paddingTop ?? style.padding,
            paddingBottom: style.paddingBottom ?? style.padding,
            paddingLeft: style.paddingLeft ?? style.padding,
            paddingRight: style.paddingRight ?? style.padding,
          }}
          onChange={(value) =>
            updateStyle({
              style: {
                ...style,
                ...value,
              },
            })
          }
        />
      )}
      <RadiusItem
        value={style.borderRadius}
        onChange={(value) =>
          updateStyle({
            style: {
              ...style,
              borderRadius: value,
            },
          })
        }
      />
    </>
  );
};

export default Media;
