import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import {BuilderContext} from 'contextes/builder';
import {hasFlag} from 'helpers/bitwise';
import React, {useContext, useEffect, useRef} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {F_BOOST_SLOT_TOP_BAR} from 'services/evolution';
import {BLOCK_TYPE_LABEL} from 'services/steps';
import RadioGroup from '../../../../../../components/RadioGroup';
import ColorItem from '../../components/sectionItems/ColorItem';
import FontFamilyItem from '../../components/sectionItems/FontFamilyItem';
import FontSizeItem from '../../components/sectionItems/FontSizeItem';
import FontWeightItem from '../../components/sectionItems/FontWeightItem';
import PaddingItem from '../../components/sectionItems/PaddingItem';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import './_Styles.scss';

const alignOptions = [
  {label: <i className="icon-align-b-l" />, value: 'left'},
  {label: <i className="icon-align-b-c" />, value: 'center'},
  {label: <i className="icon-align-b-r" />, value: 'right'},
];

const Tag = () => {
  const {
    controlledEvolution: evolution,
    selectedStep: step,
    updateBlock: uptBlock,
  } = useContext(BuilderContext);

  const isBannerStep = hasFlag(F_BOOST_SLOT_TOP_BAR, evolution.boostFlags);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_LABEL, updateObj);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current?.focus();
    }, 300); // delay focus input of 300ms to match the animation and prevent whole page from scrolling
    return () => clearTimeout(timeout);
  }, []);

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_LABEL);

  if (block == null) {
    return <></>;
  }

  const {value, style} = block;

  return (
    <div className="block-settings tag">
      <Section title="Content">
        <SectionItem title="Text">
          <InputGroup
            className="tag-content-input"
            value={value}
            onChange={({target}) =>
              updateBlock({
                value: target.value,
              })
            }
            ref={inputRef}
          />
        </SectionItem>
      </Section>
      <Divider />
      <Section title="Layout">
        <TagStyle
          style={style}
          updateStyle={updateBlock}
          hideAlign={isBannerStep}
        />
      </Section>
    </div>
  );
};

export const TagStyle = ({style, updateStyle, hideAlign = false}) => {
  return (
    <>
      <FontFamilyItem
        value={style.fontFamily}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontFamily: value},
          })
        }
      />
      <FontSizeItem
        value={style.fontSize}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontSize: value},
          })
        }
      />
      <FontWeightItem
        value={style.fontWeight}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontWeight: value},
          })
        }
      />
      <ColorItem
        title="Text color"
        value={style.fontColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontColor: value},
          })
        }
      />
      <ColorItem
        title="Background"
        value={style.primaryColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, primaryColor: value},
          })
        }
        colorPickerProps={{erasable: true}}
      />
      <ColorItem
        title="Border"
        value={style.borderColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, borderColor: value},
          })
        }
        colorPickerProps={{erasable: true}}
      />
      <PaddingItem
        value={{
          paddingTop: style.paddingTop ?? style.padding,
          paddingBottom: style.paddingBottom ?? style.padding,
          paddingLeft: style.paddingLeft ?? style.padding,
          paddingRight: style.paddingRight ?? style.padding,
        }}
        onChange={(value) =>
          updateStyle({
            style: {
              ...style,
              ...value,
            },
          })
        }
      />
      <RadiusItem
        value={style.borderRadius}
        onChange={(value) =>
          updateStyle({
            style: {
              ...style,
              borderRadius: value,
            },
          })
        }
      />
      {hideAlign !== true && (
        <SectionItem title="Position">
          <RadioGroup
            value={style.align}
            options={alignOptions}
            onSelect={(value) =>
              updateStyle({
                style: {
                  ...style,
                  align: value,
                },
              })
            }
          />
        </SectionItem>
      )}
    </>
  );
};

export default Tag;
