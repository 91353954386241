import Divider from 'components/Divider';
import { BuilderContext } from 'contextes/builder';
import { useContext, useState } from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import { DropdownAddAction } from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/TriggerActions';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import {
  BLOCK_TYPE_DISMISS_CROSS,
  BLOCK_TYPE_SECONDARY_CTA,
  STEP_CONDITION_ACTION_TYPE_DISMISS,
  STEP_CONDITION_ACTION_TYPE_SNOOZE,
} from 'services/steps';
import { v4 as uuidv4 } from 'uuid';
import ClickableInput from '../../components/items/ClickableInput';
import ColorItem from '../../components/sectionItems/ColorItem';
import FontSizeItem from '../../components/sectionItems/FontSizeItem';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import ButtonAction from '../Button/components/ButtonAction';
import './_Styles.scss';

const DismissCross = () => {
  const {
    evolution,
    selectedStep: step,
    updateBlock: uptBlock,
    selectedBlockType,
    selectedStep,
    updateStep,
  } = useContext(BuilderContext);

  const isTour = evolution?.type === EVOLUTION_TYPE_TOUR;
  const isSurvey = evolution?.type === EVOLUTION_TYPE_SURVEY;
  const isBanner = evolution?.type === EVOLUTION_TYPE_BANNER;

  const isSecondaryCta = selectedBlockType === BLOCK_TYPE_SECONDARY_CTA;
  const isDismissCross = selectedBlockType === BLOCK_TYPE_DISMISS_CROSS;

  const [newlyAddedAction, setNewlyAddedAction] = useState(null);

  const updateBlock = (updateObj) => {
    uptBlock(selectedBlockType, updateObj);
  };

  const handleAddAction = (type, opts = {}) => {
    const newAction = {
      ...opts,
      uid: uuidv4(),
      type,
    };

    updateBlock({
      actions: [...actions, newAction],
    });
    setNewlyAddedAction(newAction);
  };

  const block = step?.blocks.find((b) => b.type === selectedBlockType);
  const steps = isSurvey
    ? evolution.steps.filter((s) => s.removed !== true)
    : evolution.tourSteps
        .map((ts) =>
          ts.steps.map((s) => ({
            ...s,
            tourStepIndex: ts.tourStepInfo.split(';')[0],
          }))
        )
        .flat()
        .filter((s) => s.removed !== true);

  steps.sort(
    (
      {tourStepIndex: tourStepIndexA, indexOrder: indexOrderA},
      {tourStepIndex: tourStepIndexB, indexOrder: indexOrderB}
    ) => {
      // first sort on tourStepIndex and then on indexOrder
      if (tourStepIndexA < tourStepIndexB) {
        return -1;
      }
      if (tourStepIndexA > tourStepIndexB) {
        return 1;
      }
      if (indexOrderA < indexOrderB) {
        return -1;
      }
      if (indexOrderA > indexOrderB) {
        return 1;
      }
      return 0;
    }
  );
  const currentStepIndex = steps.findIndex((s) => s.uid === step.uid);

  if (block == null) {
    return <></>;
  }

  const {style, actions = []} = block;

  const isSnoozeAuthorized =
    (isTour || isSurvey) &&
    currentStepIndex === 0 &&
    (isSecondaryCta || isDismissCross);

  let authorizedActions = [
    STEP_CONDITION_ACTION_TYPE_DISMISS,
    ...(isSnoozeAuthorized ? [STEP_CONDITION_ACTION_TYPE_SNOOZE] : []),
  ].filter((a) => actions.map((action) => action.type).includes(a) !== true);

  if (
    actions
      .map((action) => action.type)
      .some((t) =>
        [
          STEP_CONDITION_ACTION_TYPE_DISMISS,
          STEP_CONDITION_ACTION_TYPE_SNOOZE,
        ].includes(t)
      )
  ) {
    authorizedActions = authorizedActions.filter(
      (a) =>
        [
          STEP_CONDITION_ACTION_TYPE_SNOOZE,
          STEP_CONDITION_ACTION_TYPE_DISMISS,
        ].includes(a) !== true
    );
  }

  const updateSharedAction = (updatedAction) => {
    updateStep(selectedStep.uid, {
      blocks: selectedStep?.blocks?.map((block) => ({
        ...block,
        actions: block?.actions?.map((_action) =>
          _action.type === updatedAction.type
            ? {..._action, value: updatedAction.value}
            : _action
        ),
      })),
      triggers: selectedStep?.triggers?.map((t) => ({
        ...t,
        actions: t.actions.map((ta) =>
          ta.type === updatedAction.type
            ? {...ta, value: updatedAction.value}
            : ta
        ),
      })),
    });
  };

  return (
    <div className="block-settings dismiss-cross">
      <Section title="Behavior">
        <SectionItem title="Actions" contentClassName="action-picker">
          <div className="actions-wrapper">
            {actions.map((action) => {
              return (
                <ButtonAction
                  key={action.uid}
                  action={action}
                  setAction={(updatedAction) => {
                    if (
                      updatedAction.type === STEP_CONDITION_ACTION_TYPE_SNOOZE
                    ) {
                      updateSharedAction(updatedAction);
                    } else {
                      updateBlock({
                        actions: actions.map((_action) =>
                          _action.uid === action.uid ? updatedAction : _action
                        ),
                      });
                    }
                  }}
                  onDelete={() => {
                    updateBlock({
                      actions: actions.filter(
                        (_action) => _action.uid !== action.uid
                      ),
                    });
                  }}
                  defaultOpen={newlyAddedAction?.uid === action.uid}
                />
              );
            })}
            <DropdownAddAction
              className="add-action-element-button"
              authorizedActions={authorizedActions}
              usedActionTypes={actions.map((action) => action.type)}
              onAddAction={handleAddAction}
              isSurvey={isSurvey}
              position="left top"
              offsetY={-4}
              offsetX={8}
              trigger={
                <ClickableInput
                  className="add-action-element-placeholder-btn"
                  inputProps={{placeholder: 'Add action...'}}
                  leftLabel={
                    <div className="action-icon-wrapper">
                      <i className="isax isax-flash-15" />
                    </div>
                  }
                />
              }
            />
          </div>
        </SectionItem>
      </Section>
      <Divider />
      <Section title="Style">
        <DismissCrossStyle
          style={style}
          updateStyle={updateBlock}
          hideAlign={isBanner}
        />
      </Section>
      <Divider />
    </div>
  );
};

export const DismissCrossStyle = ({style, updateStyle}) => {
  return (
    <>
      <FontSizeItem
        value={style.fontSize}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontSize: value},
          })
        }
      />
      <RadiusItem
        value={style.borderRadius}
        onChange={(value) =>
          updateStyle({
            style: {
              ...style,
              borderRadius: value,
            },
          })
        }
      />
      <ColorItem
        title="Color"
        value={style.fontColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontColor: value},
          })
        }
      />
      <ColorItem
        title="Background"
        value={style.primaryColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, primaryColor: value},
          })
        }
        colorPickerProps={{erasable: true}}
      />
    </>
  );
};

export default DismissCross;
