import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import DefaultLoader from 'components/Loader';
import SelectGroup from 'components/Select';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {errorCodes} from 'helpers/error';
import {any, func, string} from 'prop-types';
import {useRef, useState} from 'react';
import {useQuery} from 'react-query';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {fileService} from 'services';
import {BLOCK_TYPE_MEDIA} from 'services/steps';
import {Swaler} from 'swaler';
import RadioGroup from '../../../../../../../components/RadioGroup';
import './_Styles.scss';
import logoLoom from './imgs/loom.webp';
import logoVimeo from './imgs/vimeo.png';
import logoYoutube from './imgs/youtube.svg';

const mediaOptions = [
  {label: 'Image', value: 'image'},
  {label: 'Video', value: 'video'},
];

const sizingOptions = [
  {label: 'Fill', value: 'fill'},
  {label: 'Fit', value: 'fit'},
];

const logger = new Swaler('MediaLink');

const propTypes = {
  type: string,
  fileUrl: string,
  altText: string,
  sizing: string,
  file: any,
  onChange: func,
};

const defaultProps = {
  type: null,
  fileUrl: null,
  altText: null,
  sizing: null,
  file: null,
  onChange: () => {},
};

const MediaLink = ({type, fileUrl, altText, sizing, file, onChange}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [urlMode, setUrlMode] = useState(
    !!fileUrl && file == null ? true : false
  );

  const refInputFile = useRef();

  const {data: recentFiles} = useQuery({
    queryKey: ['recentFiles', BLOCK_TYPE_MEDIA],
    queryFn: () => fileService.getFiles({type: BLOCK_TYPE_MEDIA}),
    refetchOnWindowFocus: false,
  });

  const handleSelectRecentFile = (file) => {
    onChange({
      value: `${type};${file.publicUrl};${altText};${sizing}`,
      file,
    });
  };

  const handleFileChange = async ({target}) => {
    if (!target.files || target.files.length === 0) {
      return;
    }
    setIsUploading(true);
    const file = await uploadFile(target.files[0]);
    setIsUploading(false);

    if (file != null) {
      onChange({
        value: `${type};${file.publicUrl};${altText};${sizing}`,
        file,
      });
    }
  };

  const handleDeleteFile = async () => {
    onChange({
      value: `${type};;${altText};${sizing}`,
      file: null,
    });
  };

  const uploadFile = async (file) => {
    if (file == null) {
      return;
    }
    try {
      const uploadedFile = await fileService.uploadPublicFile({
        file,
        blockType: BLOCK_TYPE_MEDIA,
      });
      return uploadedFile;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      if (
        [
          errorCodes.FILE_TYPE_NOT_AUTHORIZED,
          errorCodes.FILE_TYPE_NOT_DETECTED,
          'file too large',
        ].includes(code) === false
      ) {
        logger.error('Failed to upload file', code);
      }
      toastDanger([title, message], {actions});
      return null;
    }
  };

  const recentFilesSized = Array(6).fill(null);
  for (let i = 0; i < recentFilesSized?.length; i++) {
    recentFilesSized[i] = recentFiles?.[i];
  }

  return (
    <div className="media-link">
      <SectionItem>
        <RadioGroup
          value={type}
          options={mediaOptions}
          onSelect={(value) =>
            onChange({value: `${value};${fileUrl};${altText};${sizing}`, file})
          }
        />
      </SectionItem>
      {type === 'image' ? (
        <SectionItem>
          <div
            className={classNames('image-input-wrapper', {
              'is-url': urlMode === true,
            })}>
            {urlMode === true ? (
              <div className="url-input-wrapper">
                <div className="url-input-wrapper-header">
                  <span>URL</span>
                  <i
                    className="icon-close"
                    onClick={() => {
                      onChange({
                        value: `${type};;${altText};${sizing}`,
                      });
                      setUrlMode(false);
                    }}
                  />
                </div>
                <InputGroup
                  className="link-input"
                  value={fileUrl}
                  onChange={({target}) =>
                    onChange({
                      value: `${type};${target.value};${altText};${sizing}`,
                      file: null,
                    })
                  }
                  labelTextLeft={<i className="isax isax-link-2" />}
                  placeholder="https://www.yourimage.com"
                />
              </div>
            ) : (
              <>
                <input
                  ref={refInputFile}
                  type="file"
                  style={{display: 'none'}}
                  onChange={handleFileChange}
                />
                {fileUrl && file != null ? (
                  <div className="file-wrapper">
                    <div className="file-preview-name-wrapper">
                      <img src={fileUrl} alt="Media" width={100} height={60} />
                      {file.originalName.length > 10
                        ? file.originalName.slice(0, 10) + '...'
                        : file.originalName}
                    </div>
                    <Button
                      iconRight="icon-trash"
                      className="btn-delete"
                      onClick={handleDeleteFile}
                      danger
                      thin>
                      Delete
                    </Button>
                  </div>
                ) : (
                  <>
                    <div
                      className={classNames('btn-file', {
                        'is-uploading': isUploading === true,
                      })}
                      onClick={() => refInputFile.current.click()}>
                      {isUploading === true ? (
                        <>
                          <DefaultLoader width={14} /> uploading your image...
                        </>
                      ) : (
                        <>
                          <i className="icon-upload-file"></i> Upload
                        </>
                      )}
                    </div>
                    {isUploading !== true && (
                      <>
                        or
                        <div
                          className={classNames('btn-file')}
                          onClick={() => setUrlMode(true)}>
                          <i className="isax isax-link-2"></i> URL
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </SectionItem>
      ) : (
        <div className="section-item media-link-video">
          <InputGroup
            value={fileUrl}
            className="link-input"
            labelTextLeft={<i className="isax isax-link-2" />}
            placeholder="https://loom.com/yourvideo"
            onChange={({target}) =>
              onChange({value: `${type};${target.value};`})
            }
          />
          <div className="players-logos">
            <span className="body-4 n-700">Supported link</span>
            <div className="logos-wrapper">
              <img src={logoYoutube} alt="" />
              <img src={logoLoom} alt="" />
              <img src={logoVimeo} alt="" />
            </div>
          </div>
        </div>
      )}
      {type === 'image' && (
        <>
          <SectionItem title="Sizing">
            <SelectGroup
              isSearchable={false}
              menuPlacement="top"
              className="dismiss-option-select"
              options={sizingOptions}
              value={sizingOptions.find((o) => o.value === sizing)}
              onChange={(option) =>
                onChange({
                  value: `${type};${fileUrl};${altText};${option.value}`,
                  file,
                })
              }
            />
          </SectionItem>
          <Divider />
          <div className="recent-uploads-wrapper">
            <div className="recent-label">Or select from your last uploads</div>
            <div className="recent-uploads-list">
              {recentFilesSized?.map((file) => {
                if (file == null) {
                  return (
                    <div className="recent-upload placeholder">
                      <div className="recent-upload-placeholder"></div>
                    </div>
                  );
                }

                const isSelected = fileUrl === file.publicUrl;

                return (
                  <div
                    className={classNames('recent-upload', {
                      selected: isSelected,
                    })}
                    onClick={() => handleSelectRecentFile(file)}>
                    <img src={file.publicUrl} alt="Media" />
                    <div className="icon-wrapper">
                      {isSelected ? (
                        <i className="icon-checkbox" />
                      ) : (
                        <i className="icon-checkbox-o" />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

MediaLink.propTypes = propTypes;
MediaLink.defaultProps = defaultProps;

export default MediaLink;
