import React from 'react';
import './_Styles.scss';

const Cursor = ({style = {}}) => {
  const {
    backgroundColor = '#FFFFFF',
    borderColor = '#000000',
    ...restStyle
  } = style;

  return (
    <svg
      className="cursor-element"
      width="41"
      height="41"
      viewBox="0 0 41 41"
      xmlns="http://www.w3.org/2000/svg"
      style={restStyle}>
      <path
        d="M14.8699 34.8438L6.66793 6.07133C6.52813 5.5809 7.03749 5.15741 7.50066 5.37898L34.674 18.3786C35.1509 18.6067 35.1151 19.2913 34.6169 19.4678L22.5986 23.7261C22.4646 23.7736 22.3522 23.867 22.2818 23.9895L15.9624 34.9773C15.7004 35.4327 15.0138 35.3489 14.8699 34.8438Z"
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth={2}
      />
    </svg>
  );
};

export default Cursor;
