import {bool, func, number} from 'prop-types';
import React from 'react';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import RadioGroup from '../../../../../../../components/RadioGroup';
import PixelPicker from '../../items/PixelPicker';
import './_Styles.scss';

export const HEIGHT_TYPE_AUTO = 'auto';
export const HEIGHT_TYPE_CUSTOM = 'custom';

const heightOptions = [
  {label: 'Auto', value: HEIGHT_TYPE_AUTO},
  {label: 'Custom', value: HEIGHT_TYPE_CUSTOM},
];

const propTypes = {
  height: number,
  flags: number,
  onChange: func,
  isBanner: bool,
};

const defaultProps = {
  height: null,
  flags: 0,
  onChange: () => {},
  isBanner: false,
};

const Height = ({
  height,
  type,
  onChange,
  onChangeType,
  min = 100,
  max = 900,
}) => {
  return (
    <>
      <SectionItem>
        <RadioGroup
          value={type}
          options={heightOptions}
          onSelect={(value) => {
            onChangeType(value);
          }}
        />
      </SectionItem>
      {type === 'custom' && (
        <SectionItem sectionItemClassName="height-picker">
          <PixelPicker
            value={height}
            min={min}
            max={max}
            onChange={(value) =>
              onChange({
                height: value,
              })
            }
          />
        </SectionItem>
      )}
    </>
  );
};

Height.propTypes = propTypes;
Height.defaultProps = defaultProps;

export default Height;
