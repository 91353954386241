import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import {BuilderContext} from 'contextes/builder';
import React, {useContext, useEffect, useRef} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {BLOCK_TYPE_USER} from 'services/steps';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import PixelPicker from '../../components/items/PixelPicker';
import ProfileLink from '../../components/modals/ProfileLink';
import ColorItem from '../../components/sectionItems/ColorItem';
import FontFamilyItem from '../../components/sectionItems/FontFamilyItem';
import './_Styles.scss';

const Profile = () => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    evolution,
    setEvolution,
  } = useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_USER, updateObj);
  };

  const updateAllBlocks = (updateObj) => {
    setEvolution({
      ...evolution,
      tourSteps: evolution.tourSteps.map((ts) => {
        return {
          ...ts,
          steps: ts.steps.map((s) => {
            return {
              ...s,
              blocks: s.blocks.map((b) => {
                if (b.type === BLOCK_TYPE_USER) {
                  return {
                    ...b,
                    ...updateObj,
                  };
                }
                return b;
              }),
            };
          }),
        };
      }),
      steps: evolution.steps.map((s) => {
        return {
          ...s,
          blocks: s.blocks.map((b) => {
            if (b.type === BLOCK_TYPE_USER) {
              return {
                ...b,
                ...updateObj,
              };
            }
            return b;
          }),
        };
      }),
    });
  };

  const inputRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current?.focus();
    }, 300); // delay focus input of 300ms to match the animation and prevent whole page from scrolling
    return () => clearTimeout(timeout);
  }, []);

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_USER);

  if (block == null) {
    return <></>;
  }

  const {value, file, style} = block;
  const [name, title, fileUrl, altText] = value.split(';');

  return (
    <div className="block-settings profile">
      <Section
        title={
          <>
            <i className="isax isax-main-component5 p-500" />
            Content
          </>
        }
        titleExtra={
          <>
            <div className="body-4 n-700">For every</div>
            <div className="block-group subtitle-4 n-800">
              <div className="icon-wrapper">
                <i className="icon-user-interview" />
              </div>
              Profile
            </div>
          </>
        }>
        <SectionItem title="Name">
          <InputGroup
            className="profile-info-input"
            value={name}
            placeholder="Name"
            onChange={({target}) => {
              updateAllBlocks({
                value: `${target.value};${title};${fileUrl};${altText}`,
              });
            }}
            ref={inputRef}
          />
        </SectionItem>
        <SectionItem title="Title">
          <InputGroup
            className="profile-info-input"
            value={title}
            placeholder="Title"
            onChange={({target}) => {
              updateAllBlocks({
                value: `${name};${target.value};${fileUrl};${altText}`,
              });
            }}
          />
        </SectionItem>
        <SectionItem title="Image">
          <PopupSetting
            trigger={
              <ClickableInput
                key={fileUrl}
                value={file?.originalName}
                leftLabel={
                  fileUrl ? (
                    <div className="preview-profile-media">
                      <img src={fileUrl} alt="Media" />
                    </div>
                  ) : (
                    <span
                      className="placeholder-empty-media"
                      style={{background: '#FFFFFF'}}
                    />
                  )
                }
                erasable
                onErase={() => {
                  updateAllBlocks({
                    value: `${name};${title};;${altText}`,
                    file: null,
                    fileId: null,
                  });
                }}
              />
            }
            title="Media"
            content={
              <ProfileLink
                fileUrl={fileUrl}
                altText={altText}
                file={file}
                onChange={({value, file}) => {
                  updateAllBlocks({
                    value: `${name};${title};${value}`,
                    file,
                    fileId: file?.uid,
                  });
                }}
              />
            }
          />
        </SectionItem>
      </Section>
      <Divider />
      <Section title="Style">
        <FontFamilyItem
          value={style.fontFamily}
          onChange={(value) =>
            updateBlock({
              style: {...style, fontFamily: value},
            })
          }
        />
        <SectionItem title="Font size">
          <PixelPicker
            small
            value={style.fontSize ?? 14}
            min={8}
            max={42}
            onChange={(value) => {
              updateBlock({
                style: {...style, fontSize: value},
              });
            }}
          />
        </SectionItem>
        <ColorItem
          title="Color"
          value={style.fontColor ?? null}
          onChange={(value) =>
            updateBlock({
              style: {...style, fontColor: value},
            })
          }
          colorPickerProps={{erasable: true}}
        />
      </Section>
    </div>
  );
};

export default Profile;
