import React, {useContext} from 'react';
import {
  BLOCK_PRIMARY_CTA,
  BLOCK_SECONDARY_CTA,
  BLOCK_STEPPER,
} from '../../constants/blocks';
import {PokeStateContext} from '../../context';
import {BlockCta} from '../BlockCta';
import {BlockStepper} from '../BlockStepper';
import './_styles.scss';

const LayoutBottom = ({JimoLabel = null}) => {
  const {currentStep} = useContext(PokeStateContext);

  const shouldShowBlockCta = currentStep.blocks.find(
    (b) =>
      [BLOCK_PRIMARY_CTA, BLOCK_SECONDARY_CTA].includes(b.type) &&
      b.removed !== true
  );
  const blockStepper = currentStep.blocks.find((b) => b.type === BLOCK_STEPPER);

  if (shouldShowBlockCta == null && blockStepper == null && JimoLabel == null) {
    return <></>;
  }

  return (
    <div
      className="poke-layout-bottom"
      style={{
        gap: `${currentStep?.style?.gap ?? 16}px`,
      }}>
      {shouldShowBlockCta != null && (
        <BlockCta
          isBottom={
            !(
              blockStepper != null && blockStepper.style.position === 'bottom'
            ) && JimoLabel == null
          }
        />
      )}
      {blockStepper != null && blockStepper.style.position === 'bottom' && (
        <BlockStepper />
      )}
    </div>
  );
};

export default LayoutBottom;
