import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import React, {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {F_OPTION_HOTSPOT_PERSISTENT} from 'services/evolution';
import RadioGroup from '../../../../../../components/RadioGroup';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import PixelPicker from '../../components/items/PixelPicker';
import Position from '../../components/modals/Position';
import ColorItem from '../../components/sectionItems/ColorItem';
import './_Styles.scss';

export const HOTSPOT_SHAPE_DEFAULT = 'default';
export const HOTSPOT_SHAPE_QUESTION_MARK = 'question-mark';
export const HOTSPOT_SHAPE_EXCLAMATION_MARK = 'exclamation-mark';
export const HOTSPOT_ANIMATION_PULSE = 'pulse';
export const HOTSPOT_ANIMATION_NONE = 'none';

const persistOptions = [
  {label: 'Hide', value: 'hide'},
  {label: 'Persist', value: 'persist'},
];

const shapeOptions = [
  {label: <i className="icon-slot-dot" />, value: HOTSPOT_SHAPE_DEFAULT},
  {
    label: <i className="icon-hotspot-shape-1" />,
    value: HOTSPOT_SHAPE_QUESTION_MARK,
  },
  {
    label: <i className="icon-hotspot-shape-2" />,
    value: HOTSPOT_SHAPE_EXCLAMATION_MARK,
  },
];

const animationOptions = [
  {label: 'Pulse', value: HOTSPOT_ANIMATION_PULSE},
  {label: 'No', value: HOTSPOT_ANIMATION_NONE},
];

const Hotspot = () => {
  const {
    controlledEvolution: evolution,
    setControlledEvolution: setEvolution,
    isInApp,
    setIsEditingTargetElement,
  } = useContext(BuilderContext);

  const {boostedDotStyle, boostedQueryselector} = evolution;
  const [dotColor, dotSize, dotShape, dotAnimation] =
    boostedDotStyle.split(';');
  const isPersist = hasFlag(
    F_OPTION_HOTSPOT_PERSISTENT,
    evolution.optionsFlags
  );

  return (
    <div className="block-settings hotspot">
      <Section title="Behavior">
        <SectionItem title="On click">
          <RadioGroup
            value={isPersist ? 'persist' : 'hide'}
            options={persistOptions}
            onSelect={(value) => {
              setEvolution({
                ...evolution,
                optionsFlags:
                  value === 'persist'
                    ? addFlag(
                        F_OPTION_HOTSPOT_PERSISTENT,
                        evolution.optionsFlags
                      )
                    : removeFlag(
                        F_OPTION_HOTSPOT_PERSISTENT,
                        evolution.optionsFlags
                      ),
              });
            }}
          />
        </SectionItem>
      </Section>
      <Divider />
      <Section title="Style">
        <SectionItem title="Shape">
          <RadioGroup
            value={dotShape || 'default'}
            options={shapeOptions}
            onSelect={(value) => {
              setEvolution({
                ...evolution,
                boostedDotStyle: [dotColor, dotSize, value, dotAnimation].join(
                  ';'
                ),
              });
            }}
          />
        </SectionItem>
        {(dotShape === 'default' || !dotShape) && (
          <SectionItem title="Animation">
            <RadioGroup
              value={dotAnimation || 'none'}
              options={animationOptions}
              onSelect={(value) => {
                setEvolution({
                  ...evolution,
                  boostedDotStyle: [dotColor, dotSize, dotShape, value].join(
                    ';'
                  ),
                });
              }}
            />
          </SectionItem>
        )}
        <SectionItem title="Position" contentClassName="position-picker">
          <>
            {isInApp === true ? (
              <ClickableInput
                value={
                  evolution.querySelectorManual?.elementText != null ||
                  evolution.querySelectorManual?.cssSelector != null
                    ? 'Manual selector'
                    : evolution.boostedQueryselector || 'Select an element'
                }
                leftLabel={
                  <div className="position-icon-wrapper">
                    <i className="icon-target" />
                  </div>
                }
                onClick={() => {
                  setIsEditingTargetElement(true);
                }}
              />
            ) : (
              <PopupSetting
                large
                trigger={
                  <ClickableInput
                    value={
                      evolution.querySelectorManual?.elementText != null ||
                      evolution.querySelectorManual?.cssSelector != null
                        ? 'Manual selector'
                        : boostedQueryselector || 'Select an element'
                    }
                    leftLabel={
                      <div className="position-icon-wrapper">
                        <i className="icon-target" />
                      </div>
                    }
                  />
                }
                title="Position"
                content={
                  <Position evolution={evolution} setEvolution={setEvolution} />
                }
                invalid={
                  evolution.querySelectorManual == null &&
                  !evolution.boostedQueryselector
                }
              />
            )}
          </>
        </SectionItem>
        <HotspotStyleSection
          style={boostedDotStyle}
          updateStyle={(style) => {
            setEvolution({...evolution, boostedDotStyle: style});
          }}
        />
      </Section>
    </div>
  );
};

export const HotspotStyleSection = ({style, updateStyle}) => {
  const [dotColor, dotSize, dotShape, dotAnimation] = style.split(';');

  return (
    <>
      <SectionItem title="Hotspot size">
        <PixelPicker
          small
          value={dotSize}
          min={8}
          max={42}
          onChange={(value) =>
            updateStyle([dotColor, value, dotShape, dotAnimation].join(';'))
          }
        />
      </SectionItem>
      <ColorItem
        title="Color"
        value={dotColor}
        onChange={(value) =>
          updateStyle([value, dotSize, dotShape, dotAnimation].join(';'))
        }
      />
    </>
  );
};

export default Hotspot;
