import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import {hasFlag} from 'helpers/bitwise';
import {useContext, useEffect, useState} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {F_BOOST_SLOT_TOP_BAR} from 'services/evolution';
import {BLOCK_TYPE_CHECKLIST_HEADER, STEP_TYPE_CHECKLIST} from 'services/steps';
import BasicEditor from '../../../../../../components/MarkdownEditor/BasicEditor';
import RadioGroup from '../../../../../../components/RadioGroup';
import ColorItem from '../../components/sectionItems/ColorItem';
import FontFamilyItem from '../../components/sectionItems/FontFamilyItem';
import FontSizeItem from '../../components/sectionItems/FontSizeItem';
import FontWeightItem from '../../components/sectionItems/FontWeightItem';
import './_Styles.scss';

const alignOptions = [
  {label: <i className="icon-text-align-l" />, value: 'left'},
  {label: <i className="icon-text-align-c" />, value: 'center'},
  {label: <i className="icon-text-align-r" />, value: 'right'},
];

const Paragraph = () => {
  const {
    controlledEvolution: evolution,
    selectedStep: step,
    selectedBlockType,
    updateBlock: uptBlock,
    selectedChecklistItemId,
  } = useContext(BuilderContext);

  const [isEditing, setIsEditing] = useState(false);
  const [markdownEditorKey, setMarkdownEditorKey] = useState(null);

  const isBannerStep = hasFlag(F_BOOST_SLOT_TOP_BAR, evolution.boostFlags);
  const isChecklistStep = step?.type === STEP_TYPE_CHECKLIST;

  const checklistHeaderBlock = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_HEADER
  );

  const block = step?.blocks.find(
    (b) =>
      b.type === selectedBlockType &&
      ((isChecklistStep !== true && b.parentBlockId == null) ||
        (selectedChecklistItemId == null &&
          [null, checklistHeaderBlock?.uid].includes(b.parentBlockId)) ||
        selectedChecklistItemId === b.parentBlockId)
  );

  const {value, rawValue, style} = block || {};

  const updateBlock = (updateObj, all = false) => {
    if (all !== true && block?.parentBlockId != null) {
      uptBlock(selectedBlockType, updateObj, {
        parentBlockId: block.parentBlockId,
      });
    } else {
      uptBlock(selectedBlockType, updateObj);
    }
  };

  useEffect(() => {
    if (isEditing === false) {
      setMarkdownEditorKey(JSON.stringify(rawValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawValue]);

  if (step == null || block == null) {
    return <></>;
  }

  return (
    <div className="block-settings paragraph">
      <Section title="Content">
        <BasicEditor
          key={markdownEditorKey}
          value={value}
          rawValue={rawValue}
          onChange={({value, rawValue}) => updateBlock({value, rawValue})}
          onFocus={() => setIsEditing(true)}
          onBlur={() => setIsEditing(false)}
          autoFocus
        />
      </Section>
      <Divider />
      <Section
        title={
          isChecklistStep && selectedChecklistItemId != null ? (
            <>
              <i className="isax isax-main-component5 p-500" />
              Style
            </>
          ) : (
            'Style'
          )
        }
        titleExtra={
          isChecklistStep && selectedChecklistItemId != null ? (
            <>
              <div className="body-4 n-700">For every</div>
              <div className="block-group subtitle-4 n-800">
                <div className="icon-wrapper">
                  <i className="icon-text" />
                </div>
                Description
              </div>
            </>
          ) : null
        }>
        <ParagraphStyle
          style={style}
          updateStyle={(value) =>
            updateBlock(
              value,
              isChecklistStep === true && selectedChecklistItemId != null
            )
          }
          hideAlign={isBannerStep || isChecklistStep}
        />
      </Section>
    </div>
  );
};

export const ParagraphStyle = ({style, updateStyle, hideAlign = false}) => {
  return (
    <>
      <ColorItem
        title="Color"
        value={style.fontColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontColor: value},
          })
        }
      />
      <FontFamilyItem
        value={style.fontFamily}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontFamily: value},
          })
        }
      />
      <FontSizeItem
        value={style.fontSize}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontSize: value},
          })
        }
      />
      <FontWeightItem
        value={style.fontWeight}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontWeight: value},
          })
        }
      />
      {hideAlign !== true && (
        <SectionItem title="Align">
          <RadioGroup
            value={style.align}
            options={alignOptions}
            onSelect={(value) =>
              updateStyle({
                style: {
                  ...style,
                  align: value,
                },
              })
            }
          />
        </SectionItem>
      )}
    </>
  );
};

export default Paragraph;
