import InputGroup from 'components/Input';
import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import Slider from 'rc-slider';
import {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {BLOCK_TYPE_OPINION} from 'services/steps';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import FontFamilyItem from '../../components/sectionItems/FontFamilyItem';
import './_Styles.scss';

const typeOptions = [
  {label: 'Smiley', value: 'smiley'},
  {label: 'Stars', value: 'star'},
];

const OpinionScale = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_OPINION, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_OPINION);

  if (block == null) {
    return <></>;
  }

  const {value, style, flags} = block;
  const [type, number, labelLeft, labelRight] = value.split(';');

  return (
    <div className="block-settings opinion-scale">
      <Section title="Style">
        <SectionItem title="Steps">
          <StepsPicker
            value={number}
            onChange={(value) =>
              updateBlock({
                value: `${type};${value};${labelLeft};${labelRight}`,
              })
            }
          />
        </SectionItem>
        <SectionItem title="Type">
          <SelectGroup
            className="font-option-select"
            options={typeOptions}
            value={typeOptions.find((o) => o.value === type)}
            onChange={(option) =>
              updateBlock({
                value: `${option.value};${number};${labelLeft};${labelRight}`,
              })
            }
          />
        </SectionItem>
        <SectionItem title="Left label">
          <InputGroup
            className="opinion-scale-left-label-input"
            value={labelLeft}
            onChange={({target}) =>
              updateBlock({
                value: `${type};${number};${target.value};${labelRight}`,
              })
            }
          />
        </SectionItem>
        <SectionItem title="Right label">
          <InputGroup
            className="opinion-scale-right-label-input"
            value={labelRight}
            onChange={({target}) =>
              updateBlock({
                value: `${type};${number};${labelLeft};${target.value}`,
              })
            }
          />
        </SectionItem>
        <OpinionScaleStyle style={style} updateStyle={updateBlock} />
      </Section>
    </div>
  );
};

const StepsPicker = ({value, onChange}) => {
  return (
    <div className="steps-picker">
      <div className="steps-picker-input">
        <InputGroup
          value={value}
          type="number"
          min={3}
          max={7}
          onChange={(e) => {
            const correctedValue = e.target.value || 3;
            onChange(correctedValue > 7 ? 7 : Math.max(3, correctedValue));
          }}
        />
      </div>
      <div className="steps-picker-slider">
        <Slider min={3} max={7} onChange={onChange} value={value || 5} />
      </div>
    </div>
  );
};

export const OpinionScaleStyle = ({style, updateStyle}) => {
  return (
    <>
      <SectionItem title="Size">
        <PixelPicker
          small
          value={style?.fontSize}
          min={8}
          max={42}
          onChange={(value) =>
            updateStyle({
              style: {...style, fontSize: value},
            })
          }
        />
      </SectionItem>
      <SectionItem title="Padding">
        <PixelPicker
          small
          value={style?.padding}
          min={0}
          max={100}
          onChange={(value) =>
            updateStyle({
              style: {...style, padding: value},
            })
          }
        />
      </SectionItem>
      <FontFamilyItem
        value={style.fontFamily}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontFamily: value},
          })
        }
      />
      <SectionItem title="Labels color">
        <ColorPickerInput
          inputProps={{small: true}}
          title="Color"
          value={style.labelsColor ?? null}
          onChange={(value) =>
            updateStyle({
              style: {...style, labelsColor: value},
            })
          }
          erasable
        />
      </SectionItem>
    </>
  );
};

export default OpinionScale;
