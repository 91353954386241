import classnames from 'classnames';
import React, {useContext} from 'react';
import isURL from 'validator/lib/isURL';
import {hasFlag} from '../../../../helpers/bitwise';
import {BLOCK_MEDIA} from '../../constants/blocks';
import {PokeStateContext} from '../../context';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';

export const F_BLOCK_ZOOM_ON_MEDIA_CLICK = 16;
export const MEDIA_TYPE_IMAGE = 'image';
export const MEDIA_TYPE_VIDEO = 'video';
export const MEDIA_SCALE_TYPE_FIT = 'fit';
export const MEDIA_SCALE_TYPE_FILL = 'fill';
export const MEDIA_POSITION_TOP = 'top';
export const MEDIA_POSITION_BOTTOM = 'bottom';
export const MEDIA_POSITION_LEFT = 'left';
export const MEDIA_POSITION_RIGHT = 'right';

export const parseMediaValue = (value) => {
  const [type, url, altText, scaleType] = value.split(';');

  return {
    type,
    url,
    altText,
    scaleType,
  };
};

const getYoutubeId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};
const getYoutubeEmbedUrl = (url) => {
  const videoId = getYoutubeId(url);

  url = `https://www.youtube.com/embed/${videoId}`;
  return url;
};
export const getEmbedUrl = (url) => {
  const vimeoPattern = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;

  // Loom
  if (url.startsWith('https://www.loom.com/share')) {
    return {
      player: 'loom',
      url: url.replace('share', 'embed'),
    };
  }
  // Youtube
  if (getYoutubeId(url)) {
    return {
      player: 'youtube',
      url: getYoutubeEmbedUrl(url),
    };
  }
  // Vimeo
  if (vimeoPattern.test(url) && !url.includes('player.vimeo.com')) {
    return {
      player: 'vimeo',
      url: url.replace(vimeoPattern, 'https://player.vimeo.com/video/$1'),
    };
  }
  return {
    player: null,
    url,
  };
};

export const BlockMedia = ({isCursor = false}) => {
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    onImageClick = () => {},
    language,
  } = useContext(PokeStateContext);
  const block = currentStep.blocks.find((b) => b.type === BLOCK_MEDIA);

  return (
    <Media
      block={block}
      onBlockSelected={onBlockSelected}
      selectedBlock={selectedBlock}
      inBuilder={inBuilder}
      onImageClick={onImageClick}
      language={language}
      currentStep={currentStep}
      isCursor={isCursor}
    />
  );
};

export const Media = ({
  block,
  onBlockSelected = () => {},
  selectedBlock,
  inBuilder,
  onImageClick,
  extraStyle = {}, // for styles outside of the image block context (i.e post radius)
  isDisableAnimation = false,
  language,
  currentStep = null,
  isCursor = false,
}) => {
  const playAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_MEDIA,
  });

  const value = parseMediaValue(block.value);
  const {
    position,
    padding,
    height = 200,
    width,
    layoutType,
    borderRadius,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    ...restStyle
  } = block.style ?? {};

  const stepPaddingVertical = currentStep?.style?.paddingTop ?? 24;
  const stepPaddingHorizontal = currentStep?.style?.paddingLeft ?? 24;

  const marginTop =
    position === MEDIA_POSITION_BOTTOM || isCursor
      ? 0
      : -stepPaddingVertical + paddingTop;
  const marginBottom = isCursor ? 0 : paddingBottom;

  const isVertical = layoutType === 'vertical';

  const margin = `
  ${marginTop}px 
  ${paddingRight - stepPaddingHorizontal}px
  ${marginBottom}px
  ${paddingLeft - stepPaddingHorizontal}px
  `;

  const hasZoomOnMediaClick = hasFlag(F_BLOCK_ZOOM_ON_MEDIA_CLICK, block.flags);

  const defaultImageUrl =
    'https://assets.usejimo.com/builder-empty-state/empty_state_media.svg';

  const translation = block.translations?.find((t) => t.language === language);

  const valueUrl = translation?.value || value.url;

  const renderImage = () => {
    return (
      <>
        {(value.scaleType === MEDIA_SCALE_TYPE_FILL || !valueUrl) && (
          <div
            className="media-file"
            style={{
              ...restStyle,
              backgroundImage: `url(${valueUrl || defaultImageUrl})`,
              backgroundSize: 'cover',
              borderRadius: borderRadius,
              ...extraStyle,
            }}
          />
        )}
        {value.scaleType === MEDIA_SCALE_TYPE_FIT && valueUrl && (
          <img
            src={valueUrl || defaultImageUrl}
            alt={value.altText}
            style={{
              ...restStyle,
              borderRadius: borderRadius,
            }}
          />
        )}
        {hasZoomOnMediaClick === true && <i className="icon-search" />}
      </>
    );
  };
  const renderVideo = () => {
    const {player, url} = getEmbedUrl(valueUrl);

    if (player == null) {
      if (isURL(url || '') === true) {
        return (
          <iframe
            src={url}
            title="embedded video"
            allow="fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              ...restStyle,
              borderRadius: borderRadius,
            }}
          />
        );
      }
      return (
        <>
          <div
            className="media-file"
            style={{
              ...restStyle,
              backgroundImage: `url(${defaultImageUrl})`,
              backgroundSize: 'cover',
              borderRadius: borderRadius,
              ...extraStyle,
            }}
          />
        </>
      );
    }
    return player === 'vimeo' ? (
      <div
        style={{
          position: 'relative',
          height: '100%',
        }}>
        <iframe
          src={url}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: borderRadius,
            ...restStyle,
          }}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="embedded video"
        />
      </div>
    ) : (
      <iframe
        src={url}
        title="embedded video"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        style={{
          ...restStyle,
          borderRadius: borderRadius,
        }}
      />
    );
  };

  return (
    <div
      className={classnames('poke-block-media', {
        'poke-block-clickable': inBuilder === true,
        'is-animating-out': playAnimationOut === true,
        'is-image': value.type === MEDIA_TYPE_IMAGE,
        'is-video': value.type === MEDIA_TYPE_VIDEO,
        'is-empty': !valueUrl,
        'is-fit': value.scaleType === MEDIA_SCALE_TYPE_FIT,
        selected: selectedBlock === BLOCK_MEDIA,
        'is-clickable':
          value.type === MEDIA_TYPE_IMAGE && hasZoomOnMediaClick === true,
        'is-disable-animation': isDisableAnimation,
        'is-sided': isVertical,
      })}
      style={{
        ...(isVertical
          ? {
              height: '100%',
              width,
            }
          : {
              height,
              margin,
            }),
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (value.type === MEDIA_TYPE_IMAGE && hasZoomOnMediaClick === true) {
          onImageClick(valueUrl);
        }
        onBlockSelected(BLOCK_MEDIA);
      }}>
      {value.type === MEDIA_TYPE_IMAGE && renderImage()}
      {value.type === MEDIA_TYPE_VIDEO && renderVideo()}
      <ClickableBlockOverlay />
    </div>
  );
};
