import React from 'react';
import './_Styles.scss';

const Effects = ({style}) => {
  const {effectType, effectColor = '#1260eb', effectSpeed = 100} = style;

  if (effectType === 'moving-border' && effectColor != null) {
    const animationColor = effectColor;
    const animationSpeed = (1 * 100) / effectSpeed || 100;

    return (
      <div
        className="effect effect-moving-border"
        style={{
          background: `linear-gradient(
            90deg,
            ${animationColor} 0%,
            ${animationColor} 50%,
            rgba(255, 255, 255, 1) 100%
          )`,
          animation: `moving-border-rotate ${animationSpeed}s linear infinite`,
        }}
      />
    );
  }

  if (effectType === 'shimmer') {
    const adjustedSpeed = (1 * 100) / effectSpeed || 100;

    return (
      <div
        className="effect effect-shimmer"
        style={{
          backgroundImage: `
          linear-gradient(
            to right,
            rgba(${parseInt(effectColor.slice(1, 3), 16)}, ${parseInt(
            effectColor.slice(3, 5),
            16
          )}, ${parseInt(effectColor.slice(5, 7), 16)}, 0) 0%,
            rgba(${parseInt(effectColor.slice(1, 3), 16)}, ${parseInt(
            effectColor.slice(3, 5),
            16
          )}, ${parseInt(effectColor.slice(5, 7), 16)}, 0.5) 50%,
            rgba(${parseInt(effectColor.slice(1, 3), 16)}, ${parseInt(
            effectColor.slice(3, 5),
            16
          )}, ${parseInt(effectColor.slice(5, 7), 16)}, 0) 100%
          )
        `,
          animation: `shimmer ${adjustedSpeed}s infinite`,
        }}
      />
    );
  }

  if (effectType === 'glow') {
    const adjustedSpeed = (1 * 100) / effectSpeed || 100;
    const colorAdjusted =
      effectColor.length > 7 ? effectColor.slice(0, -2) : effectColor;

    return (
      <div
        className="effect effect-glow"
        style={{
          '--glow-color': colorAdjusted,
          '--glow-color-80': `${colorAdjusted}80`,
          animation: `glow ${adjustedSpeed}s infinite`,
        }}
      />
    );
  }
};

export default Effects;
