import SelectGroup from 'components/Select';
import React from 'react';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {fontFamiliesOptions} from '../../../blocks/Title/utils';
import './_Styles.scss';

const FontFamilyItem = ({value, onChange}) => {
  return (
    <SectionItem title="Font family">
      <SelectGroup
        small
        options={fontFamiliesOptions}
        value={fontFamiliesOptions.find((o) => o.value === value)}
        onChange={(option) => onChange(option.value)}
      />
    </SectionItem>
  );
};

export default FontFamilyItem;
