import InputGroup from 'components/Input';
import {func, string} from 'prop-types';
import React from 'react';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import ColorPickerInput from '../../items/ColorPickerInput';

const propTypes = {
  value: string,
  onChange: func,
};

const defaultProps = {
  value: {},
  onChange: () => {},
};

const Shadow = ({value, onChange, setCloseOnDocumentClick}) => {
  const {color, x, y, blur} = value ?? {};

  return (
    <>
      <SectionItem title="Shadow">
        <ColorPickerInput
          title="Shadow color"
          value={color}
          onChange={(v) => onChange({...value, color: v})}
          setParentCloseOnDocumentClick={setCloseOnDocumentClick}
        />
      </SectionItem>
      <SectionItem title="X">
        <InputGroup
          value={x}
          type="number"
          min={-100}
          max={100}
          onChange={(e) => onChange({...value, x: e.target.value})}
        />
      </SectionItem>
      <SectionItem title="Y">
        <InputGroup
          value={y}
          type="number"
          min={-100}
          max={100}
          onChange={(e) => onChange({...value, y: e.target.value})}
        />
      </SectionItem>
      <SectionItem title="Blur">
        <InputGroup
          value={blur}
          type="number"
          min={0}
          max={100}
          onChange={(e) => onChange({...value, blur: e.target.value})}
        />
      </SectionItem>
    </>
  );
};

Shadow.propTypes = propTypes;
Shadow.defaultProps = defaultProps;

export default Shadow;
