import classNames from 'classnames';
import Divider from 'components/Divider';
import RadioGroup from 'components/RadioGroup';
import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {useContext} from 'react';
import {getDefaultBlockFromType} from 'scenes/PokeBuilder/components/BlockManager/utils';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {F_OPTION_CHECKLIST_EXPAND_ON_TASK_COMPLETION_DISABLED} from 'services/evolution';
import {
  BLOCK_TYPE_CHECKLIST_DISMISS,
  F_STEP_CHECKLIST_STEP_COMPLETION_ORDERED,
  getDefaultStepStyle,
} from 'services/steps';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import {positionsArr} from '../../components/modals/Position';
import Shadow from '../../components/modals/Shadow';
import Width from '../../components/modals/Width';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import ChecklistConditionsWrapper from '../ChecklistListItem/ChecklistConditionsWrapper';
import './_Styles.scss';

const dismissOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

const completionOrderOptions = [
  {label: 'In order', value: true},
  {label: 'In any order', value: false},
];

const ChecklistSettings = () => {
  const {
    controlledEvolution: evolution,
    setControlledEvolution: setEvolution,
    selectedStep,
    updateStep,
  } = useContext(BuilderContext);

  const handleThemeStyleChange = (changes) => {
    updateStep(selectedStep?.uid, {
      style: {...selectedStep.style, ...changes},
    });
  };

  const stepStyle = selectedStep?.style || getDefaultStepStyle();

  const selectedAlignment = positionsArr.find(
    (o) => o.value === evolution?.boostedPositionFlags
  );

  const {triggers = [], blocks = []} = selectedStep;
  const autoExpandTrigger = triggers[0];

  const hasDismiss =
    blocks.find((b) => b.type === BLOCK_TYPE_CHECKLIST_DISMISS) != null;

  const hasTaskCompletionAutoExpandDisabled = hasFlag(
    F_OPTION_CHECKLIST_EXPAND_ON_TASK_COMPLETION_DISABLED,
    evolution.optionsFlags
  );

  return (
    <div className="block-settings checklist-block">
      <Section title="Behavior">
        <SectionItem
          title="Auto expand"
          sectionItemClassName="triggers"
          contentClassName="auto-expand">
          <div className="items-wrapper">
            <ClickableInput
              className={classNames('task-completion-auto-expand-button', {
                checked: hasTaskCompletionAutoExpandDisabled !== true,
              })}
              value="Task completion"
              leftLabel={
                <div className="checkbox-wrapper">
                  {hasTaskCompletionAutoExpandDisabled !== true ? (
                    <i className="isax isax-tick-square" />
                  ) : (
                    <i className="isax isax-refresh-square-2" />
                  )}
                </div>
              }
              onClick={() => {
                setEvolution({
                  ...evolution,
                  optionsFlags: hasTaskCompletionAutoExpandDisabled
                    ? removeFlag(
                        F_OPTION_CHECKLIST_EXPAND_ON_TASK_COMPLETION_DISABLED,
                        evolution.optionsFlags
                      )
                    : addFlag(
                        F_OPTION_CHECKLIST_EXPAND_ON_TASK_COMPLETION_DISABLED,
                        evolution.optionsFlags
                      ),
                });
              }}
            />
            <ChecklistConditionsWrapper
              trigger={autoExpandTrigger}
              setTrigger={(trigger) => {
                updateStep(selectedStep?.uid, {
                  triggers: [trigger],
                });
              }}
              title="Auto expand checklist"
            />
          </div>
        </SectionItem>
        <SectionItem title="Dismissible">
          <RadioGroup
            options={dismissOptions}
            value={hasDismiss}
            onSelect={(value) => {
              if (value) {
                updateStep(selectedStep?.uid, {
                  blocks: [
                    ...blocks,
                    getDefaultBlockFromType(
                      BLOCK_TYPE_CHECKLIST_DISMISS,
                      evolution.theme
                    ),
                  ],
                });
              } else {
                updateStep(selectedStep?.uid, {
                  blocks: selectedStep.blocks.filter(
                    (b) => b.type !== BLOCK_TYPE_CHECKLIST_DISMISS
                  ),
                });
              }
            }}
          />
        </SectionItem>

        <SectionItem title="Completion">
          <SelectGroup
            isSearchable={false}
            className="progress-option-select"
            options={completionOrderOptions}
            value={completionOrderOptions.find(
              (o) =>
                o.value ===
                hasFlag(
                  F_STEP_CHECKLIST_STEP_COMPLETION_ORDERED,
                  selectedStep.stepFlags
                )
            )}
            onChange={(option) => {
              if (option.value === true) {
                updateStep(selectedStep?.uid, {
                  stepFlags: addFlag(
                    F_STEP_CHECKLIST_STEP_COMPLETION_ORDERED,
                    selectedStep.stepFlags
                  ),
                });
              } else {
                updateStep(selectedStep?.uid, {
                  stepFlags: removeFlag(
                    F_STEP_CHECKLIST_STEP_COMPLETION_ORDERED,
                    selectedStep.stepFlags
                  ),
                });
              }
            }}
          />
        </SectionItem>
      </Section>
      <Divider />

      <Section title="General">
        <SectionItem
          title="Position"
          contentClassName="snippet-position-picker">
          <div className="alignment-figure-wrapper">
            <div className="alignment-figure">
              <div className="alignment-label">
                {selectedAlignment != null ? (
                  <>Always show from {selectedAlignment.position}</>
                ) : (
                  <>Select a position</>
                )}
              </div>
              {positionsArr.map((o) => {
                if (o.value >= 128 || o.value === 8) {
                  return <></>;
                }
                return (
                  <div
                    className={classNames('position-dot', o.position, {
                      selected: evolution?.boostedPositionFlags === o.value,
                    })}
                    onClick={() =>
                      setEvolution({
                        ...evolution,
                        boostedPositionFlags: o.value,
                      })
                    }
                  />
                );
              })}
            </div>
          </div>
        </SectionItem>
        <SectionItem title="Width">
          <PopupSetting
            trigger={
              <ClickableInput
                value={
                  stepStyle.width != null ? `${stepStyle.width}px` : 'Default'
                }
              />
            }
            title="Width"
            content={
              <Width
                value={stepStyle.width}
                onChange={(value) =>
                  updateStep(selectedStep?.uid, {
                    style: {...stepStyle, width: value},
                  })
                }
              />
            }
          />
        </SectionItem>
      </Section>
      <Divider />
      <Section title="Style">
        <SectionItem title="Background" contentClassName="background-picker">
          <ColorPickerInput
            title="Background color"
            value={stepStyle.backgroundColor || null}
            onChange={(value) =>
              handleThemeStyleChange({backgroundColor: value})
            }
          />
        </SectionItem>
        <SectionItem title="Border">
          <ColorPickerInput
            title="Border color"
            value={stepStyle.borderColor || null}
            onChange={(value) => handleThemeStyleChange({borderColor: value})}
            erasable
          />
        </SectionItem>
        <SectionItem title="Shadow" contentClassName="shadow-picker">
          <PopupSetting
            trigger={
              <ClickableInput
                value={stepStyle.shadow?.color}
                leftLabel={
                  <span
                    className="preview-shadow-color"
                    style={{
                      background: stepStyle.shadow?.color || '#FFFFFF',
                    }}
                  />
                }
                erasable={true}
                onErase={() =>
                  handleThemeStyleChange({
                    shadow: {color: null, opacity: 0, x: 0, y: 0, blur: 0},
                  })
                }
              />
            }
            title="Shadow"
            content={
              <Shadow
                value={stepStyle.shadow}
                onChange={(value) => handleThemeStyleChange({shadow: value})}
              />
            }
          />
        </SectionItem>
        <RadiusItem
          value={stepStyle.borderRadius}
          onChange={(value) => handleThemeStyleChange({borderRadius: value})}
          max={50}
        />
      </Section>
      <Divider />
    </div>
  );
};

export default ChecklistSettings;
