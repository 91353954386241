import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {BLOCK_TYPE_CONCEPT} from 'services/steps';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import ConceptThumbnailLink from '../../components/modals/ConceptThumbnailLink';
import RadiusItem from '../../components/sectionItems/RadiusItem';
import './_Styles.scss';
import iconFigma from './icons/figma.png';
import iconMaze from './icons/maze.png';

const Concept = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_CONCEPT, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_CONCEPT);

  if (block == null) {
    return <></>;
  }

  const {value, style, file} = block;
  const [type, conceptTestUrl, fileUrl, altText] = value.split(';');

  return (
    <div className="block-settings concept">
      <Section title="Prototype">
        <SectionItem>
          <InputGroup
            className="prototype-info-input"
            value={conceptTestUrl}
            placeholder="Prototype url (figma, invision, etc..)"
            onChange={({target}) => {
              updateBlock({
                value: `${type};${target.value};${fileUrl};${altText}`,
              });
            }}
          />
        </SectionItem>
        <div className="icons-brand-wrapper fade-in">
          <a
            href="https://help.usejimo.com/help-center/v/using-jimo/integrations/figma"
            target="_blank"
            rel="noreferrer">
            <img src={iconFigma} alt="Figma" />
          </a>
          <a
            href="https://help.usejimo.com/help-center/v/using-jimo/integrations/maze"
            target="_blank"
            rel="noreferrer">
            <img src={iconMaze} alt="Maze" />
          </a>
        </div>
      </Section>
      <Divider />
      <Section title="Layout">
        <SectionItem title="Image">
          <PopupSetting
            trigger={
              <ClickableInput
                key={fileUrl}
                value={file?.originalName}
                leftLabel={
                  fileUrl ? (
                    <div className="preview-prototype-media">
                      <img src={fileUrl} alt="Media" />
                    </div>
                  ) : (
                    <span
                      className="placeholder-empty-media"
                      style={{background: '#FFFFFF'}}
                    />
                  )
                }
                erasable
                onErase={() => {
                  updateBlock({
                    value: `${type};${conceptTestUrl};${fileUrl};${altText}`,
                    file: null,
                    fileId: null,
                  });
                }}
              />
            }
            title="Image"
            content={
              <ConceptThumbnailLink
                fileUrl={fileUrl}
                altText={altText}
                file={file}
                onChange={({value, file}) => {
                  updateBlock({
                    value: `${type};${conceptTestUrl};${value};${altText}`,
                    file,
                    fileId: file?.uid,
                  });
                }}
              />
            }
          />
        </SectionItem>
        <ConceptStyle style={style} updateStyle={updateBlock} />
      </Section>
    </div>
  );
};

export const ConceptStyle = ({style, updateStyle}) => {
  return (
    <RadiusItem
      value={style.borderRadius}
      onChange={(value) =>
        updateStyle({
          style: {
            ...style,
            borderRadius: value,
          },
        })
      }
    />
  );
};

export default Concept;
