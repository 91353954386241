import classnames from 'classnames';
import fontColorContrast from 'font-color-contrast';
import React, {useContext, useEffect} from 'react';
import {hasFlag} from '../../../../helpers/bitwise';
import {BLOCK_USER} from '../../constants/blocks';
import {F_SLOT_HINT} from '../../constants/poke';
import {PokeContext, PokeStateContext} from '../../context';
import {useShouldPlayAnimationOut} from '../../hooks/useShouldPlayAnimationOut';
import {BACKGROUND_TYPE_COLOR} from '../BlockGradient';
import ClickableBlockOverlay from '../ClickableBlockOverlay';
import './_styles.scss';

export const parseUserValue = (value) => {
  const [name, role, avatarUrl] = value.split(';');

  return {name, role, avatarUrl};
};

const defaultImageUrl =
  'https://assets.usejimo.com/builder-empty-state/empty_state_avatar.svg';

export const BlockUser = () => {
  const {poke} = useContext(PokeContext);
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    addFontFamily,
  } = useContext(PokeStateContext);

  const playAnimationOut = useShouldPlayAnimationOut({
    blockType: BLOCK_USER,
  });

  const block = currentStep.blocks.find((b) => b.type === BLOCK_USER);
  const value = parseUserValue(block.value);
  let {
    fontFamily = 'Inter',
    fontColor: forcedFontColor,
    fontSize = 14,
    ...restStyle
  } = block.style;

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  const fontColor =
    forcedFontColor != null
      ? forcedFontColor
      : poke?.style?.background == null
      ? '#FFFFFFFF'
      : poke.style.background.type !== BACKGROUND_TYPE_COLOR
      ? '#FFFFFFFF'
      : poke.style.background.primaryColor;

  const isHint = hasFlag(F_SLOT_HINT, poke.boostFlags);

  return (
    <div
      className={classnames('poke-block-user', {
        'poke-block-clickable': inBuilder === true,
        'is-animating-out': playAnimationOut === true,
        selected: selectedBlock === BLOCK_USER,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onBlockSelected(BLOCK_USER);
      }}
      style={{
        fontFamily: `${fontFamily}, ui-sans-serif, system-ui, sans-serif`,
      }}>
      <div
        className="pb-user-avatar"
        style={{
          ...restStyle,
          backgroundImage: `url(${value.avatarUrl || defaultImageUrl})`,
        }}
      />
      <div className="pb-user-infos">
        {value.name && (
          <strong
            style={{
              fontSize: `${fontSize}px`,
              color:
                forcedFontColor != null
                  ? fontColor
                  : fontColorContrast(
                      fontColor.slice(
                        0,
                        fontColor.length > 7
                          ? fontColor.length - 2
                          : fontColor.length
                      )
                    ),
            }}>
            {value.name}
          </strong>
        )}
        {value.role && (
          <span
            style={{
              fontSize: `${fontSize}px`,
              color:
                forcedFontColor != null
                  ? fontColor
                  : fontColorContrast(
                      fontColor.slice(
                        0,
                        fontColor.length > 7
                          ? fontColor.length - 2
                          : fontColor.length
                      )
                    ),
            }}>
            {value.role}
          </span>
        )}
      </div>
      <ClickableBlockOverlay withoutOverflow={isHint} />
    </div>
  );
};

export const BlockUserCursorImage = () => {
  const {currentStep, onBlockSelected, selectedBlock, inBuilder} =
    useContext(PokeStateContext);

  const block = currentStep.blocks.find((b) => b.type === BLOCK_USER);
  const value = parseUserValue(block.value);

  return (
    <div
      className={classnames('poke-block-user-cursor-image', {
        'poke-block-clickable': inBuilder === true,
        selected: selectedBlock === BLOCK_USER,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onBlockSelected(BLOCK_USER);
      }}>
      <div
        className="pb-user-avatar"
        style={{
          backgroundImage: `url(${value.avatarUrl || defaultImageUrl})`,
        }}
      />
      <ClickableBlockOverlay />
    </div>
  );
};

export const BlockUserCursorText = () => {
  const {
    currentStep,
    onBlockSelected,
    selectedBlock,
    inBuilder,
    addFontFamily,
  } = useContext(PokeStateContext);

  const block = currentStep.blocks.find((b) => b.type === BLOCK_USER);
  const value = parseUserValue(block.value);
  let {fontFamily = 'Inter', fontColor, fontSize = 14} = block.style;

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  return (
    <div
      className={classnames('poke-block-user-cursor-text', {
        'poke-block-clickable': inBuilder === true,
        selected: selectedBlock === BLOCK_USER,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onBlockSelected(BLOCK_USER);
      }}
      style={{
        fontFamily: `${fontFamily}, ui-sans-serif, system-ui, sans-serif`,
      }}>
      {value.name && (
        <span
          style={{
            color: fontColor,
            fontSize: `${fontSize}px`,
          }}>
          {value.name}
          {value.role && `, ${value.role}`}
        </span>
      )}
      <ClickableBlockOverlay />
    </div>
  );
};
