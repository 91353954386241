import classNames from 'classnames';
import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import {UrlTester} from 'components/PathSelector';
import {BuilderContext} from 'contextes/builder';
import {hasFlag} from 'helpers/bitwise';
import React, {useContext} from 'react';
import {
  ACTIVE_OPERATOR_CUSTOM,
  ACTIVE_OPERATOR_SINGLE_URL,
} from 'scenes/EmbeddedElementSelectorBuilder';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {F_BOOST_SLOT_TOUR} from 'services/evolution';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import Rules from '../../components/modals/Rules';
import './_Styles.scss';

const NavigationOption = ({
  label,
  description,
  selected,
  onClick = () => {},
}) => {
  return (
    <div
      className={classNames('navigation-option', {
        selected,
      })}
      onClick={onClick}>
      <div className="navigation-option-content">
        <div className="navigation-option-label subtitle-4 n-800">{label}</div>
        <div className="navigation-option-description body-4 n-700">
          {description}
        </div>
      </div>
      <div className="navigation-option-icon">
        <i className="isax isax-tick-circle5 b-400" />
      </div>
    </div>
  );
};

const Navigation = () => {
  const {controlledEvolution: evolution, setControlledEvolution: setEvolution} =
    useContext(BuilderContext);

  return (
    <div className="block-settings navigation">
      <Section title="Type">
        <SectionItem>
          <NavigationOption
            label="Direct URL Navigation"
            description="Redirect users immediately to a specified URL, then resume tour."
            selected={
              evolution.boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL
                ? 'Selected'
                : ''
            }
            onClick={() =>
              setEvolution({
                ...evolution,
                boostedActiveOperator: ACTIVE_OPERATOR_SINGLE_URL,
              })
            }
          />
        </SectionItem>
        <SectionItem>
          <NavigationOption
            label="URL-triggered Progression"
            description="Pause tour until user visits specified URL on their own, then resume tour."
            selected={
              evolution.boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM
                ? 'Selected'
                : ''
            }
            onClick={() =>
              setEvolution({
                ...evolution,
                boostedActiveOperator: ACTIVE_OPERATOR_CUSTOM,
              })
            }
          />
        </SectionItem>
      </Section>
      <Divider />
      <div className="section">
        <div className="section-title">
          Navigation
          <div className="subtitle body-3 n-600">
            Resume tour when URL matches with the following rule(s)
          </div>
        </div>
        <div className="section-content">
          {evolution.boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL && (
            <SectionItem title="Redirect to">
              <InputGroup
                className="navigation-redirect-input"
                value={evolution.boostedActiveUrl}
                onChange={({target}) =>
                  setEvolution({
                    ...evolution,
                    boostedActiveUrl: target.value,
                  })
                }
                placeholder="https://"
              />
            </SectionItem>
          )}
          {evolution.boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM && (
            <>
              <SectionItem title="Rules">
                <PopupSetting
                  className="rules-popup-setting"
                  trigger={
                    <ClickableInput
                      value={
                        evolution.boostedPaths?.length > 0
                          ? `${evolution.boostedPaths.length} rule${
                              evolution.boostedPaths.length > 1 ? 's' : ''
                            }`
                          : 'Add...'
                      }
                    />
                  }
                  title="Rules"
                  content={<Rules />}
                  large
                />
              </SectionItem>
              <Divider />
              <UrlTester
                paths={evolution.boostedPaths}
                domainFilter={evolution.boostedDomainFilter}
                pathOperator={evolution.boostedPathOperator}
                isTour={hasFlag(evolution.boostedSlot, F_BOOST_SLOT_TOUR)}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navigation;
