import Divider from 'components/Divider';
import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import ColorItem from '../../components/sectionItems/ColorItem';
import FontFamilyItem from '../../components/sectionItems/FontFamilyItem';
import FontSizeItem from '../../components/sectionItems/FontSizeItem';
import './_Styles.scss';

export const CHECKLIST_HEADER_PROGRESS_BAR = 'bar';
export const CHECKLIST_HEADER_PROGRESS_STEPS = 'steps';

const shapeOptions = [
  {
    label: (
      <>
        <i className="isax isax-settings5" />
        Bar
      </>
    ),
    value: CHECKLIST_HEADER_PROGRESS_BAR,
  },
  {
    label: (
      <>
        <i className="isax isax-fatrows5" />
        Steps
      </>
    ),
    value: CHECKLIST_HEADER_PROGRESS_STEPS,
  },
];

const ChecklistProgress = () => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    selectedBlockType,
  } = useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(selectedBlockType, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === selectedBlockType);

  if (block == null) {
    return <></>;
  }

  const {value, style} = block;

  return (
    <div className="block-settings checklist-header-progress">
      <Section title="General">
        <SectionItem title="Type">
          <SelectGroup
            isSearchable={false}
            classNamePrefix="type-icon-select"
            options={shapeOptions}
            value={shapeOptions.find((o) => o.value === value)}
            onChange={(option) => {
              updateBlock({
                value: option.value,
              });
            }}
            menuPortalTarget={document.body}
          />
        </SectionItem>
      </Section>
      <Divider />
      <Section title="Style">
        <ChecklistProgressStyle style={style} updateStyle={updateBlock} />
      </Section>
    </div>
  );
};

export const ChecklistProgressStyle = ({style, updateStyle}) => {
  return (
    <>
      <FontFamilyItem
        value={style.fontFamily}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontFamily: value},
          })
        }
      />
      <FontSizeItem
        value={style.fontSize}
        onChange={(value) =>
          updateStyle({
            style: {...style, fontSize: value},
          })
        }
      />
      <ColorItem
        title="Active"
        value={style.primaryColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, primaryColor: value},
          })
        }
      />
      <ColorItem
        title="Inactive"
        value={style.secondaryColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, secondaryColor: value},
          })
        }
      />
    </>
  );
};

export default ChecklistProgress;
