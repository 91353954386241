import Divider from 'components/Divider';
import { BuilderContext } from 'contextes/builder';
import { useContext } from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import ColorItem from '../../components/sectionItems/ColorItem';
import './_Styles.scss';

const Cursor = () => {
  const {
    evolution,
    selectedStep: step,
    updateBlock: uptBlock,
    selectedBlockType,
    selectedStep,
    updateStep,
  } = useContext(BuilderContext);
  
  const updateBlock = (updateObj) => {
    uptBlock(selectedBlockType, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === selectedBlockType);

  if (block == null) {
    return <></>;
  }

  const {style} = block;

  return (
    <div className="block-settings cursor">
      <Section title="Style">
        <CursorStyle
          style={style}
          updateStyle={updateBlock}
        />
      </Section>
      <Divider />
    </div>
  );
};

export const CursorStyle = ({style, updateStyle}) => {
  return (
    <>
      <ColorItem
        title="Background"
        value={style.backgroundColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, backgroundColor: value},
          })
        }
      />
      <ColorItem
        title="Border"
        value={style.borderColor}
        onChange={(value) =>
          updateStyle({
            style: {...style, borderColor: value},
          })
        }
      />
    </>
  );
};

export default Cursor;
