import classNames from 'classnames';
import React from 'react';
import './_Styles.scss';

const SectionItem = ({
  title,
  children,
  sectionItemClassName = '',
  contentClassName = '',
}) => {
  if (title != null) {
    return (
      <div className={classNames('section-item', sectionItemClassName)}>
        <div className="section-item-title body-4">{title}</div>
        <div className={classNames('section-item-content', contentClassName)}>
          {children}
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('section-item', sectionItemClassName)}>
      {children}
    </div>
  );
};

export default SectionItem;
