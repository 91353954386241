import Divider from 'components/Divider';
import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import RadioGroup from '../../../../../../components/RadioGroup';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import './_Styles.scss';

const typeOptions = [
  {
    label: (
      <div className="type-option-wrapper">
        <i className="isax isax-settings5" />
        Bar
      </div>
    ),
    value: 'line',
  },
  {
    label: (
      <div className="type-option-wrapper">
        <i className="isax isax-fatrows5" />
        Steps
      </div>
    ),
    value: 'dot-line',
  },
  {
    label: (
      <div className="type-option-wrapper">
        <i className="isax isax-text5" />
        Text
      </div>
    ),
    value: 'text',
  },
];

const positionOptions = [
  {label: 'Top', value: 'top'},
  {label: 'Bottom', value: 'bottom'},
];

const alignOptions = [
  {label: <i className="icon-align-b-l" />, value: 'left'},
  {label: <i className="icon-align-b-c" />, value: 'center'},
  {label: <i className="icon-align-b-r" />, value: 'right'},
];

const Stepper = () => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    selectedBlockType,
  } = useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(selectedBlockType, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === selectedBlockType);

  if (block == null) {
    return <></>;
  }

  const {style} = block;

  return (
    <div className="block-settings stepper">
      <Section title="Layout">
        <StepperLayout style={style} updateStyle={updateBlock} />
      </Section>
      <Divider />
      <Section title="Style">
        <StepperStyle
          style={style}
          updateStyle={updateBlock}
          hideStepActive={style.type === 'text'}
        />
      </Section>
    </div>
  );
};

export const StepperLayout = ({style, updateStyle}) => {
  return (
    <>
      <SectionItem title="Type">
        <SelectGroup
          isSearchable={false}
          classNamePrefix="type-icon-select"
          options={typeOptions}
          value={typeOptions.find((o) => o.value === style.type)}
          onChange={(option) => {
            updateStyle({
              style: {
                ...style,
                type: option.value,
              },
            });
          }}
          menuPortalTarget={document.body}
        />
      </SectionItem>
      <SectionItem title="Position">
        <RadioGroup
          value={style.position}
          options={positionOptions}
          onSelect={(value) =>
            updateStyle({
              style: {
                ...style,
                position: value,
              },
            })
          }
        />
      </SectionItem>
      <SectionItem title="Align">
        <RadioGroup
          value={style.align}
          options={alignOptions}
          onSelect={(value) =>
            updateStyle({
              style: {
                ...style,
                align: value,
              },
            })
          }
        />
      </SectionItem>
    </>
  );
};

export const StepperStyle = ({style, updateStyle, hideStepActive = false}) => {
  return (
    <>
      <SectionItem title="Step">
        <ColorPickerInput
          inputProps={{small: true}}
          title="Step"
          value={style.primaryColor}
          onChange={(value) =>
            updateStyle({
              style: {...style, primaryColor: value},
            })
          }
        />
      </SectionItem>
      {hideStepActive !== true && (
        <SectionItem title="Step active">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Step active"
            value={style.secondaryColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, secondaryColor: value},
              })
            }
          />
        </SectionItem>
      )}
    </>
  );
};

export default Stepper;
