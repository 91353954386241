import InputGroup from 'components/Input';
import {bool, func, number, string} from 'prop-types';
import Slider from 'rc-slider';
import './_Styles.scss';

const propTypes = {
  value: number,
  min: number,
  max: number,
  onChange: func,
  radius: bool,
  label: string,
};

const PixelPicker = ({
  value = 10,
  min = 0,
  max = 20,
  onChange = () => {},
  radius = false,
  small = false,
  label = 'px',
  inputProps = {},
}) => {
  return (
    <div className="pixel-picker">
      <div className="pixel-picker-input">
        <InputGroup
          small={small}
          value={value}
          type="number"
          labelTextLeft={
            radius === true ? (
              <div className="icon-wrapper">
                <i className="icon-line-corner" />
              </div>
            ) : null
          }
          labelTextRight={label}
          min={min}
          max={max}
          onChange={(e) => onChange(parseInt(e.target.value, 10))}
          {...inputProps}
        />
      </div>
      <div className="pixel-picker-slider">
        <Slider min={min} max={max} onChange={onChange} value={value || 0} />
      </div>
    </div>
  );
};

PixelPicker.propTypes = propTypes;

export default PixelPicker;
