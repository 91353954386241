import SelectGroup from 'components/Select';
import React from 'react';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import PixelPicker from '../../items/PixelPicker';
import ColorItem from '../ColorItem';
import './_Styles.scss';

export const effectOptions = [
  {
    label: (
      <>
        <i className="isax isax-close-circle4" />
        None
      </>
    ),
    value: null,
  },
  {
    label: (
      <>
        <i className="isax isax-voice-square" />
        Shimmer
      </>
    ),
    value: 'shimmer',
  },
  {
    label: (
      <>
        <i className="isax isax-sun-1" />
        Glow
      </>
    ),
    value: 'glow',
  },
  {
    label: (
      <>
        <i className="isax isax-maximize-214" />
        Moving border
      </>
    ),
    value: 'moving-border',
  },
];

const defaultEffects = [null, 'shimmer', 'glow'];

const EffectsSection = ({style, onChange, additionalEffects = []}) => {
  return (
    <Section title="Effects" className="effects-section">
      <SectionItem title="Type">
        <SelectGroup
          classNamePrefix="effects-select"
          isSearchable={false}
          menuPlacement="top"
          small
          options={effectOptions.filter(
            (o) =>
              defaultEffects.includes(o.value) ||
              additionalEffects.includes(o.value)
          )}
          value={effectOptions.find(
            (o) => o.value === (style.effectType || null)
          )}
          onChange={(option) =>
            onChange({
              effectType: option.value,
              effectSpeed: style.effectSpeed ?? 100,
              effectColor: style.effectColor ?? '#000000',
            })
          }
        />
      </SectionItem>
      {style.effectType != null && (
        <>
          <ColorItem
            title="Color"
            value={style.effectColor}
            onChange={(value) =>
              onChange({
                effectColor: value,
              })
            }
          />
          <SectionItem title="Speed">
            <PixelPicker
              small
              value={style.effectSpeed}
              min={0}
              max={90}
              label="%"
              onChange={(value) =>
                onChange({
                  effectSpeed: value,
                })
              }
            />
          </SectionItem>
        </>
      )}
    </Section>
  );
};

export default EffectsSection;
